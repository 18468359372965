<template>
    <label class="block">
        <span class="text-gray-700">{{ label }}</span>
        <slot />
    </label>
</template>

<script>
    export default {
        name: "BlankInput",
        props: {
            label: {
                type: String,
                default: 'Deskripsi'
            },
        }
    };
</script>

<style lang="css" scoped>
</style>