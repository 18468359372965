import { requestDelete, requestSubmit, sendRequest } from "../api"
import {
    getField,
    updateField
} from 'vuex-map-fields'
import {
    RESET_STATE, SET_OPTION
} from "../constant-types"

const getDefaultState = () => {
    return {
        options : [],
        form: getDefaultFormState()
    }
}
const getDefaultFormState = () => {
    return {
        id: '',
        name: '',
        via: 'tunai',
        direct_cash: false
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_OPTION](state, options) {
            state.options = options
        },
        ADD_TO_OPTION(state, option) {
            state.options.unshift(option)
        },
        UPDATE_TO_OPTION(state, updatedOption) {
            let idx = state.options.findIndex(o => o.id == updatedOption.id)
            state.options[idx] = updatedOption
        },
        REMOVE_FROM_OPTION(state, cashAccount) {
            // _.remove(state.options, { id : cashAccount.id})
            let idx = state.options.findIndex(o => o.id === cashAccount.id)
            state.options.splice(idx, 1);
        },
        RESET_FORM(state) {
            Object.assign(state.form, getDefaultFormState())
        },
        SET_FORM(state, cashAccount) {
            Object.assign(state.form, cashAccount)
        },
        updateField

    },
    // can async
    actions: {
        loadOptions({commit}) {
            sendRequest('cash-account/option', {}, 'get', (responseOptions) => commit(SET_OPTION, responseOptions))
        },
        requestCreateCashAccount({
            commit,
            state
        }) {
            return requestSubmit('cash-account', state.form, commit, 'post', (createdCashAccount) => commit('ADD_TO_OPTION', createdCashAccount))
        },
        requestUpdateCashAccount({
            commit,
            state
        }) {
            return requestSubmit(`cash-account/${state.form.id}`, state.form, commit, 'put', (updatedCashAccount) => commit('UPDATE_TO_OPTION', updatedCashAccount))
        },
        requestDeleteCashAccount({
            commit
        }, cashAccount) {
            return requestDelete(`cash-account/${cashAccount.id}`, {}, commit, cashAccount.name, 'REMOVE_FROM_OPTION')
        }
    },
    // call variable with custom display
    getters: {
        getField
    },
    // modules
    modules: {

    }
})
