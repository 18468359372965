import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, REMOVE_ROW, RESET_FORM, SAVE_CALCULATION_ROW, SET_FORMS
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: []
    }
}

const getDefaultRowState = () => ({
    id: '',
    product: '', // for reference
    product_id: '',
    calc_of_dimension: '',
    calc_of_dimension_raw: {},
    calc_of_dimension_no_price: '',
    dimension: '',
    n_markup_price: 0,
    n_price: 0,
    qty: 0,
    markup_price: 0,
    price: 0,
    memo_to_operator: '',
    machine_id: '',
    price_by_client_categories: []
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.push(getDefaultRowState())
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [REMOVE_ROW](state, idx) {
            state.rows.splice(idx, 1)
        },
        [SAVE_CALCULATION_ROW](state, { calculationResult, itemIndex }) {
            // let item = state.rows[itemIndex]
            // item.calc_of_dimension = calculationResult.calc_of_dimension
            Object.assign(state.rows[itemIndex], calculationResult)
        },
        [SET_FORMS](state, items) {
            state.rows = _.map(items, i => {
                return _.pick(i, ['id', 'product', 'product_id', 'calc_of_dimension', 'calc_of_dimension_no_price', 'n_markup_price', 'n_price', 'qty', 'markup_price', 'price', 'memo_to_operator', 'machine_id'])
            })
        },
        updateField

    },
    // can async
    actions: {
        
    },
    // call variable with custom display
    getters: {
        getField,
        getProductPriceByClientCategory: (state, getters, rootState, rootGetters) => (itemIndex) => {
            let client = rootState.orderDemand.orderDemandForm.rows[0].client
            let product = state.rows[itemIndex].product
            // return if client or product is not set yet
            if ( !client || !product ) {
                return ;
            } else {
                let clientCategoryId = client.client_category_id
                let productPrice = product.product_prices.find(p => +p.client_category_id === +clientCategoryId)
                return productPrice ? +productPrice.sell_price : 0;
            }
        }
    }
})
