<template>
    <div class="flex items-center justify-center text-green-500">
        <svg class="w-12 h-12 animate-pulse"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" /></svg>
    </div>
</template>

<script>
    export default {
        name: "LoadingBlock",
        data() {
            return {};
        }
    };
</script>

<style lang="css" scoped>
</style>