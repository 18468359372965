import {
    ADD_IN_LIST,
    REMOVE_IN_LIST,
    RESET_STATE,
    UPDATE_IN_LIST,
    SET_LIST
} from "../constant-types"
import { getField, updateField } from 'vuex-map-fields'
import clientForm from "./form/clientForm"
import { getSearch, notify, notifyConfirmation, requestDelete, requestSubmit, sendRequest } from "../api"

const getDefaultState = () => {
    return {
        list: null,
        search_params: getDefaultSearchParamsState(),
        detail: null,
        detail_loading: false,
        client_code_rule_list: {}
    }
}

const getDefaultSearchParamsState = () => {
    return {
        filter_search_text : '',
        loading : false,
        filter_sort : [{
            column : 'name',
            sort : 'asc',
            icon : ['fas', 'angle-up']
        }],
        filter_page : 1,
        filter_limit : 10
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_IN_LIST](state, createdClient) {
            // only save if list loaded
            if (state.list) {
                state.list.data.unshift(createdClient)
            }
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [REMOVE_IN_LIST](state, deletedClient) {
            let idx = state.list.data.findIndex(l => l.id === deletedClient.id)
            state.list.data.splice(idx, 1);
        },
        [SET_LIST](state, fetchedList) {
            //state.list = fetchedList

            // map selected wihtin data
            const { data, ...fetch_remain } = fetchedList

            state.list = {
                ...fetch_remain,
                data : data.map(os => {
                    os.selected = false
                    return os
                })
            }
        },
        [UPDATE_IN_LIST](state, updatedClient) {
            let idx = state.list.data.findIndex(l => l.id === updatedClient.id)
            state.list.data[idx] = updatedClient
        },
        SET_DETAIL(state, payload) {
            state.detail = payload
        },
        updateField
    },
    // can async
    actions: {
        loadList({commit, state}, payload = { resetPage : false}) {
            if (payload.resetPage) {
                commit('updateField', { path : 'search_params.filter_page', value : 1})
            }
            let filterSearchMust = []
            if (typeof(payload.statusActive) === 'boolean') { // if boolean, indicate has this related payload
                filterSearchMust.push([
                    payload.statusActive, '=', '', 'status_active'
                ])
            }
            if (typeof(payload.hasBusinessUnit) === 'boolean') { // if boolean, indicate has this related payload
                if (payload.hasBusinessUnit) {
                    filterSearchMust.push([
                        0, '>', '', 'count_business_units'
                    ])
                }
            }
            commit('updateField', { path: 'search_params.loading', value : true })
            return getSearch(
                {words: _.split(state.search_params.filter_search_text, ' '), fields : ['name', 'full_name', 'phone', 'im', 'address', 'clientCategory>client_category']},
                filterSearchMust,
                [],
                state.search_params.filter_sort,
                state.search_params.filter_page,
                state.search_params.filter_limit,
                [],
                [],
                'client',
                (response) => commit(SET_LIST, response),
                ()=>{},
                ()=>{ commit('updateField', { path: 'search_params.loading', value : false }) }
            )
        },
        requestCreateClient({commit, state}, { responseToForm  = ''}) {
            return requestSubmit('client', state.clientForm.rows[0], commit, 'post',
                (createdClient) => {
                    commit(ADD_IN_LIST, createdClient)
                    if (responseToForm) {
                        commit(responseToForm, createdClient, { root : true })
                    }
                }
            )
        },
        requestUpdateClient({commit, state}, { responseToForm = ''}) {
            return requestSubmit('client', state.clientForm.rows[0], commit, 'put',
                (updatedClient) => {
                    commit(UPDATE_IN_LIST, updatedClient)
                    if (responseToForm) {
                        commit(responseToForm, updatedClient, { root : true })
                    }
                }
            )
        },
        requestDeleteClient({commit, state}, client) {
            return requestDelete(`client/${client.id}`, {}, commit, client.name, REMOVE_IN_LIST)
        },
        loadDetail({commit}, clientId) {
            commit('updateField', { path: 'detail_loading', value: true })
            return sendRequest(`client/${clientId}`, {}, 'get', (successResponse) => {
                commit('SET_DETAIL', successResponse)
                commit('updateField', { path: 'detail_loading', value: false })
            })
        },
        loadClientCodeRuleList({commit}) {
            return sendRequest('client/code', {}, 'get', (successResponse) => {
                commit('updateField',  { path: 'client_code_rule_list', value: successResponse })
            })
        },
        requestMergeClient({commit, dispatch}, clients) {
            // should confirm first
            notifyConfirmation('apakah yakin ingin menggabung ? data transaksi akan berpindah, tidak dapat diulang',
                // yes
                () => {
                    return requestSubmit('client/merge', { clients: clients.map( c => ({ id : c.id, name: c.name }) ) }, commit, 'post', (successResponse ) => {
                        // refresh list when success
                        dispatch('loadList')
                        // notify
                        notify('success', 'penggabungan berhasil')
                    })
                }, () => {
                    // no
                })
            //return sendRequest('client/merge', { clients : clientIds }, 'post', (successResponse) => {
                //// refresh list when success
                //dispatch('loadList')
            //})
        }
    },
    // call variable with custom display
    getters: {
        getField
    },
    // modules
    modules: {
        clientForm
    }
})
