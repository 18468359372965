import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, CALCULATE_PAYMENT_PAID, RESET_FORM, RESET_PAYMENT, SET_FORM, SET_TOTAL
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: []
    }
}

const getDefaultRowState = () => ({
    branch_id: null,
    // code : '', // manual
    // order_date_time : '', // manual
    // order_date : '', // manual
    // client_id : '', // removed, because already on orderDemandItem
    price_total : 0,
    price_disc : 0,
    price_total_after_disc : 0,
    price_paid : 0,
    price_remain : 0,
    price_change : 0,
    // paid : '', // manual,

    order_demand : '', // for reference and update
})

export default ({
    namespaced: true,
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state, orderDemand) {
            state.rows.push({...getDefaultRowState(), order_demand : orderDemand })
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [RESET_PAYMENT](state, { indexRow = 0}) {
            state.rows[indexRow].price_paid = 0;
            state.rows[indexRow].price_remain = state.rows[indexRow].price_total_after_disc - state.rows[indexRow].price_paid
            state.rows[indexRow].price_change = 0;
        },
        [SET_TOTAL](state, { indexRow = 0, total }) {
            state.rows[indexRow].price_total = total
            state.rows[indexRow].price_disc = 0
            state.rows[indexRow].price_total_after_disc = total
        },
        [SET_FORM](state, cashier) {
            state.rows[0] = _.pick(cashier, ['id', 'code', 'order_date_time', 'order_date', 'price_total', 'price_disc', 'price_total_after_disc', 'price_paid', 'price_remain', 'price_change', 'order_demand'])
        },
        [CALCULATE_PAYMENT_PAID](state, { indexRow = 0, totalPayment}){
            state.rows[indexRow].price_paid = totalPayment
            state.rows[indexRow].price_remain = state.rows[indexRow].price_total_after_disc - state.rows[indexRow].price_paid
            let priceChange = state.rows[indexRow].price_paid - state.rows[indexRow].price_total_after_disc
            state.rows[indexRow].price_change = priceChange < 0 ? 0 : priceChange
        },
        updateField

    },
    // can async
    actions: {
        setCashierForm({commit, dispatch, rootState}, orderDemand) {
            commit(ADD_ROW, orderDemand)
            // set cashier item
            orderDemand.order_demand_items.forEach(odi => {
                commit('cashier/cashierItemForm/ADD_ROW', odi, { root: true})
            })
            // set branch base on auth user as default
            commit('updateField', { path: 'rows.0.branch_id', value: rootState.authUser.branch_id })
            // calculate total from initialize
            dispatch('calculateTotal')

        },
        calculateTotal({commit, rootState, dispatch}) {
            // total from item
            let totalItem = rootState.cashier.cashierItemForm.rows.reduce((accumulator, currentValue) => accumulator + +currentValue.price_after_disc, 0)
            // total from common cost
            let totalCost = rootState.cashier.cashierCommonCostForm.rows.reduce((accumulator, currentValue) => accumulator + +currentValue.nominal, 0)
            // total
            let total = totalItem + totalCost

            // commit
            commit(SET_TOTAL, { indexRow : 0, total })
            // commit(CALCULATE_PAYMENT_PAID, { indexRow : 0, totalPayment : total })
            dispatch('calculatePayment')
        },
        calculatePayment({commit, rootState}) {
            // get total from payment
            let totalFromPayment = rootState.cashier.paymentForm.rows.reduce((accumulator, currentValue) => accumulator + currentValue.nominal, 0)
            // set to recap
            commit(CALCULATE_PAYMENT_PAID, { indexRow : 0, totalPayment: totalFromPayment})

        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
