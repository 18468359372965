import { clearNotify, notify, sendRequest } from "../api"
import { sortBy } from 'lodash'
import {
    getField,
    updateField
} from 'vuex-map-fields'
import {
    RESET_STATE} from "../constant-types"

const getDefaultState = () => {
    return {
        configs : [],
        config_loading: false,
        config_fetched: false,
        config_saving: false,

        connection: {
            success: false,
            sending: false,
            fetched: false,
            message: '',
        },

        download: {
            success: false,
            sending: false,
            fetched: false,
            message: '',
            detail: {
                owner: null,
                count: 0
            }
        },

        testing: {
            success: false,
            sending: false,
            fetched: false,
            message: '',
            detail: null,
            request: {
                message: '',
                wa_contact: null,
            }
        },
        /*
        socket_wa: {

        }
        */

    }
}

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        [RESET_STATE](state) {
            Object.assign(state, getDefaultState())
        },
        SET_CONFIG(state, payloads) {
            state.configs = sortBy(payloads, 'order_sequence')
        },
        updateField
    },
    actions: {
        /*
        requestWALogout({commit, state}) {
            // all should change to utation
            state.customer.sendingStatus.customerLogout = true
            sendRequest('/wa/logout', state.credentials, post, () => {
                state.customer.waState.showAskQrButton = true
                state.customer.waState.qrcodeUrl = ""
                // state.customer.sendingStatus.customerLogout = false
                state.customer.waState.connection = false
            })
        },
        requestWAskQr({commit, state}) {
            state.customer.sendingStatus.customerAskQr = true
            sendRequest('/wa/initialize', state.credentials, 'post', (response) => {
                state.customer.waState.showAskQrButton = false
                state.customer.sendingStatus.customerAskQr = false
            })
        },
        */
        loadConfigs({commit}) {
            commit('updateField', { path: 'config_loading', value: true })
            sendRequest('/integration/whatsapp/config', {}, 'get', (successResponse) => {
                commit('SET_CONFIG', successResponse)
                commit('updateField', { path: 'config_loading', value: false })
                commit('updateField', { path: 'config_fetched', value: false })
            })
        },
        async requestUpdateConfigs({state,commit}) {
            notify('info', 'mengupdate konfigurasi...')
            commit('updateField', { path: 'config_saving', value: true })
            await sendRequest('/integration/whatsapp/config', { configs: state.configs }, 'put', (successResponse) => {
                commit('updateField', { path: 'config_saving', value: false })
                clearNotify()
                notify('success', 'update konfigurasi selesai...')
            })
        },
        async requestCheckConnection({commit}) {
            commit('updateField', { path: 'connection.sending', value: true })
            commit('updateField', { path: 'connection.success', value: false })
            commit('updateField', { path: 'connection.message', value: '' })
            await sendRequest('/integration/whatsapp/contact/get-state', {}, 'get', (successResponse) => {
                //condition when false in this, should handle
                commit('updateField', { path: 'connection.sending', value: false })
                if (successResponse.status) {
                    commit('updateField', { path: 'connection.success', value: true })
                    commit('updateField', { path: 'connection.message', value: successResponse.message })
                } else {
                    commit('updateField', { path: 'connection.success', value: false })
                    commit('updateField', { path: 'connection.message', value: 'client error' })
                }
            }, (error) => {
                commit('updateField', { path: 'connection.sending', value: false })
                commit('updateField', { path: 'connection.success', value: false })
                commit('updateField', { path: 'connection.message', value: 'koneksi gagal' })
            }, () => {
                commit('updateField', { path: 'connection.fetched', value: true })
            })
        },
        async requestUpdateContacts({commit}) {
            commit('updateField', { path: 'download.sending', value: true })
            commit('updateField', { path: 'download.success', value: false })
            commit('updateField', { path: 'download.message', value: '' })
            await sendRequest('/integration/whatsapp/contact/download', {}, 'get', (successResponse) => {
                //condition when false in this, should handle
                commit('updateField', { path: 'download.sending', value: false })
                if (successResponse.status) {
                    commit('updateField', { path: 'download.success', value: true })
                    commit('updateField', { path: 'download.message', value: successResponse.message })
                    commit('updateField', { path: 'download.detail', value: successResponse.detail })
                } else {
                    commit('updateField', { path: 'download.success', value: false })
                    commit('updateField', { path: 'download.message', value: 'client error' })
                }
            }, (error) => {
                commit('updateField', { path: 'download.sending', value: false })
                commit('updateField', { path: 'download.success', value: false })
                commit('updateField', { path: 'download.message', value: 'koneksi gagal' })
            }, () => {
                commit('updateField', { path: 'download.fetched', value: true })
            })
        },
        async requestTestingMessage({commit, state}) {
            commit('updateField', { path: 'testing.sending', value: true })
            commit('updateField', { path: 'testing.success', value: false })
            commit('updateField', { path: 'testing.message', value: '' })
            let form = {
                wa_id_serialized: state.testing.request.wa_contact.wa_id_serialized,
                message : state.testing.request.message
            }
            await sendRequest('/integration/whatsapp/contact/send-text', form, 'post', (successResponse) => {
                //condition when false in this, should handle
                commit('updateField', { path: 'testing.sending', value: false })
                if (successResponse.status) {
                    commit('updateField', { path: 'testing.success', value: true })
                    commit('updateField', { path: 'testing.message', value: successResponse.message })
                    commit('updateField', { path: 'testing.detail', value: successResponse.detail })
                } else {
                    commit('updateField', { path: 'testing.success', value: false })
                    commit('updateField', { path: 'testing.message', value: 'Pengiriman Gagal' })
                }
            }, (error) => {
                commit('updateField', { path: 'testing.sending', value: false })
                commit('updateField', { path: 'testing.success', value: false })
                commit('updateField', { path: 'testing.message', value: 'Koneksi Gagal' })
            }, () => {
                commit('updateField', { path: 'testing.fetched', value: true })
            })
        }
    },
    getters: {
        testingMessageReady: state => {
            return state.testing.request.message && state.testing.request.wa_contact
        },
        getField
    }
})
