<template>
    <datepicker input-class="w-56 text-center form-input" :wrapper-class="wrapperClass" :placeholder="placeholder" :format="customFormatter" :bootstrap-styling="false"  :value="value"
        @selected="saveVisitDate"></datepicker>
</template>

<script>
    import Datepicker  from "vuejs-datepicker";

    export default {
        name: "DateInput",
        components: {
            Datepicker 
        },
        props: {
            // includeTime:{
            //   type: Boolean,
            //   required : false,
            //   default: false
            // },
            placeholder: {
                type: String,
                default : 'Masukkan Tgl'
            },
            value: String,
            wrapperClass: {
                type: String,
                default: 'inline-block'
            }
        },
        data() {
            return {
                // displayDateFormat:'dddd Do MMMM YYYY',
                // displayDateTimeFormat : 'dddd Do MMMM YYYY, h:mm:ss a',
                // returnDateFormat:'YYYY-MM-DD',
                // returnDateTimeFormat:'YYYY-MM-DD HH:mm:ss'
            }
        },
        methods: {
            customFormatter(date) {
                return moment(date).format("dddd Do MMMM YYYY");
            },
            saveVisitDate(date) {
                this.$emit('input', moment(date).format("YYYY-MM-DD"));
            }
        },
        // computed: {
        //   displayFormat () {
        //     return this.includeTime ? this.displayDateTimeFormat : this.displayDateFormat;
        //   },
        //   returnFormat (){
        //     return this.includeTime ? this.returnDateTimeFormat : this.returnDateTimeFormat;
        //   }
        // }
    }

</script>

<style lang="css" scoped>
</style>
