// @version V.0.0.4 [Thursday - 20201210_165118] : add SET_QUICK_LIST , some revision SALE -> TRANSACTION 
// @version V0.0.3 [Monday - 20201130_215614] : added info tips for SET_FORM, UPDATE_IN_LIST, add CART, DETAIL
// @version V0.0.2 [Saturday - 20201114_203539] : new const, and sorting constant 
// @version V0.0.1 [Tuesday - 20201103_092924] : init
/**
 * use for form 
 */
export const RESET_FORM = 'RESET_FORM'
export const RESET_STATE = 'RESET_STATE'
export const RESET_LIST = 'RESET_LIST'
export const RESET_SEARCH_PARAMS = 'RESET_SEARCH_PARAMS'

export const REQUEST_SENDING = 'REQUEST_SENDING'
export const REQUEST_SENDED = 'REQUEST_SENDED'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAILED = 'REQUEST_FAILED'

export const SET_KEY_LIST = 'SET_KEY_LIST'
export const SET_LIST = 'SET_LIST'
export const SET_QUICK_LIST = 'SET_QUICK_LIST' //show without paginate, usually only take top 50
export const SET_FORM = 'SET_FORM' // should use Object.assign, to prevent list being changed too
export const SET_OPTION = 'SET_OPTION'
export const SET_FILES = 'SET_FILES'
export const SET_FORMS = 'SET_FORMS' // indicate is list, more than one

export const UPDATE_FORM = 'UPDATE_FORM'
export const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'
export const UPDATE_IN_LIST = 'UPDATE_IN_LIST' // sometimes only works with Object.assign

export const REMOVE_IN_LIST = 'REMOVE_IN_LIST'
export const REMOVE_FILE_IN_FILES = 'REMOVE_FILE_IN_FILES'

export const ADD_IN_LIST = 'ADD_IN_LIST'
export const ADD_ROW = 'ADD_ROW'
export const REMOVE_ROW = 'REMOVE_ROW'

export const ADD_TO_CART = 'ADD_TO_CART'
export const CLEAN_CART = 'CLEAN_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SELECT_CART = 'SELECT_CART'
export const SELECT_ALL_CART = 'SELECT_ALL_CART'
export const UNSELECT_ALL_CART = 'UNSELECT_ALL_CART'

export const SET_DETAIL = 'SET_DETAIL'
export const RESET_DETAIL = 'RESET_DETAIL'
export const REFRESH_DETAIL = 'REFRESH_DETAIL'

// custom
export const ADD_TO_OPTION = 'ADD_TO_OPTION'
export const UPDATE_TO_OPTION = 'UPDATE_TO_OPTION'
export const ADD_TO_QUICK_LIST = 'ADD_TO_QUICK_LIST'
export const REMOVE_FROM_OPTION = 'REMOVE_FROM_OPTION'
export const SET_LOADING = 'SET_LOADING'
export const SET_CLIENT_TO_FORM = 'SET_CLIENT_TO_FORM'
export const SAVE_CALCULATION_ROW = 'SAVE_CALCULATION_ROW'
export const UPDATE_TO_QUICK_LIST = 'UPDATE_TO_QUICK_LIST'
export const UPDATE_TO_ROW = 'UPDATE_TO_ROW'
export const SET_TOTAL = 'SET_TOTAL'
export const CALCULATE_PAYMENT_PAID = 'CALCULATE_PAYMENT_PAID'
export const RESET_PAYMENT = 'RESET_PAYMENT'