<template>
    <!-- pagination -->
    <paginate v-if="data"
    :value="data.current_page"
    :page-count="data.last_page"
    :click-handler="clickCallback"
    prev-text="Prev"
    next-text="Next"
    container-class="inline-flex items-center overflow-hidden font-medium text-green-700 border-t border-b border-green-500 "
    page-class="py-1 hover:bg-green-200"
    page-link-class="px-3 focus:outline-none"
    prev-class="px-3 py-1 hover:bg-green-200 focus:outline-none"
    prev-link-class="page-link bg-primary"
    next-class="px-3 py-1 hover:bg-green-200"
    next-link-class="page-link bg-primary focus:outline-none"
    active-class="bg-green-300 shadow-outline-green"
    disabled-class="text-gray-200"
    break-view-link-class="px-3 text-green-700 "
    >
    </paginate>
</template>

<script>
import Paginate from 'vuejs-paginate'

    export default {
        name: "PaginationTable",
        components: {
            Paginate,
        },
        data() {
            return {
                test: ''
            };
        },
        methods: {
            clickCallback(pageNumber) {
                this.$emit('clickCallback', pageNumber);
            }
        },
        props : ['data']
    };

</script>