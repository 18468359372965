import {
    clearNotify,
    notify,
    sendRequest
} from "../api";
import {
    RESET_FORM,
    RESET_STATE,
    SET_FORM,
    SET_KEY_LIST,
    SET_LIST,
    UPDATE_FORM
} from "../constant-types";

const getDefaultState = () => {
    return {
        general_setting_loaded: false,
        form: null,
        form_new_logo: [],
        key_list: null,
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [SET_KEY_LIST](state, payload) {
            state.key_list = payload;
            state.general_setting_loaded = true;
            // should fire events to tell related component
            app.$events.fire('general-setting-loaded');
        },
        [SET_FORM](state, payload) {
            state.form = _.groupBy(payload, 'group');
        },
        'RESET_FORM_NEW_LOGO'(state) {
            state.form_new_logo = []
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_FILES(state, { refFiles, key }) {
            // add pictures
            for (let i = 0; i < refFiles.length; i++) {
                let reader = new FileReader()
                reader.onload = e => {
                    let idx = state.form_new_logo.findIndex(l => l.key == key)
                    if (idx >= 0) {
                        // state.form_new_logo[idx] = {key : key, file_path: e.target.result, file : refFiles[i]}
                        Object.assign(state.form_new_logo[idx], {key : key, file_path: e.target.result, file : refFiles[i]} )
                    } else {
                        state.form_new_logo.push({key : key, file_path: e.target.result, file : refFiles[i]})
                    }
                    // Object.assign(state.form_new_logo, {key : key, file_path: e.target.result, file : refFiles[i]})
                    // state.form_new_logo = {key : key, file_path: e.target.result, file : refFiles[i]}
                    // state.form.newPictures.push({ caption : '', file_path : e.target.result, file : refFiles[i] })
                }
                reader.readAsDataURL(refFiles[i])
            }
        },
        [RESET_FORM](state) {
            state.form = null
        },
        [UPDATE_FORM](state, payload) {
            state.form[payload.group][payload.index].value = payload.value;
        }
    },
    // can async
    actions: {
        
        loadKeyList({
            commit
        }) {
            return sendRequest('general-setting/key-list', {}, 'get',
                (response) => {
                    commit(SET_KEY_LIST, response)
                },
                () => {},
                () => {})
        },
        loadList({
            commit
        }) {
            notify('default', 'memuat...', null)
            return sendRequest('general-setting', {}, 'get',
                (response) => {
                    clearNotify();
                    commit(SET_FORM, response);
                },
                () => {},
                () => {})
        },
        requestSaveList({
            state, commit, dispatch
        }) {
            // before saving, flatten first
            let params = {}

            _.each(state.form, function (group) {
                _.each(group, function (item) {
                    params[item.key] = item.value
                })
            })

            notify('info', 'menyimpan...', null, 'top-right');
            return sendRequest('general-setting', { params, paramsLogo : state.form_new_logo }, 'post', 
                // success
                (response) => {
                    clearNotify(),
                    // notify('success', 'refresh aplikasi', 10000, 'top-right') 
                    // reset form new logo
                    commit('RESET_FORM_NEW_LOGO')
                    // update back
                    commit(SET_FORM, response)
                    dispatch('loadKeyList')

                }, (error) => {
                clearNotify();
                notify('error', 'gagal menyimpan')
            }, () => {}, true, false, true)
        }
    },

    // call variable with custom display
    getters: {
        formNewLogoPath : state => logoKey => {
            let idx = state.form_new_logo.findIndex(l => l.key == logoKey)
            if (idx >= 0) {
                return state.form_new_logo[idx].file_path
            } else {
                return 'no path'
            }
        },
        formNewLogoAvailable :state => logoKey => {
            let idx = state.form_new_logo.findIndex(l => l.key == logoKey)
            return idx >= 0
        }
    }
})
