<template>
    <nav
        class="flex flex-wrap items-center px-1 border-b-2 border-teal-500 bg-gradient-to-r from-teal-400 to-green-400 ">
        <!-- app name -->
        <button @click="gotoHome()" class="ml-1 mr-2 font-mono text-sm font-black tracking-wide text-teal-700 uppercase sm:text-3xl focus:outline-none">
            <!-- {{ $store.state.company_profile.name }} -->
            {{ $store.state.generalSetting.key_list ? $store.state.generalSetting.key_list.company_name : '---' }} <small class="text-xs">v{{ $store.state.app_version }}</small>
        </button>

        <!-- button to open menu -->
        <button class="px-1 ml-auto mr-1 text-teal-700 xl:hidden hover:bg-teal-800 hover:text-white"
            @click="showMenu = !showMenu">
            <font-awesome-icon icon='grip-vertical'></font-awesome-icon>
        </button>

        <!-- menu -->
        <transition name="third-transition">
            <div v-if="showMenu"
                class="z-10 flex flex-col w-full px-1 divide-y-2 divide-teal-700 sm:items-center md:w-auto md:flex-row md:ml-auto bg-gradient-to-r from-teal-400 to-green-400 divide-dotted md:divide-none md:from-transparent md:to-transparent">
                <template v-if="menuList.length > 0">
                    <div class="transition duration-300 ease-linear rounded group hover:bg-teal-800"
                        v-for="(m, mKey) in menuList" :key="'m'+mKey">
                        <router-link @click.native="toggle" tag="button" :to="m.link" class="px-2 py-3 font-bold text-teal-700 group-hover:text-white focus:outline-none">
                            <font-awesome-icon :icon='m.icon'></font-awesome-icon>
                            {{ m.label }}
                        </router-link>
                    </div>
                </template>
                <loading-block v-else></loading-block>
                <!-- user authority -->
                <!-- <jet-dropdown>
                    <template #trigger>
                        <button class="">
                            guest
                        </button>
                    </template>
                    <template #content>
                        content
                    </template>
                </jet-dropdown> -->
                <!-- recheck notification sound channel -->
                <notification-sound  wrapper-class="px-2 text-teal-700" :sound-url="$store.state.sound_notification" :echo-listener="{ channelName : $store.state.application_name, eventName : '.App\\Events\\OrderDemandCreated'}"/>
                <auth-menu-authority @buttonClicked="toggle"></auth-menu-authority>
                
            </div>
        </transition>
    </nav>
</template>

<script>
    // import JetDropdown from '../Jetstream/Dropdown.vue';
    // import JetDropdownLink from '../Jetstream/DropdownLink.vue';
    // import { JetDropdown } from "@/component";
    import { AuthMenuAuthority } from "@/composite";
    import { LoadingBlock, NotificationSound } from '@/component'

    export default {
        name: "Navigation",
        components: {
            // JetDropdown,
            // JetDropdownLink, Dropdown
            // JetDropdown
            AuthMenuAuthority,
            LoadingBlock, NotificationSound
        },
        data() {
            return {
                showMenu: true,
                isMobile: screen.width < 1280
            };
        },
        props: {
            menuList: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        methods: {
            toggle() {
                // only hide if mobile
                if (this.isMobile) {
                    this.showMenu = false;
                }
            },
            gotoHome() {
                // cancel if already
                if (this.$route.path == "/") { return }
                
                this.$router.push('/');
            }
        },
        created() {
            // init menu display
            this.showMenu = !this.isMobile;
        }
    };

</script>

<style lang="css" scoped>
    .active {
        @apply text-teal-200 outline-none;
    }
</style>
