import {sendRequest} from "../../api"

const getDefaultState = () => ({
    restricted_pages: [],
    selected_package: '---',
    fetched: false
}) 

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_INFO(state, { restricted_pages, selected_package }) {
            state.restricted_pages = restricted_pages
            state.selected_package = selected_package
            state.fetched = true
        }
    },
    actions: {
        loadInfo({commit}) {
            return sendRequest('commercial', {}, 'get', (fetchedInfo) => {
                commit('SET_INFO', { 
                    restricted_pages : fetchedInfo.restricted_pages,
                    selected_package : fetchedInfo.selected_package
                })
            })
        }
    }
})
