import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, RESET_FORM, SET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: [getDefaultRowState()],
        form_machines: [] // machine was move out from rows[0] for temp, to fix unreactive issue
    }
}

const getDefaultRowState = () => ({
    id: '',
    branch_id: '',
    expense_date_time : (new Date()).toISOString(),
    expense_category_id : '',
    description : '',
    qty: 1,
    unit: '',
    expense_price: 0,
    direct_cash: false,
    cash_account: null,
    cash_account_id: '',
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.push(getDefaultRowState())
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, payload) {
            const { machines, ...expense } = payload
            state.rows[0] = _.pick(expense, ['id', 'branch_id', 'expense_date_time', 'expense_category_id', 'description', 'qty', 'unit', 'expense_price', 'direct_cash',  'cash_account', 'cash_account_id'])
            state.form_machines = machines.map(m => m.id)
        },
        updateField

    },
    // can async
    actions: {

    },
    // call variable with custom display
    getters: {
        getField
    }
})
