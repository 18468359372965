import { getSearch, requestDelete, requestSubmit} from "../api"
import {
    ADD_IN_LIST,
    REMOVE_IN_LIST,
    RESET_STATE,
    SET_LIST,
    UPDATE_IN_LIST
} from "../constant-types"
import productForm from "./form/productForm"
import productPriceForm from "./form/productPriceForm"
import productMachineForm from "./form/productMachineForm"
import productCategory from './productCategory'
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        list: null,
        search_params: getDefaultSearchParamsState()
    }
}

const getDefaultSearchParamsState = () => {
    return {
        filter_search_text : '',
        loading : true,
        filter_sort : [{
            column : 'product_name',
            sort : 'asc',
            icon : ['fas', 'angle-up']
        }],
        filter_page : 1,
        filter_limit : 10,
        filter_show_deprecated: false
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_IN_LIST](state, createdProduct) {
            state.list.data.unshift(createdProduct)
        },
        [REMOVE_IN_LIST](state, deletedProduct) {
            let idx = state.list.data.findIndex(l => l.id === deletedProduct.id)
            state.list.data.splice(idx, 1)
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_LIST](state, list) {
            state.list = list
        },
        [UPDATE_IN_LIST](state, updatedProduct) {
            let idx = state.list.data.findIndex(l=> l.id === updatedProduct.id)
            state.list.data[idx] = updatedProduct
        },
        updateField

    },
    // can async
    actions: {
        loadList({commit, state}, payload = { resetPage : false, showDeprecated : false}) {
            if (payload.resetPage) {
                commit('updateField', { path : 'search_params.filter_page', value : 1})
            }
            if (payload.showDeprecated) {
                commit('updateField', { path : 'search_params.filter_show_deprecated', value : payload.showDeprecated})
            }
            let filterSearchMust = []
            // show only undeprecated product
            if (!state.search_params.filter_show_deprecated) {
                filterSearchMust.push([ false, '=', '', 'is_deprecated' ])
            }

            commit('updateField', { path : 'search_params.loading', value : true})
            return getSearch(
                {words: _.split(state.search_params.filter_search_text, ' '), fields : ['product_name', 'barcode', 'productCategory>category']},
                filterSearchMust,
                [],
                state.search_params.filter_sort,
                state.search_params.filter_page,
                state.search_params.filter_limit,
                [],
                [],
                'product',
                (response) => commit(SET_LIST, response),
                ()=>{},
                ()=>{
                    commit('updateField', { path : 'search_params.loading', value : false})
                }
            )
        },
        resetForm({commit}) {
            commit('product/productForm/RESET_FORM', null, { root : true})
            commit('product/productPriceForm/RESET_FORM', null, { root : true})
            commit('product/productCategory/productCategoryForm/RESET_FORM', null, { root : true})
        },
        requestCreateProduct({commit, state}) {
            let createdForm = { ...state.productForm.rows[0], filter_roles: state.productForm.filter_roles, product_prices : state.productPriceForm.rows, machines : state.productMachineForm.rows.map(r => ({ id: r })) }
            return requestSubmit('product', createdForm, commit, 'post', (createdProduct) => commit(ADD_IN_LIST, createdProduct))
        },
        requestUpdateProduct({commit, state}) {
            let createdForm = { ...state.productForm.rows[0], filter_roles: state.productForm.filter_roles, product_prices : state.productPriceForm.rows, machines : state.productMachineForm.rows.map(r => ({ id: r })) }
            return requestSubmit('product', createdForm, commit, 'put', (editedProduct) => commit(UPDATE_IN_LIST, editedProduct))
        },
        requestDeleteProduct({commit}, product) {
            return requestDelete(`product/${product.id}`, {}, commit, product.product_name, REMOVE_IN_LIST)
        }
    },
    // call variable with custom display
    getters: {
        getField
    },
    // modules
    modules: {
        productForm, productPriceForm, productCategory, productMachineForm
    }
})
