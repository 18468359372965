import {
    getField,
    updateField
} from "vuex-map-fields"
import { clearNotify, notify, sendRequest } from "../../api"
import {
    ADD_ROW, REMOVE_ROW, RESET_FORM, SET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: []
    }
}

const getDefaultRowState = () => ({
    // 'cashier_id' : '', // manual
    description : '',
    nominal : 0,
    code_direction: '',
    category: '',
    worker_id: ''
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.push(getDefaultRowState())
        },
        [REMOVE_ROW](state, rowIndex) {
            state.rows.splice(rowIndex, 1);
        },
        [SET_FORM](state, payload) {
            state.rows = JSON.parse(JSON.stringify(payload)).map(p => {
                return {
                    cashier_id : p.cashier_id,
                    category: p.category ? p.category.id : '',
                    code_direction: p.code_direction ? p.code_direction.id : '',
                    description: p.description,
                    id: p.id,
                    nominal: p.nominal,
                    worker_id: p.worker_id,
                    worker: p.worker
                }
            })
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        updateField

    },
    // can async
    actions: {
        requestUpdateCommonCost({state}) {
            notify('info', 'mengupdate biaya tambahan...')
            return sendRequest('cashier-common-cost', { cashier_common_costs: state.rows}, 'put', (successResponse) => {
                clearNotify()
                notify('success', 'update biaya tambahan berhasil...')
                
            }, (error) => {
                clearNotify()
            })
        }
    },
    // call variable with custom display
    getters: {
        getField,
        getTotalNominal(state) {
            // return _.sumBy(state.rows, o => +o.nominal)
            return state.rows.reduce((accumulator, currentValue) => accumulator + +currentValue.nominal, 0)
        }
    }
})
