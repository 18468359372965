const getDefaultState = () => {
    return {
        name: 'default',
        guest: true,
        client: null,
        fetched: false
    }
}

import { updateField } from 'vuex-map-fields'

import {
    notify,
    clearNotify
} from "./../../../api/index";

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setAuth(state, newClient) {
            if (newClient.id) {
                state.guest = false
            }
            state.client = newClient
            state.name = newClient.name
            state.fetched = true
        },
        updateField
        // alt: 
        //SET_AUTH(state, loggedUser) {
            //if (loggedUser) {
                //state.guest = loggedUser.isGuest;
                //Object.assign(state, { detail : loggedUser })
            //} else {
                //state.guest = true
                //Object.assign(state, { detail : null })
            //}
            //state.fetched = true
        //},
    },
    actions: {
        refreshAuth({
            commit
        }) {
            //job title
            return axios.get('oa-client/client/auth-client')
                .then((response) => {
                    //success
                    if (response.data.id) {
                        // set auth
                        commit('setAuth', response.data);
                    } else {
                        commit('resetState');
                        // set fetched to prevent routerHook still call
                        commit('updateField', { path: 'fetched', value: true })
                    }
                })
                .catch((error) => {
                    //failed
                    console.log(error);
                });
        },
        refreshToken() {
            axios.get('token')
                .then(function (res) {
                    // Refresh crsf token from session after login
                    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = res.data;
                });
        },
        loginAuth({
            dispatch
        }, credential) {
            notify('default', 'memproses login...', null)
            //job title
            axios.post('oa-client/client/login', {
                    username: credential.username,
                    password: credential.password,
                    remember: credential.remember
                })
                .then(async(response) => {
                    //success
                    clearNotify();
                    notify('success', 'login berhasil...')
                    // refresh auth then redirect to home
                    await dispatch('refreshAuth');
                    await dispatch('refreshToken');
                    app.$router.push({ name : 'client-dashboard' });
                })
                .catch((error) => {
                    //failed
                    clearNotify();
                    notify('error', 'login gagal...')
                    // show error info
                });
        },
        logoutAuth({
            dispatch, commit
        }) {
            notify('default', 'memproses logout...', null)
            return axios.post('oa-client/client/logout')
                .then((response) => {
                    //success
                    clearNotify();
                    notify('success', 'logout berhasil...')
                    // window.location.href = window.location.pathname;
                    // dispatch('refreshAuth');
                    // app.$router.push('/');
                    commit('resetState');
                })
                .catch((error) => {
                    //failed
                    clearNotify();
                    notify('error', 'logout gagal...')
                    //failed mean success of logout
                    // window.location.href = window.location.pathname;
                });
        },
    }
})
