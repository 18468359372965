import { sendRequest, notify, requestDelete } from "../api"
import {
    ADD_TO_QUICK_LIST,
    RESET_STATE, SET_LOADING, SET_QUICK_LIST, UPDATE_TO_QUICK_LIST
} from "../constant-types"
import orderDemandForm from "./form/orderDemandForm"
import orderDemandItemForm from "./form/orderDemandItemForm"

const getDefaultState = () => {
    return {
        list_quick: {
            unprocessed_list : [],
            unprocessed_list_count : 0,
            processed_list : [],
            processed_list_count : 0,
        },
        loading: false
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_TO_QUICK_LIST](state, createdOrderDemand) {
            // add to unprocessed list, new order demand should unprocessed
            state.list_quick.unprocessed_list.unshift(createdOrderDemand)
            // adjust count
            state.list_quick.unprocessed_list_count++
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_QUICK_LIST](state, list) {
            state.list_quick = list
        },
        [SET_LOADING](state, boolValue) {
            state.loading = boolValue
        },
        [UPDATE_TO_QUICK_LIST](state, updatedOrderDemand) {
            // note: updated order demand should be move to processed list if on unprocessed list
            // find on unprocessed list
            let idx_in_unprocessed = state.list_quick.unprocessed_list.findIndex(l => l.id === updatedOrderDemand.id)
            let idx_in_processed = state.list_quick.processed_list.findIndex(l => l.id === updatedOrderDemand.id)
            // move to processed list if found
            if (idx_in_unprocessed >= 0) {
                // update then move
                Object.assign(state.list_quick.unprocessed_list[idx_in_unprocessed], updatedOrderDemand)
                let related = state.list_quick.unprocessed_list[idx_in_unprocessed]

                // delete on unprocessed list, decrease counter
                state.list_quick.unprocessed_list.splice(idx_in_unprocessed, 1)
                state.list_quick.unprocessed_list_count--
                // add to processed list, increase counter
                state.list_quick.processed_list.unshift(related)
                state.list_quick.processed_list_count++
            }
            // if not found, then try find on processed list
            else if (idx_in_processed >= 0) {
                Object.assign(state.list_quick.processed_list[idx_in_processed], updatedOrderDemand)
            }
            // not found
            else {

            }
        },
        REMOVE_FROM_QUICK_LIST(state, deletedOrderDemand) {
            // find index
            let idx_in_unprocessed = state.list_quick.unprocessed_list.findIndex(l => l.id === deletedOrderDemand.id)
            let idx_in_processed = state.list_quick.processed_list.findIndex(l => l.id === deletedOrderDemand.id)
            // delete on unprocessed list, decrease counter
            if (idx_in_unprocessed >= 0) {
                state.list_quick.unprocessed_list.splice(idx_in_unprocessed, 1)
                state.list_quick.unprocessed_list_count--
            }
            // if not found, then try find on processed list, then delete and decrease counter
            else if (idx_in_processed >= 0) {
                state.list_quick.processed_list.splice(idx_in_processed, 1)
                state.list_quick.processed_list_count--
            }
            // not found
            else {

            }
        },
        // // from_aska
        // ADD_DESIGN_FILE_INSIDE_QUICK_LIST(state, { orderDemandId, orderDemandItemId, designFile }) {
        //     // OPTIMIZE: find more simple function
        //     let orderDemandIdx = state.list_quick.findIndex(l => l.id === orderDemandId)
        //     let orderDemandItemIdx = state.list_quick[orderDemandIdx].order_demand_items.findIndex(l => l.id === orderDemandItemId)
        //     state.list_quick[orderDemandIdx].order_demand_items[orderDemandItemIdx].design_files.push(designFile)
        // },
        // // from aska
        // REMOVE_DESIGN_FILE_INSIDE_QUICK_LIST(state, { orderDemandId, orderDemandItemId, designFileIdx }) {
        //     // OPTIMIZE: find more simple function
        //     let orderDemandIdx = state.list_quick.findIndex(l => l.id === orderDemandId)
        //     let orderDemandItemIdx = state.list_quick[orderDemandIdx].order_demand_items.findIndex(l => l.id === orderDemandItemId)
        //     state.list_quick[orderDemandIdx].order_demand_items[orderDemandItemIdx].design_files.splice(designFileIdx, 1)
        // }
    },
    // can async
    actions: {
        loadQuickList({
            commit
        }) {
            commit(SET_LOADING, true)
            return sendRequest('order-demand/quick-list', {}, 'get', (responseQuickList) => commit(SET_QUICK_LIST, responseQuickList), () => {}, () => { commit(SET_LOADING, false)})
        },
        requestCreateOrderDemand({commit, state}) {
            let createdForm = { ...state.orderDemandForm.rows[0], order_demand_items: state.orderDemandItemForm.rows }

            commit('requestStatus/REQUEST_SENDING', null, { root : true})
            // change form to json send, duo to size, change to sendRequest
            return sendRequest('order-demand', createdForm, 'post',
                (createdOrderDemand) => {
                    commit('requestStatus/REQUEST_SUCCESS', null, { root : true})
                    commit(ADD_TO_QUICK_LIST, createdOrderDemand)
                    commit('requestStatus/REQUEST_SENDED', null, { root : true})

                    return true
                },
                (failedResponse) => {
                    // handle 504 gateway time out, prevention of double order demand ( double spk ), don't stop request sending, to prevent resend again
                    if ( typeof failedResponse === 'string' ) {
                        if ( failedResponse.search('504') ) {
                            //console.log('its 504 gateway time out')
                            notify('error', 'refresh ki, ada gangguan jaringan, kemungkinan spk ta sudah masuk', null)
                            return false
                        }
                    }

                    commit('requestStatus/REQUEST_FAILED', null, { root : true})
                    return false
                },
                () => {
                    // move to each whether success or failed, to avoid double order demand ( double spk ) when on net slow
                    //commit('requestStatus/REQUEST_SENDED', null, { root : true})
                }, false, true, false
            )
        },
        requestDeleteOrderDemand({commit}, orderDemand) {
            return requestDelete(`order-demand/${orderDemand.id}`, {}, commit, `spk ${orderDemand?.client?.name}`, 'REMOVE_FROM_QUICK_LIST')
        },
        resetForm({commit}) {
            commit('orderDemand/orderDemandForm/RESET_FORM', null, { root: true})
            commit('orderDemand/orderDemandItemForm/RESET_FORM', null, { root: true})
        }
    },
    // call variable with custom display
    getters: {
        listQuickFiltered: (state, getters, rootState, rootGetters) => {
            let unprocessed_list = state.list_quick.unprocessed_list;
            let processed_list = state.list_quick.processed_list;
            let unprocessed_list_count = unprocessed_list.length
            let processed_list_count = processed_list.length

            // override : restrict on OrderDemandPage, active when checkRestrictOrderFlowForIsIn is true
            if (rootGetters['authUser/checkRestrictOrderFlowForIsIn']) {
                unprocessed_list = state.list_quick.unprocessed_list.filter(ul => rootGetters['authUser/checkRestrictOrderFlow']({machineIds : ul.order_demand_items.map(odi => odi.machine_id), clientCategoryId: ul.client.client_category_id}))
                processed_list = state.list_quick.processed_list.filter(ul => rootGetters['authUser/checkRestrictOrderFlow']({machineIds : ul.order_demand_items.map(odi => odi.machine_id), clientCategoryId: ul.client.client_category_id}))
            }

            return {
                unprocessed_list,
                processed_list,
                unprocessed_list_count,
                processed_list_count
            }
        }
    },
    modules: {
        orderDemandForm, orderDemandItemForm
    }
})
