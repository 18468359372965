import { clearNotify, notify, sendRequest } from "../api";
import { RESET_STATE } from "../constant-types";
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => {
    let dt = new Date()
    let today = dt.toISOString().split('T')[0]
    return {
        list : [],
        list_by_machine: null, // { list, machine }
        list_by_machine_loading: false,
        list_by_machine_taken_only: null,
        list_by_machine_taken_only_loading: false,
        list_by_machine_taken_only_params: {
            filter_machine_id: null, // set when loaded
            filter_code: '',
            filter_client_name: '',
            filter_order_date: today,
            filter_limit: 20,
            filter_page: 1
        },
        option_categories : [],
        logs: [], // order status logs
        unpayment_list : null, // unpayment with processed and processing
        unpayment_list_detail: null, // will determine whether by unpayment_type : takenUnpaid, processingUnpaid
        unpayment_list_detail_loading : false
    }
}

const failedMessageOfChangeStatus = (failedResponse) => {
    clearNotify()
    // add when has failed message
    let message =  'gagal mengupdate...'
    if ( failedResponse.message ) {
        message += ' ' + failedResponse.message
    }
    notify('error', message, 5000,'top-right');
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_LIST(state, payload) {
            // map selected on order_statuses
            state.list = payload.map(p => {
                const { order_statuses, ...p_remain } = p
                return {
                    ...p_remain,
                    order_statuses : order_statuses.map(os => {
                        os.selected = false
                        return os
                    })
                }
            })
        },
        'SET_LIST_BY_MACHINE'(state, fetchedList) {
            let { list, machine } = fetchedList
            state.list_by_machine = {
                machine,
                list: list.map(i => ({ show: true, ...i }))
            }
        },
        // toggle, to show only selected order status
        TOGGLE_SHOW_ONLY_ORDER_STATUS_BY_MACHINE(state, orderStatusId ) {
            let allShowed = state.list_by_machine.list.every(i => i.show)
            if (allShowed) {
                // hide other
                state.list_by_machine.list
                    .filter(l => l.id !== orderStatusId)
                    .forEach(l2 => l2.show = false)
            } else {
                // show all
                state.list_by_machine.list.forEach(l => l.show = true)
            }
        },
        SET_UNPAYMENT_LIST(state, payload) {
            state.unpayment_list = payload;
        },
        SET_LOG(state, payload) {
            state.logs = payload;
        },
        SET_OPTION_CATEGORIES(state, payload) {
            state.option_categories = payload;
        },
        // after status change, should update position
        'UPDATE_STATUS_POSITION'(state, { orderStatusId, toOrderStatusCategoryId, fromOrderStatusCategoryId, message }) {
            // ON LIST BY MACHINE
            if (state.list_by_machine) {
                // find exact position and move the data
                let fromOrderStatusCategoryIdx = state.list_by_machine.list.findIndex(l => l.id === fromOrderStatusCategoryId)
                let fromOrderStatusIdx = state.list_by_machine.list[fromOrderStatusCategoryIdx].order_statuses.findIndex(o => o.id === orderStatusId)

                // only update when operator page open the same detail ( state.list_by_machine )
                if (fromOrderStatusIdx >= 0) {
                    let orderStatus = state.list_by_machine.list[fromOrderStatusCategoryIdx].order_statuses[fromOrderStatusIdx]
                    // change
                    orderStatus.order_status_category_id = toOrderStatusCategoryId
                    orderStatus.message = message
                    // add to next
                    let nextOrderStatusCategoryIdx = state.list_by_machine.list.findIndex(l => l.id === toOrderStatusCategoryId)
                    // push when status exist
                    let next = state.list_by_machine.list[nextOrderStatusCategoryIdx]
                    if (next) {
                        next.order_statuses.push(orderStatus)
                    }

                    // delete on current
                    state.list_by_machine.list[fromOrderStatusCategoryIdx].order_statuses.splice(fromOrderStatusIdx, 1)
                }
            }

            // ON LIST
            // update on list if have
            if (state.list.length > 0) {
                // find order status category
                let fromOrderStatusCategoryIdx = state.list.findIndex(l => l.id === fromOrderStatusCategoryId )
                let toOrderStatusCategoryIdx = state.list.findIndex(l => l.id === toOrderStatusCategoryId )
                // move and change status
                let orderStatusIdx = state.list[fromOrderStatusCategoryIdx].order_statuses.findIndex(i => i.id === orderStatusId)
                let orderStatus = state.list[fromOrderStatusCategoryIdx].order_statuses[orderStatusIdx]
                orderStatus.order_status_category_id = toOrderStatusCategoryId
                orderStatus.message = message
                state.list[toOrderStatusCategoryIdx].order_statuses.push(orderStatus)

                state.list[fromOrderStatusCategoryIdx].order_statuses.splice(orderStatusIdx, 1)
            }
        },
        UPDATE_LIST_COUNTER(state, { orderStatusCategoryIds }) {
            if (state.list.length > 0) {
                orderStatusCategoryIds.forEach(o => {
                    let idx = state.list.findIndex(f => f.id === o)
                    state.list[idx].order_statuses_count = state.list[idx].order_statuses.length
                })

            }
        },
        updateField
    },
    // can async
    actions: {
        loadList({commit}, payload) {
            // vue progress bar
            app.$Progress.start();
            return sendRequest('order-status',payload,'get',
             (response) => commit('SET_LIST', response), () => {},
                () => {
                    // vue progress bar
                    app.$Progress.finish();
                }
            )
        },
        loadListByMachine({commit}, machineId) {
            // vue progress bar
            app.$Progress.start();
            commit('updateField', { path: 'list_by_machine_loading', value: true})
            return sendRequest(`order-status/machine/${machineId}`, {}, 'get', (fetchedList) => {
                commit('SET_LIST_BY_MACHINE', fetchedList)
            }, () => {}, () => {
                // vue progress bar
                app.$Progress.finish();
                commit('updateField', { path: 'list_by_machine_loading', value: false})
            })
        },
        loadListByMachineButTakenOnly({commit, state})  {
            // vue progress bar
            app.$Progress.start();
            const form = {
                filter_code: state.list_by_machine_taken_only_params.filter_code,
                filter_client_name: state.list_by_machine_taken_only_params.filter_client_name,
                filter_order_date: state.list_by_machine_taken_only_params.filter_order_date,
                filter_limit: state.list_by_machine_taken_only_params.filter_limit,
                filter_page: state.list_by_machine_taken_only_params.filter_page
            };
            commit('updateField', { path: 'list_by_machine_taken_only_loading', value: true})
            return sendRequest(`order-status/machine/taken-only/${state.list_by_machine_taken_only_params.filter_machine_id}`, form, 'get', (fetchedList) => {
                commit('updateField', { path: 'list_by_machine_taken_only', value: fetchedList})
            }, () => {}, () => {
                // vue progress bar
                app.$Progress.finish();
                commit('updateField', { path: 'list_by_machine_taken_only_loading', value: false})
            })
        },
        loadUnpaymentList({ commit }) {
            return sendRequest('order-status/unpayment', {} , 'get',
                (successResponse) => commit('SET_UNPAYMENT_LIST', successResponse),
                (errorResponse) => {},
                () => {}
            )
        },
        async loadUnpaymentListDetail({ commit }, unpaymentType) {
            commit('updateField', { path: 'unpayment_list_detail_loading', value: true})
            return await sendRequest('order-status/unpayment/detail', { unpayment_type : unpaymentType} , 'get',
                (successResponse) => {
                    commit('updateField', { path: 'unpayment_list_detail_loading', value: false})
                    commit('updateField', { path: 'unpayment_list_detail', value: successResponse})
                },
                (errorResponse) => {},
                () => {}
            )
        },
        // deprecated, because cashier moved to web
        // setUnexistedInList({}) {
        //     notify('info', 'merefresh...', null, 'top-right');
        //     return sendRequest('order-status/set-unexisted', {},'get',
        //         (successResponse) => {
        //             // clearNotify()
        //         },
        //         (errorRespose) => console.log(errorResponse),
        //         () => {
        //             clearNotify()
        //         }
        //     );
        // },
        loadOptionCategory({commit}) {
            return sendRequest('order-status-category/option', {}, 'get', (response) => { commit('SET_OPTION_CATEGORIES', response)})
        },
        requestChangeStatus({dispatch, state, commit, rootState, getters}, payload) {
            notify('info', 'mengubah status...', null, 'top-right');
            return sendRequest('order-status/change', { order_status_id : payload.order_status_id, to_order_status_category_id : payload.to_order_status_category_id, message: payload.message},'put',
                (successResponse) => {
                    clearNotify();
                    // refresh activity, because broadcast only to other
                    dispatch('activityLog/loadQuickList', null, { root : true })

                    // move position
                    commit('UPDATE_STATUS_POSITION', { orderStatusId : payload.order_status_id, toOrderStatusCategoryId : payload.to_order_status_category_id, fromOrderStatusCategoryId : payload.from_order_status_category_id, message: payload.message })

                    // update counter
                    commit('UPDATE_LIST_COUNTER', { orderStatusCategoryIds : [payload.from_order_status_category_id, payload.to_order_status_category_id] })

                    // refresh machine recap if loaded
                    if (rootState.machine.list.length > 0) {
                        let waitForPrintId = 4
                        let printFinishedId = 5
                        commit('machine/UPDATE_LIST_RECAP_COUNTER', { machineId: state.list_by_machine.machine.id, countWaitForPrint: getters.countJobInListByMachine(waitForPrintId), countPrintFinished: getters.countJobInListByMachine(printFinishedId) }, { root: true})
                    }
                },
                (failedResponse) => {
                    failedMessageOfChangeStatus(failedResponse)
                }
            )
        },
        requestChangeStatusBulk({dispatch, state, commit, rootState, getters}, { order_status_ids, from_order_status_category_id, to_order_status_category_id, message }) {
            notify('info', 'mengubah banyak status...', null, 'top-right');
            return sendRequest('order-status/change-bulk', { order_status_ids : order_status_ids, to_order_status_category_id : to_order_status_category_id, message},'post',
                (successResponse) => {
                    clearNotify();
                    // refresh activity, because broadcast only to other
                    dispatch('activityLog/loadQuickList', null, { root : true })

                    order_status_ids.forEach(osi => {
                        // move position
                        commit('UPDATE_STATUS_POSITION', { orderStatusId : osi, toOrderStatusCategoryId : to_order_status_category_id, fromOrderStatusCategoryId : from_order_status_category_id, message })

                        // update counter
                        commit('UPDATE_LIST_COUNTER', { orderStatusCategoryIds : [from_order_status_category_id, to_order_status_category_id] })

                        // refresh machine recap if loaded
                        if (rootState.machine.list.length > 0) {
                            let waitForPrintId = 4
                            let printFinishedId = 5
                            commit('machine/UPDATE_LIST_RECAP_COUNTER', { machineId: state.list_by_machine.machine.id, countWaitForPrint: getters.countJobInListByMachine(waitForPrintId), countPrintFinished: getters.countJobInListByMachine(printFinishedId) }, { root: true})
                        }

                    })
                },
                (failedResponse) => {
                    failedMessageOfChangeStatus(failedResponse)
                }
            )
        }
    },
    // call variable with custom display
    getters: {
        availableOptionCategory : (state) => (currentOrderStatusCategoryId, hideOrderStatusCategoryIds) => {
               return _.reject(state.option_categories, o => [currentOrderStatusCategoryId, ...hideOrderStatusCategoryIds].some(i => i == o.id));
        },
        // category id info =>  waitForPrintId = 4, printFinishedId = 5, and exclude canceled orders, include order success
        countJobInListByMachine: (state) => (categoryId) => {
            let relatedCategory = state.list_by_machine.list.find(l => l.id === categoryId)
            let counter = 0
            let countExcludeCanceled = relatedCategory.order_statuses.reduce( (prev, cur, idx, arr) => ( (cur.order_demand_item.order_demand.status_canceled === false && cur.order_demand_item.order_demand.status_ordered === true ) ? prev + 1 : prev ), counter )

            return countExcludeCanceled
        },
        translateOperator: state => {
            if (!state.list_by_machine) return;

            let headOperatorName = state.list_by_machine.machine.head_operator ? state.list_by_machine.machine.head_operator.name : '---'
            let result = headOperatorName + ' ( K.OP )'
            result += ' - ' + state.list_by_machine.machine.operators.map(op => op.name).join(', ')
            return result
        },
        getField
    }
})
