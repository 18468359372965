<template>
    <button
        class="bg-red-500 rounded px-2 py-1 text-xs text-red-200 font-bold hover:bg-red-600 hover:shadow-inner">
        <font-awesome-icon icon='trash-alt'></font-awesome-icon>
        <slot/>
    </button>
</template>

<script>
    export default {
        name: "DeleteButton",
        data() {
            return {};
        }
    };

</script>

<style lang="css" scoped>
</style>
