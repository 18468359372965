import { clearNotify, getSearch, notify, notifyConfirmation, sendRequest } from "../api";

const getDefaultState = () => {
    let today = (new Date()).toISOString().split('T')[0];
    // let today = "2018-11-13"

    return {
        someInitialState: true,
        order_list: null,
        search_params: {
            filter_page : 1,
            filter_limit : 10,
            filter_code: '',
            filter_sort : [
                {
                    column : 'created_at',
                    sort : 'desc',
                    icon : ['fas', 'angle-down']
                }
            ],
            filter_date : today
        }
    }
}

import { getField, updateField } from "vuex-map-fields";
import { REQUEST_FAILED, REQUEST_SENDED, REQUEST_SENDING, REQUEST_SUCCESS, RESET_STATE } from "../constant-types";

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_ORDER_LIST(state, payload) {
            state.order_list = payload
        },
        UPDATE_SEARCH_PARAMS_FIELD(state, field) {
            updateField(state.search_params, field)
        },
    },
    // can async
    actions: {
        cancelOrder({ dispatch }, payload) {
            // notify then continue
            notifyConfirmation('apakah yakin ingin membatalkan order...?',
                // continue
                (c) => {
                    let reason = prompt('tuliskan alasan order dibatalkan')

                    notify('error', 'membatalkan order...', null, 'top-right')
                    return sendRequest('order/cancel', {
                        cashier_id : payload.order.id, canceled_reason : reason, canceled_need_approve: payload.order.order_demand.canceled_need_approve, status_canceled: payload.order.order_demand.status_canceled
                    }, 'put',
                        (successResponse) => {
                            // refresh list
                            clearNotify();
                            dispatch('getOrderList')
                        },
                        (errorResponse) => { },
                        () => {  }
                    )
                },
                // stop
                (s) => {

                }
            )
        },
        approveCancelOrder({ dispatch }, payload) {
            // notify then continue
            notifyConfirmation('apakah yakin ingin menyetujui pembatalan order...?',
                // continue
                (c) => {
                    notify('error', 'membatalkan order...', null, 'top-right')
                    return sendRequest('order/cancel/approve', { cashier_id : payload.order.id }, 'put',
                        (successResponse) => {
                            // refresh list
                            clearNotify();
                            dispatch('getOrderList')
                        },
                        (errorResponse) => { },
                        () => {  }
                    )
                },
                // stop
                (s) => {

                }
            )
        },
        getOrderList({state, commit}) {
            // set status
            commit('requestStatus/'+[REQUEST_SENDING], null, { root : true});
            let filterPage = state.search_params.filter_page
            let filterSearchMust = [[state.search_params.filter_date, "=", "modeDate","order_date_time"]]
            if ( state.search_params.filter_code ) {
                filterSearchMust.push([
                    state.search_params.filter_code, '=', "", 'orderDemand>code'
                ])
                // when have filter code, should reset filter page to 1
                filterPage = 1
            }

            getSearch(
                { words: [], fields: [] },
                filterSearchMust,
                [],
                [],
                filterPage,
                state.search_params.filter_limit,
                [
                    'orderDemand.client', 'cashierItems.orderDemandItem.product', 'cashierItems.orderDemandItem.orderStatus.orderStatusCategory',
                    'orderDemand.canceledBy:id,name', 'orderDemand.canceledApprovedBy:id,name'
                ],
                [],
                'order',
                (successResponse) => {
                    commit('SET_ORDER_LIST', successResponse);
                    commit('requestStatus/'+[REQUEST_SUCCESS], null, { root : true});
                },
                () => {
                    commit('requestStatus/'+[REQUEST_FAILED], null, { root : true});
                },
                () => {
                    commit('requestStatus/'+[REQUEST_SENDED], null, { root : true});
                }

            )
        }

    },
    // call variable with custom display
    getters: {
        getSearchParamsField(state, field) {
            return getField(state.search_params, field)
        }
    }
})
