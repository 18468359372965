// @version V0.0.1 [Tuesday - 20201103_092924] : init

const getDefaultState = () => {
    return {
        pages: null,
        
    }
}

import { RESET_STATE } from "../../constant-types";
import {
    sendRequest
} from "./../../api";

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_PAGE(state, payload) {
            state.pages = payload;
        },
        

    },
    // can async
    actions: {
        getPage({commit}) {
            sendRequest('page', {}, 'get', (response) => commit('SET_PAGE', response),() => {}, () => {}, false, false);
        },
        
    },
    // call variable with custom display
    getters: {

    }
})
