// @version V0.3.2 : transformFormData should not continue when undefined
// @version V0.3.1 : rebuild transformFormData for full recursive, remove lodash, use native
// @version V0.3.0 : isFile now change to isFileOrDate, ability to detect Date constructor
// @version V0.2.2 : isFile identification on root level file array
// @version V0.2.1 : isFile now identified by constructor.name == 'File' , Object.keys.foreach to loop inside object, like array
// @version V0.2.0 : change isFile implementation to check by common file keys
// @version V0.1.0 [Friday - 20211105_164334] : added transformFormData for reloop between params
// @version V0.0.6 [Friday - 20210430_211936] : deprecated fileTypes, change to xxx.includes
// @version V0.0.5 [Thursday - 20210107_151137] : added isFile to determine file, deprecated freeKeys, stringifyByCondition changed from freeKeys to fileTypes
// @version V0.0.4 [Thursday - 20201210_165404] : added stringify by condition, to fix FormData problem with laravel validation && only run console error on development mode && added resultBoolean
// @version V0.0.3 [Saturday - 20201121_102524] : identified flatting form... object and array
// @version V0.0.2 [Tuesday - 20201117_175122] : flatting the form, to pass file
// @version V0.0.1 [Tuesday - 20201103_092924] : init

/**
 * this function may put 3rd parties, like axios, vue events, notification
 * @param {string} url
 * @param {Array, Object} formRaw params to send
 * @param {string} method like post, put, delete, patch, etc
 * @param {function} successCallback
 * @param {function} errorCallback
 * @param {function} finishCallback
 * @param {Boolean} useMethodSpoofing default : true, use post as real, but injecting _method to form
 * @param {Boolean} enableEvent default : true,  after then, catch and then finally -> will call vue event
 * @param {Boolean} useFormData default : true, will use new FormData(), come with stringify with condition, and should use  DecodeFromRequestData middleware to decode every request
 */
 export function sendRequest(url, formRaw, method, successCallback, errorCallback = () => {}, finishCallback = () => {}, useMethodSpoofing = true, enableEvent = true, useFormData = true) {

    let realMethod = method;

    if (useMethodSpoofing) {
        // laravel technique -> for put method, add _ method to field
        Object.assign(formRaw, {
            _method: method
        });
        realMethod = 'post';
    }

    // prepare form
    let form = '';

    // freeKeys deprecated, change to isFile
    // let freeKeys = ['_method', 'search', 'page', 'limit', 'sort', 'includeRelations', 'relationCounts', 'file']; // key in this array, will not stringify
    // let freeKeys = ['file']; // key in this array, will not stringify

    if (useFormData) {
        form = new FormData();
        for (const [key, value] of Object.entries(formRaw)) {
            transformFormData({ form : form , key : key , value : value })
        }
    } else {
        form = formRaw;
    }

    return axios[realMethod](url, form)
        .then((response) => {
            successCallback(response.data)
            if (enableEvent) {
                eventSuccess(response.data)
            }
            return true; // pass to finsih
        })
        .catch((error) => {
            // only run console error on development
            if (process.env.NODE_ENV == 'development') {

                console.log('error : ');
                console.log(error);
                console.log('error config')
                console.log(error.config);
                if (error.response) {
                    console.log('error-response')
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log('error-request')
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    console.log('error else')
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }

            errorCallback(error.response.data)
            if (enableEvent) {
                eventFailed(error.response.data)
            }
            return false; // pass to finish
        })
        .then((resultBoolean) => {
            // finished
            finishCallback()
            if (enableEvent) {
                eventFinish()
            }
            return resultBoolean;
        })
}

// deprecated, change to String(value.type).includes('image')
// let fileTypes = ['image/jpeg']; // in the future, this array should add

// rev : change freeKeys to fileTypes
function stringifyByCondition(value) {
    if (isFileOrDate(value)) {
        return value;
    } else {
        return JSON.stringify(value);
    }
}


function isFileOrDate(value) {
    if ( typeof (value) == 'object' && value != null ) {
        return ['File', 'Date'].some(e => e === value.constructor.name)
        // reference : https://stackoverflow.com/questions/1098040/checking-if-a-key-exists-in-a-javascript-object
        // deprecated // return !(value.type === undefined)
        // deprecated // return String(value.type).includes('image')
    } else {
        return false
    }
}

// note: rebuild
function transformFormData({ form, key, value }) {
    // should not continue if undefined
    if (typeof(value) === 'undefined') return


    if (value === null) {
        form.append(key, value)
    } else if ( value.constructor.name === 'Array' ) {
        // if is array
        value.forEach((rowData, rowIdx) => {
            let currentKey = key + '[' + rowIdx + ']'
            if (['Object', 'Array'].some(l => rowData.constructor.name === l)) {
                transformFormData({ form, key : currentKey, value : rowData })
            } else {
                form.append(currentKey, stringifyByCondition(rowData))
            }
        })
    } else if ( value.constructor.name === 'Object' ) {
        // if an object
        Object.entries(value).forEach(([keyName, val]) => {
            let currentKey = key + '[' + keyName + ']'
            if (val === null) {
                form.append(currentKey, val)
            } else if (['Object', 'Array'].some(l => val.constructor.name === l)) {
                transformFormData({ form, key: currentKey, value: val })
            } else {
                form.append(currentKey, stringifyByCondition(val))
            }
        })
    } else {
        // other
        form.append(key, stringifyByCondition(value))
    }
}

// deprecated : old of transformFormData, not full recursive
//function transformFormData({ form, key, value }) {
    //console.log(key);
    //// if file detected, then should add without stringify on root
    //if (isFileOrDate(value)) {
        //console.log('a');
        //form.append(key, value)
    //}
    //// check if nested, then should flatting
    //else if (typeof (value) == 'object' && value != null) {
        //console.log('b');
        //// continue if have length, then should be array
        //if (value.length > 0) {
            //console.log('c');
            //_.each(value, function (rowData, idx) {
                //if (isFileOrDate(rowData)) {
                    //console.log('c1');
                    //form.append(key + '[' + idx + ']', stringifyByCondition(rowData))
                //} else {
                    //console.log('c2');
                    //_.each(rowData, function (fieldValue, keyValue) {
                        //console.log('c21');
                        //let currentKey = key + '[' + idx + '][' + keyValue + ']';
                        //transformFormData({ form : form, key : currentKey, value: fieldValue})
                        //// form.append(key + '[' + idx + '][' + keyValue + ']', stringifyByCondition(fieldValue))
                    //})
                //}
            //})
        //}
        //// else, will be object
        //else {
            //console.log('d');
            //_.each(value, function (fieldValue, keyValue) {
                //let constructorName = fieldValue ? fieldValue.constructor.name : '---'
                //if (constructorName === 'Object') {
                    //console.log('d1');
                    //Object.keys(fieldValue).forEach(e => {
                        //form.append(key + '[' + keyValue + ']' + '[' + e + ']', stringifyByCondition(fieldValue[e]))

                    //})
                //} else {
                    //console.log('d2');
                    //console.log(fieldValue);
                    //console.log(keyValue);
                    //form.append(key + '[' + keyValue + ']', stringifyByCondition(fieldValue))
                    //console.log('/---d2');
                //}
            //})
        //}
    //} else {
        //console.log('e');
        //form.append(key, stringifyByCondition(value));
    //}
    //console.log('/--' + key);
//}

function eventFailed(errorResponseData) {
    app.$events.fire('show-error-response', errorResponseData)
}

function eventSuccess(responseData) {
    app.$events.fire('show-success-response', responseData)
}

function eventFinish() {
    app.$events.fire('show-finish-response')
}
