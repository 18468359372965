import {debounce} from 'lodash';
import { getField, updateField } from 'vuex-map-fields'
import { saveAs } from 'file-saver'

import {
    clearNotify,
    notify,
    sendRequest
} from "../api";
import {
    REQUEST_FAILED,
    REQUEST_SENDED,
    REQUEST_SENDING,
    REQUEST_SUCCESS,
    RESET_STATE
} from "../constant-types"

const getDefaultState = () => {
    let dt = new Date()
    let today = dt.toISOString();
    return {
        expense_branch_monthly: { list: [], recap_categories: []},
        sale_daily: [],
        sale_detail_periodically: { list: [] },
        sale_cancel_daily: { list: [] },
        sale_monthly: { list: []},
        sale_yearly: { list: []},
        sale_machine_monthly: { list: [], recaps: []},
        sale_machine_detail_periodically: { list: [], recaps: []},
        sale_all_machine_yearly: { list: [], chart: { labels: [], datasets: [] }, recap_by_machines: [], recap_total: []},
        sale_group_machine_monthly_periodically: {
            month_from : '',
            month_to : '',
            year_from : '', 
            year_to : '',
            running_months : [],
            report : {} 
        },
        client_payment_detail_periodically: { list: [], recaps: []},
        sale_customer_service_detail_periodically: { list: [], client_categories: []},
        sale_customer_service_monthly: { list: [], client_categories: [], negative_revenue: []},
        sale_all_customer_service_yearly: { chart: { labels: [], datasets: [] }, recap_by_months: [], list_by_customer_services: []},
        sale_order_demand_creator_detail_periodically: { list: [], client_categories: []},
        receivable_monthly: { list: [], recap: {}},
        receivable_periodically: { list: [], recap: {}},
        receivable_customer_service_detail_periodically: { list: [], recap: [] },
        receivable_group_detail_periodically: { list: [], recap: [] },
        order_demand_monthly: { list: [], recaps: []},
        order_demand_finished_by_staff_monthly: { list_designers : [], list_operators : [] },
        book_daily: null,
        filter_date: today,
        filter_date_from: today,
        filter_date_to: today,
        filter_month: dt.getMonth() + 1,
        filter_year: dt.getFullYear(),
        filter_month_to: dt.getMonth() + 1,
        filter_year_to: dt.getFullYear(),
        filter_branch_id: 1, // 1 as default
        filter_branch_ids: [],
        filter_branchs: [],
        filter_machine_id: null,
        filter_group_machines: [],
        filter_group_machine_ids: [],
        filter_client_category_id: null,
        filter_client_category_id_nested: null, // price in price
        filter_client_category_ids: [],
        filter_has_nested: false,
        filter_sort: [], // ex. [{ column: 'id', sort: 'asc' | 'desc' }],
        filter_client_ids: [], // ex [1, 3, 4]
        filter_creators: [],
        filter_creator_ids: [],
        filter_customer_service_ids: [], // ex [1, 3, 4]
        filter_customer_services: [],
        filter_group_ids: [], // ex [1, 3, 4]
        filter_groups: [],
        filter_compare_by: 'sum_price_sale', // as default, option : sum_price_sale | sum_price_modal | sum_price_substracted
        filter_sort_creators: [{ column: 'creator_name', sort: 'asc' }], // column for created_by_id on order_demands
        filter_sort_customer_services: [{ column: 'customer_service_name', sort: 'asc' }], // column : customer_service_name, sum_price_after_disc_with_cost, sort: asc, desc
        filter_sort_groups: [{ column: 'group_name', sort: 'asc' }], // column : group_name, sum_price_after_disc_with_cost, sort: asc, desc
        filter_sort_recap_details: [{ column: 'sum_price_sale', sort: 'desc' }], // column : client_name, sum_price_sale, sort: asc, desc
        filter_sort_details: [{ column: 'cashier_item_id', sort: 'asc' }], // column : client_name, price_sale, cashier_item_id, sort: asc, desc
        filter_includes: [], // ex ['expenses', 'dividen']
        filter_date_source: null, // object from filter_date_source_option
        filter_date_payment_source_method: null, // object from filter_date_payment_source_method_option
        filter_status_payment: null, // object from  filter_status_payment_option

        // options
        filter_sort_creators_option: [
            ['creator_name#asc', 'dari nama pembuat spk pertama'],
            ['creator_name#desc', 'dari nama pembuat spk terakhir'],
            ['sum_price_after_disc_with_cost#asc', 'dari penjualan terendah'],
            ['sum_price_after_disc_with_cost#desc', 'dari penjualan tertinggi']
        ],
        filter_sort_customer_services_option: [
            ['customer_service_name#asc', 'dari nama cs pertama'],
            ['customer_service_name#desc', 'dari nama cs terakhir'],
            ['sum_price_after_disc_with_cost#asc', 'dari penjualan terendah'],
            ['sum_price_after_disc_with_cost#desc', 'dari penjualan tertinggi']
        ],
        filter_sort_details_option: [
            ['client_name#asc', 'dari nama pelanggan pertama'],
            ['client_name#desc', 'dari nama pelanggan terakhir'],
            ['price_sale#asc', 'dari penjualan terendah'],
            ['price_sale#desc', 'dari penjualan tertinggi'],
            ['cashier_item_id#asc', 'dari urutan order awal'],
            ['cashier_item_id#desc', 'dari urutan order akhir'],
        ],
        filter_sort_recap_details_option: [
            ['client_name#asc', 'dari nama pelanggan pertama'],
            ['client_name#desc', 'dari nama pelanggan terakhir'],
            ['sum_price_sale#asc', 'dari jumlah penjualan terendah'],
            ['sum_price_sale#desc', 'dari jumlah penjualan tertinggi']
        ],
        filter_compare_by_option: [
            ['sum_price_sale', 'Harga Jual', 'HJ'],
            ['sum_price_modal', 'Harga Modal', 'HM'],
            ['sum_price_substracted', 'Harga Selisih', 'HS']
        ],
        filter_date_source_option: [
            { id: 'from_sale_date', label: 'dari tanggal transaksi' },
            { id: 'from_last_paid_date', label: 'dari tanggal pembayaran terakhir' },
        ],
        filter_date_payment_source_method_option: [
            { id: 'each_date', label: 'setiap tanggal pembayaran' },
            { id: 'merge_with_last', label: 'digabung ke tanggal pembayaran terakhir' },
        ],
        filter_status_payment_option: [
            { id: 'full-paid', label: 'lunas' },
            { id: 'half-paid', label: 'belum lunas / dp' },
            { id: 'half-and-full-paid', label: 'lunas dan belum lunas / dp' },
            { id: 'unpaid', label: 'belum terbayar' },
            { id: 'show-all', label: 'semua' },
        ]
    }
}

const loaderCommonReport = ({commit, commitName, requestUrl, requestParams }) => {
    commit('RESET_REPORT')
    commit('requestStatus/' + [REQUEST_SENDING], null, { root : true});
    return sendRequest(requestUrl, requestParams, 'get',
    (successResponse) => {
        commit('requestStatus/' + [REQUEST_SUCCESS], null, { root : true});
        commit(commitName, successResponse)
    },
    () => {
        commit('requestStatus/' + [REQUEST_FAILED], null, { root : true});
    },
    () => {
        commit('requestStatus/' + [REQUEST_SENDED], null, { root : true});
    })
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        RESET_REPORT(state) {
            state.expense_branch_monthly = { list: [], recap_categories: []}
            state.sale_daily = []
            state.sale_detail_periodically = { list: [] }
            state.sale_cancel_daily = { list: [] }
            state.sale_monthly = { list: []}
            state.sale_yearly = { list: []}
            state.sale_machine_monthly = { list: [], recaps: []}
            state.sale_machine_detail_periodically = { list: [], recaps: []}
            state.sale_all_machine_yearly = { list: [], chart: { labels: [], datasets: [] }, recap_by_machines: [], recap_total: []}
            state.sale_group_machine_monthly_periodically = {
                month_from: '',
                month_to: '',
                year_from: '',
                year_to: '',
                running_months: [],
                report: {} 
            },
            state.client_payment_detail_periodically = { list: [], recaps: []}
            state.sale_customer_service_detail_periodically = { list: [], client_categories: []}
            state.sale_customer_service_monthly = { list: [], client_categories: []}
            state.sale_order_demand_creator_detail_periodically = { list: [], client_categories: []}
            state.receivable_monthly = { list: [], recap: {}}
            state.receivable_periodically = { list: [], recap: {}}
            state.receivable_customer_service_detail_periodically = { list: [], recap: [] }
            state.receivable_group_detail_periodically = { list: [], recap: [] }
            state.order_demand_monthly = { list: [], recaps: []}
            state.order_demand_finished_by_staff_monthly = { list_designers : [], list_operators : [] }
            state.book_daily = null
        },
        SET_SALE_DAILY(state, payload) {
            state.sale_daily = payload;
        },
        SET_SALE_CANCEL_DAILY(state, payload) {
            state.sale_cancel_daily = payload;
        },
        SET_BOOK_DAILY(state, payload) {
            state.book_daily = payload;
        },
        SET_EXPENSE_BRANCH_MONTHLY(state, payload) {
            state.expense_branch_monthly = payload;
        },
        SET_SALE_MONTHLY(state, payload) {
            state.sale_monthly = payload;
        },
        SET_SALE_DETAIL_PERIODICALLY(state, payload) {
            state.sale_detail_periodically = payload;
        },
        SET_CLIENT_PAYMENT_DETAIL_PERIODICALLY(state, payload) {
            state.client_payment_detail_periodically = payload;
        },
        SET_SALE_MACHINE_MONTHLY(state, payload) {
            state.sale_machine_monthly = payload;
        },
        SET_SALE_MACHINE_DETAIL_PERIODICALLY(state, payload) {
            state.sale_machine_detail_periodically = payload;
        },
        SET_SALE_ALL_MACHINE_YEARLY(state, payload) {
            state.sale_all_machine_yearly = payload;
        },
        SET_SALE_GROUP_MACHINE_MONTHLY_PERIODICALLY(state, payload) {
            state.sale_group_machine_monthly_periodically = payload
        },
        SET_SALE_CUSTOMER_SERVICE_DETAIL_PERIODICALLY(state, payload) {
            state.sale_customer_service_detail_periodically = payload
        },
        SET_SALE_CUSTOMER_SERVICE_MONTHLY(state, payload) {
            state.sale_customer_service_monthly = payload;
        },
        SET_SALE_ALL_CUSTOMER_SERVICE_YEARLY(state, payload) {
            state.sale_all_customer_service_yearly = payload;
        },
        SET_SALE_ORDER_DEMAND_CREATOR_DETAIL_PERIODICALLY(state, payload) {
            state.sale_order_demand_creator_detail_periodically = payload
        },
        SET_RECEIVABLE_MONTHLY(state, payload) {
            state.receivable_monthly = payload;
        },
        SET_RECEIVABLE_PERIODICALLY(state, payload) {
            state.receivable_periodically = payload;
        },
        SET_RECEIVABLE_CUSTOMER_SERVICE_DETAIL_PERIODICALLY(state, payload) {
            state.receivable_customer_service_detail_periodically = payload;
        },
        SET_RECEIVABLE_GROUP_DETAIL_PERIODICALLY(state, payload) {
            state.receivable_group_detail_periodically = payload;
        },
        SET_ORDER_DEMAND_MONTHLY(state, payload) {
            state.order_demand_monthly = payload;
        },
        SET_ORDER_DEMAND_FINISHED_BY_STAFF_MONTHLY(state, payload) {
            state.order_demand_finished_by_staff_monthly = payload;
        },
        SET_SALE_YEARLY(state, payload) {
            state.sale_yearly = payload;
        },
        SET_FILTER_DATE(state, payload) {
            state.filter_date = payload;
        },
        SET_FILTER_DATE_FROM(state, payload) {
            state.filter_date_from = payload;
        },
        SET_FILTER_DATE_TO(state, payload) {
            state.filter_date_to = payload;
        },
        SET_FILTER_MACHINE_ID(state, payload) {
            state.filter_machine_id = payload
        },
        SET_FILTER_CLIENT_CATEGORY_ID(state, payload) {
            state.filter_client_category_id = payload
        },
        SET_FILTER_CLIENT_CATEGORY_ID_NESTED(state, payload) {
            state.filter_client_category_id_nested = payload
            state.filter_has_nested = payload ? true : false
        },
        SET_FILTER_DATE_SOURCE(state, payload) {
            state.filter_date_source = payload
        },
        SET_FILTER_DATE_PAYMENT_SOURCE_METHOD(state, payload) {
            state.filter_date_payment_source_method = payload
        },
        SET_FILTER_STATUS_PAYMENT(state, payload) {
            state.filter_status_payment = payload
        },
        updateField
    },
    // can async
    actions: {
        loadBookDailyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_BOOK_DAILY', requestUrl : 'report/book-daily', requestParams : {
                filter_date: state.filter_date,
                filter_branch_id: state.filter_branch_id,
                filter_date_payment_source_method: JSON.stringify(state.filter_date_payment_source_method)
            }})
        }, 400),
        loadSaleDailyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_DAILY', requestUrl : 'report/sale-daily', requestParams : {
                filter_date: state.filter_date,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        loadSaleDetailPeriodicallyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_DETAIL_PERIODICALLY', requestUrl : 'report/sale-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_machine_id: state.filter_machine_id ,
                filter_client_category_id: state.filter_client_category_id,
                filter_client_category_id_nested: state.filter_client_category_id_nested,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        exportExcelSaleDetailPeriodicallyReport({state}) {
            notify('info', 'memproses export excel...', null)
            return axios.get('report/sale-detail-periodically/export-excel', { responseType : 'blob', params : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_machine_id: state.filter_machine_id ,
                filter_client_category_id: state.filter_client_category_id,
                filter_client_category_id_nested: state.filter_client_category_id_nested,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)

            }})
                .then(response => {
                    clearNotify()
                    saveAs(response.data)
                })
        },
        loadClientPaymentDetailPeriodicallyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_CLIENT_PAYMENT_DETAIL_PERIODICALLY', requestUrl : 'report/client-payment-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_machine_id: state.filter_machine_id ,
                filter_client_category_id: state.filter_client_category_id,
                filter_client_category_id_nested: state.filter_client_category_id_nested,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        exportExcelClientPaymentDetailPeriodicallyReport({state}) {
            notify('info', 'memproses export excel...', null)
            return axios.get('report/client-payment-detail-periodically/export-excel', { responseType : 'blob', params : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_machine_id: state.filter_machine_id ,
                filter_client_category_id: state.filter_client_category_id,
                filter_client_category_id_nested: state.filter_client_category_id_nested

            }})
                .then(response => {
                    clearNotify()
                    saveAs(response.data)
                })
        },
        loadSaleCancelDailyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_CANCEL_DAILY', requestUrl : 'report/sale-cancel-daily', requestParams : {
                filter_date: state.filter_date,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        loadExpenseBranchMonthlyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_EXPENSE_BRANCH_MONTHLY', requestUrl : 'report/expense-branch-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        exportExcelExpenseBranchMonthlyReport({state}) {
            notify('info', 'memproses export excel...', null)
            return axios.get('report/expense-branch-monthly/export-excel', { responseType : 'blob', params : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)

            }})
                .then(response => {
                    clearNotify()
                    saveAs(response.data)
                })
        },
        loadSaleMonthlyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_MONTHLY', requestUrl : 'report/sale-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        loadSaleYearlyReport: debounce(async function ({ state, commit }) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_YEARLY', requestUrl : 'report/sale-yearly', requestParams : {
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        loadSaleMachineMonthlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_MACHINE_MONTHLY', requestUrl : 'report/sale-machine-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        loadSaleMachineDetailPeriodicallyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_MACHINE_DETAIL_PERIODICALLY', requestUrl : 'report/sale-machine-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_machine_id: state.filter_machine_id,
                filter_includes: state.filter_includes,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids),
                filter_date_source: JSON.stringify(state.filter_date_source),
                filter_status_payment: JSON.stringify(state.filter_status_payment)
            }})
        },
        exportExcelSaleMachineDetailPeriodicallyReport({state}) {
            notify('info', 'memproses export excel...', null)
            return axios.get('report/sale-machine-detail-periodically/export-excel', { responseType : 'blob', params : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_machine_id: state.filter_machine_id,
                filter_includes: state.filter_includes,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids),
                filter_date_source: JSON.stringify(state.filter_date_source),
                filter_status_payment: JSON.stringify(state.filter_status_payment)
            }})
                .then(response => {
                    clearNotify()
                    saveAs(response.data)
                })
        },
        loadSaleAllMachineYearlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_ALL_MACHINE_YEARLY', requestUrl : 'report/sale-all-machine-yearly', requestParams : {
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids),
                filter_client_category_ids: JSON.stringify(state.filter_client_category_ids)
            }})
        },
        loadSaleGroupMachinePeriodically({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_GROUP_MACHINE_MONTHLY_PERIODICALLY', requestUrl : 'report/sale-group-machine-monthly-periodically', requestParams : {
                filter_month_from : state.filter_month,
                filter_month_to : state.filter_month_to,
                filter_year_from : state.filter_year,
                filter_year_to : state.filter_year_to,
                filter_group_machine_ids: JSON.stringify(state.filter_group_machine_ids)
            }})
        },
        loadSaleCustomerServiceDetailPeriodicallyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_CUSTOMER_SERVICE_DETAIL_PERIODICALLY', requestUrl : 'report/sale-customer-service-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_customer_service_ids: JSON.stringify(state.filter_customer_service_ids),
                filter_sort_customer_services: JSON.stringify(state.filter_sort_customer_services),
                filter_sort_details: JSON.stringify(state.filter_sort_details),
                filter_sort_recap_details: JSON.stringify(state.filter_sort_recap_details),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids),
                filter_status_payment: JSON.stringify(state.filter_status_payment)
            }})
        },
        loadSaleCustomerServiceMonthlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_CUSTOMER_SERVICE_MONTHLY', requestUrl : 'report/sale-customer-service-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_customer_service_ids: JSON.stringify(state.filter_customer_service_ids),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        loadSaleAllCustomerServiceYearlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_ALL_CUSTOMER_SERVICE_YEARLY', requestUrl : 'report/sale-all-customer-service-yearly', requestParams : {
                filter_year: state.filter_year,
                filter_customer_service_ids: JSON.stringify(state.filter_customer_service_ids),
                filter_compare_by: state.filter_compare_by,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        loadSaleOrderDemandCreatorDetailPeriodicallyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_SALE_ORDER_DEMAND_CREATOR_DETAIL_PERIODICALLY', requestUrl : 'report/sale-order-demand-creator-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_creator_ids: JSON.stringify(state.filter_creator_ids),
                filter_sort_creators: JSON.stringify(state.filter_sort_creators),
                filter_sort_details: JSON.stringify(state.filter_sort_details),
                filter_sort_recap_details: JSON.stringify(state.filter_sort_recap_details),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids),
                filter_status_payment: JSON.stringify(state.filter_status_payment)
            }})
        },
        loadReceivableMonthlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_RECEIVABLE_MONTHLY', requestUrl : 'report/receivable-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_sort: JSON.stringify(state.filter_sort),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        exportExcelReceivableMonthlyReport({state}) {
            notify('info', 'memproses export excel...', null)
            return axios.get('report/receivable-monthly/export-excel', { responseType : 'blob', params : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_sort: JSON.stringify(state.filter_sort),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)

            }})
                .then(response => {
                    clearNotify()
                    saveAs(response.data)
                })
        },
        loadReceivablePeriodicallyReport: debounce(async function ({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_RECEIVABLE_PERIODICALLY', requestUrl : 'report/receivable-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_client_ids: JSON.stringify(state.filter_client_ids),
                filter_sort: JSON.stringify(state.filter_sort),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        }, 400),
        loadReceivableCustomerServiceDetailPeriodicallyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_RECEIVABLE_CUSTOMER_SERVICE_DETAIL_PERIODICALLY', requestUrl : 'report/receivable-customer-service-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_customer_service_ids: JSON.stringify(state.filter_customer_service_ids),
                filter_sort_customer_services: JSON.stringify(state.filter_sort_customer_services),
                filter_sort_details: JSON.stringify(state.filter_sort_details),
                filter_sort_recap_details: JSON.stringify(state.filter_sort_recap_details),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        loadReceivableGroupDetailPeriodicallyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_RECEIVABLE_GROUP_DETAIL_PERIODICALLY', requestUrl : 'report/receivable-group-detail-periodically', requestParams : {
                filter_date_from: state.filter_date_from,
                filter_date_to: state.filter_date_to,
                filter_group_ids: JSON.stringify(state.filter_group_ids),
                filter_sort_groups: JSON.stringify(state.filter_sort_groups),
                filter_sort_details: JSON.stringify(state.filter_sort_details),
                filter_sort_recap_details: JSON.stringify(state.filter_sort_recap_details),
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        loadOrderDemandMonthlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_ORDER_DEMAND_MONTHLY', requestUrl : 'report/order-demand-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
        loadOrderDemandFinishedByStaffMonthlyReport({state, commit}) {
            return loaderCommonReport({commit, commitName: 'SET_ORDER_DEMAND_FINISHED_BY_STAFF_MONTHLY', requestUrl : 'report/order-demand-finished-by-staff-monthly', requestParams : {
                filter_month: state.filter_month,
                filter_year: state.filter_year,
                filter_branch_ids: JSON.stringify(state.filter_branch_ids)
            }})
        },
    },
    // call variable with custom display
    getters: {
        getBookDailyOwnerDirectCashIncomeGroup: state => {
            return _.groupBy(state.book_daily.ownerDirectCashIncome, 'receiver_name')
        },
        getSumTotalOfSaleDaily(state) {
            return _.sumBy(state.sale_daily, 'price_total_after_disc');
        },
        getSumPaidOfSaleDaily(state) {
            return _.sumBy(state.sale_daily, 'price_paid')
        },
        getSumRemainOfSaleDaily(state) {
            return _.sumBy(state.sale_daily, 'price_remain')
        },
        getSumCashierCashIncomeOfBookDaily(state) {
            let result = 0
            _.each(state.book_daily.cashierCashIncome, function(val){
                _.each(val.payments, function(p) {
                    result += p.nominal
                })
            })
            return result;
        },
        getSumCashierCashExpenseOfBookDaily(state) {
            return _.sumBy(state.book_daily.cashierCashExpense, 'expense_price')
        },
        getSumOwnerDirectCashIncomeOfBookDaily(state) {
            let result = 0
            _.each(state.book_daily.ownerDirectCashIncome, function(p) {
                result += p.nominal
            })
            return result
        },
        getSumOwnerWithdrawOfBookDaily(state) {
            return _.sumBy(state.book_daily.ownerWithdraw, 'cash_amount')
        },
        getMonthYearPeriod(state) {
            return 'Bulan ' + state.filter_month + ' Tahun ' + state.filter_year
        },
        getMonthYearPeriodRange(state) {
            return 'Bulan ' + state.filter_month + ' Tahun ' + state.filter_year + ' s/d ' +
            'Bulan ' + state.filter_month_to + ' Tahun ' + state.filter_year_to
        },
        getPeriodicallyPeriod(state) {
            return new Intl.DateTimeFormat('id').format(new Date(state.filter_date_from)) + ' s.d ' +
            new Intl.DateTimeFormat('id').format(new Date(state.filter_date_to))
        },
        getFilterCompareByAbbrev: state => key => {
            return state.filter_compare_by_option.find(o => o[0] === key)[2]
        },
        getField
    }
})
