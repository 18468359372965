import { requestDelete, requestSubmit, sendRequest } from "../api"
import customerServiceForm from './form/customerServiceForm'

const getDefaultState = () => {
    return {
        options: [],
        option_loading: false
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_OPTION(state, payload) {
            state.options = payload
        },
        ADD_TO_OPTION(state, payload) {
            state.options.unshift(payload)
        },
        UPDATE_IN_OPTION(state, payload) {
            let relatedOption = state.options.find(o => o.id == payload.id)
            if (relatedOption) {
                relatedOption = payload
            }
        },
        REMOVE_FROM_OPTION(state, payload) {
            let idx = state.options.findIndex(o => o.id == payload.id)
            if (idx !== -1) {
                state.options.splice(idx, 1)
            }
        },
        SET_OPTION_LOADING(state, payload) {
            state.option_loading = payload
        },
        ADD_GROUP_IN_OPTION(state, { customerService, group }) {
            // find in list
            const findCustomerService = state.options.find(o => o.id === customerService.id)
            if ( !findCustomerService ) return

            // only push when not exist
            const exist = findCustomerService.groups.find(g => g.id === group.id)
            if (exist) return

            findCustomerService.groups.push(group)
        },
        REMOVE_GROUP_IN_OPTION(state, { customerService, group }) {
            // find in list
            const findCustomerService = state.options.find(o => o.id === customerService.id)
            if ( !findCustomerService ) return
            findCustomerService.groups.splice(findCustomerService.groups.findIndex(i => i.id === group.id), 1)
        }
    },
    // can async
    actions: {
        loadOptions({commit}, showAll = false) {
            commit('SET_OPTION_LOADING', true)
            sendRequest('customer-service/option', { show_all : showAll }, 'get', (fetchedOption) => commit('SET_OPTION', fetchedOption), () => {}, () => {  commit('SET_OPTION_LOADING', false) })
        },
        requestCreate({commit, state}) {
            return requestSubmit('customer-service', state.customerServiceForm.rows[0], commit, 'post', (fetchedCustomerService) => {
                 commit('ADD_TO_OPTION', fetchedCustomerService)
            })
        },
        requestUpdate({commit, state}) {
            return requestSubmit('customer-service/' + state.customerServiceForm.rows[0].id, state.customerServiceForm.rows[0], commit, 'put', (fetchedCustomerService) => {
                commit('UPDATE_IN_OPTION', fetchedCustomerService)
            })
        },
        requestDelete({commit, state}, payload) {
            return requestDelete(`customer-service/${payload.id}`, {}, commit, payload.name, 'REMOVE_FROM_OPTION')
        },
        addGroupInOption({commit}, { customerService, group}) {
            commit('ADD_GROUP_IN_OPTION', { customerService, group})
        },
        removeGroupInOption({commit}, { customerService, group}) {
            commit('REMOVE_GROUP_IN_OPTION', { customerService, group})
        }
    },
    // call variable with custom display
    getters: {

    },
    // modules
    modules: {
        customerServiceForm
    }
})
