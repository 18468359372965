// @version V0.0.1 [Wednesday - 20201028_093808] : init

export default  ([
    {
        path : '/',
        name: 'home',
        redirect : 'welcome',
        component: require('./../fadel/Home').default,
        children: [
            {
                path: 'welcome',
                name: 'welcome',
                component: () => import('./../fadel/Welcome')
            },
            {
                path: 'guest/login',
                name: 'guest-login',
                component: () => import('./../fadel/guest/LoginPage')
            },
            // {
            //     path: 'guest/register',
            //     component: () => import('./../composite/auth/Register')
            // },
            {
                path: 'version-info',
                name: 'version-info',
                component: () => import('./../fadel/developer/VersionPage')
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('./../fadel/Dashboard')
            },
            {
                path: 'transaction',
                name: 'transaction',
                component: () => import('./../fadel/transaction/TransactionPage'),
                children: [
                    {
                        path: 'order-cancel',
                        name: 'transaction-order-cancel',
                        component: () => import('./../fadel/transaction/OrderCancel')
                    },
                    {
                        path: 'cash',
                        name: 'transaction-cash',
                        component: () => import('./../fadel/transaction/Cash')
                    },
                    {
                        path: 'order-demand',
                        name: 'order-demand',
                        component: () => import('./../fadel/order-demand/OrderDemandPage')
                    },
                    {
                        path: 'cashier',
                        name: 'cashier',
                        component: () => import('./../fadel/cashier/CashierPage'),
                        children: [
                            {
                                path: 'new',
                                name: 'cashier-new',
                                meta: { actionKey : 'can-transact-cashier' },
                                component: () => import('./../fadel/cashier/CashierNew'),
                            },
                            {
                                path: 'edit',
                                name: 'cashier-edit',
                                meta: { actionKey : 'can-transact-cashier' },
                                component: () => import('./../fadel/cashier/CashierEdit'),
                            },
                            {
                                path: 'read',
                                name: 'cashier-read',
                                meta: { actionKey : 'can-see-cashier-detail' },
                                component: () => import('./../fadel/cashier/CashierRead'),
                            },
                            {
                                path: 'receipt',
                                name: 'cashier-receipt',
                                meta: { actionKey : 'can-see-cashier-detail' },
                                component: () => import('./../fadel/cashier/CashierReceipt'),
                                props : true
                            },
                            {
                                path: 'payment',
                                name: 'cashier-payment',
                                meta: { actionKey: 'can-transact-cashier' },
                                component: () => import('./../fadel/cashier/CashierPayment')
                            },
                            {
                                path: 'work-order-letter',
                                name: 'work-order-letter',
                                meta: { actionKey: 'can-see-cashier-detail' },
                                component: () => import('./../fadel/operator/WorkOrderLetter')
                            }
                        ]
                    },
                    {
                        path: 'expense',
                        name: 'expense',
                        redirect: { name: 'expense-list'},
                        component: () => import('./../fadel/expense/ExpensePage'),
                        children: [
                            {
                                path: 'list',
                                name: 'expense-list',
                                component: () => import('./../fadel/expense/ExpenseIndex')
                            },
                            {
                                path: 'edit',
                                name: 'expense-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/expense/ExpenseEdit')
                            },
                            {
                                path: 'new',
                                name: 'expense-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/expense/ExpenseNew')
                            },
                            {
                                path: 'category/list',
                                name: 'expense-category-list',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/expense-category/ExpenseCategoryIndex')
                            },
                            {
                                path: 'category/new',
                                name: 'expense-category-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/expense-category/ExpenseCategoryNew')
                            },
                            {
                                path: 'category/edit',
                                name: 'expense-category-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/expense-category/ExpenseCategoryEdit')
                            }
                        ]
                    }
                ]
            },
            {
                path: 'operator',
                name: 'operator',
                component: () => import('./../fadel/operator/OperatorPage'),
                children: [
                    {
                        path: 'detail',
                        name: 'operator-detail',
                        component: () => import('./../fadel/operator/OperatorDetail')
                    },
                    {
                        path: 'history-taken',
                        name: 'operator-history-taken',
                        component: () => import('./../fadel/operator/OperatorHistoryTaken')
                    }
                ]
            },
            {
                path: 'report',
                name: 'report',
                component: () => import('./../fadel/report/ReportPage'),
                children: [
                    {
                        path : 'sale-report',
                        name: 'sale-report',
                        component: () => import('./../fadel/report/SaleReportPage'),
                        children: [
                            {
                                path : 'sale-daily',
                                name: 'sale-daily',
                                component: () => import('./../fadel/report/SaleDailyReport')
                            },
                            {
                                path: 'sale-detail-periodically',
                                name: 'sale-detail-periodically',
                                component: () => import('./../fadel/report/SaleDetailPeriodicallyReport.vue')
                            },
                        ]
                    },
                    {
                        path: 'client-payment-detail-periodically',
                        name: 'client-payment-detail-periodically',
                        component: () => import('./../fadel/report/ClientPaymentDetailPeriodicallyReport.vue')
                    },
                    {
                        path : 'sale-cancel-daily',
                        name : 'sale-cancel-daily',
                        component: () => import('./../fadel/report/SaleCancelDailyReport')
                    },
                    {
                        path: 'book-daily',
                        name: 'book-daily',
                        component: () => import('./../fadel/report/BookDailyReport')
                    },
                    {
                        path: 'expense-branch-monthly',
                        name: 'expense-branch-monthly',
                        component: () => import('./../fadel/report/ExpenseBranchMonthlyReport')
                    },
                    {
                        path: 'omzet-report',
                        name: 'omzet-report',
                        component: () => import('./../fadel/report/OmzetReportPage'),
                        children: [
                            {
                                path: 'sale-monthly',
                                name: 'sale-monthly',
                                component: () => import('./../fadel/report/SaleMonthlyReport')
                            },
                            {
                                path: 'sale-yearly',
                                name: 'sale-yearly',
                                component: () => import('./../fadel/report/SaleYearlyReport')
                            },
                            {
                                path: 'sale-machine-monthly',
                                name: 'sale-machine-monthly',
                                component: () => import('./../fadel/report/SaleMachineMonthlyReport')
                            },
                            {
                                path: 'sale-machine-detail-periodically',
                                name: 'sale-machine-detail-periodically',
                                component: () => import('./../fadel/report/SaleMachineDetailPeriodicallyReport.vue')
                            },
                            {
                                path: 'sale-all-machine-yearly',
                                name: 'sale-all-machine-yearly',
                                component: () => import('./../fadel/report/SaleAllMachineYearlyReport.vue')
                            },
                            {
                                path: 'sale-group-machine-monthly-periodically',
                                name: 'sale-group-machine-monthly-periodically',
                                component: () => import('./../fadel/report/SaleGroupMachineMonthlyPeriodically.vue')
                            },
                            {
                                path: 'sale-customer-service-monthly',
                                name: 'sale-customer-service-monthly',
                                component: () => import('./../fadel/report/SaleCustomerServiceMonthlyReport')
                            },
                            {
                                path: 'sale-customer-service-detail-periodically',
                                name: 'sale-customer-service-detail-periodically',
                                component: () => import('./../fadel/report/SaleCustomerServiceDetailPeriodicallyReport')
                            },
                            {
                                path: 'sale-all-customer-service-yearly',
                                name: 'sale-all-customer-service-yearly',
                                component: () => import('./../fadel/report/SaleAllCustomerServiceYearlyReport')
                            },
                            {
                                path: 'sale-order-demand-creator-detail-periodically',
                                name: 'sale-order-demand-creator-detail-periodically',
                                component: () => import('./../fadel/report/SaleOrderDemandCreatorDetailPeriodicallyReport')
                            },
                        ]
                    },
                    {
                        path: 'receivable-report',
                        name: 'receivable-report',
                        component: () => import('./../fadel/report/ReceivableReportPage'),
                        children: [
                            {
                                path: 'receivable-monthly',
                                name: 'receivable-monthly',
                                component: () => import('./../fadel/report/ReceivableMonthlyReport')
                            },
                            {
                                path: 'receivable-periodically',
                                name: 'receivable-periodically',
                                component: () => import('./../fadel/report/ReceivablePeriodicallyReport')
                            },
                            {
                                path: 'receivable-customer-service-detail-periodically',
                                name: 'receivable-customer-service-detail-periodically',
                                component: () => import('./../fadel/report/ReceivableCustomerServiceDetailPeriodicallyReport')
                            },
                            {
                                path: 'receivable-group',
                                name: 'receivable-group',
                                component: () => import('./../fadel/report/ReceivableGroupPage'),
                                children: [
                                    {
                                        path: 'receivable-group-manage',
                                        name: 'receivable-group-manage',
                                        component: () => import('./../fadel/group-client/GroupClientPage'),
                                        children: [
                                            {
                                                path: 'new',
                                                name: 'group-client-new',
                                                meta: { isActionRoute: true },
                                                component: () => import('./../fadel/group-client/GroupClientNew')
                                            },
                                            {
                                                path: 'edit',
                                                name: 'group-client-edit',
                                                meta: { isActionRoute: true },
                                                component: () => import('./../fadel/group-client/GroupClientEdit')
                                            },
                                            {
                                                path: 'detail',
                                                name: 'group-client-detail',
                                                meta: { isActionRoute: true },
                                                component: () => import ('./../fadel/group-client/GroupClientDetail')
                                            }
                                        ]
                                    },
                                    {
                                        path: 'receivable-group-detail-periodically',
                                        name: 'receivable-group-detail-periodically',
                                        component: () => import('./../fadel/report/ReceivableGroupDetailPeriodicallyReport')
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        path: 'order-demand-report',
                        name: 'order-demand-report',
                        component: () => import('./../fadel/report/OrderDemandReportPage'),
                        children: [
                            {
                                path : 'order-demand-monthly',
                                name : 'order-demand-monthly',
                                component: () => import('./../fadel/report/OrderDemandMonthlyReport')
                            },
                            {
                                path : 'order-demand-finished-by-staff-monthly',
                                name : 'order-demand-finished-by-staff-monthly',
                                component: () => import('./../fadel/report/OrderDemandFinishedByStaffMonthlyReport')
                            },
                        ]
                    },
                ]
            },
            {
                path: 'admin',
                name: 'admin',
                component: () => import('./../fadel/admin/AdminPage'),
                children: [
                    {
                        path: 'landing-page',
                        name: 'admin-landing-page',
                        component: () => import('./../fadel/admin/AdminLandingPage')
                    },
                    {
                        path: 'client',
                        name: 'admin-client',
                        component: () => import('./../fadel/admin/AdminClient'),
                        children: [
                            {
                                path: 'detail',
                                name: 'admin-client-detail',
                                component: () => import('./../fadel/admin/AdminClientDetail')
                            },
                            {
                                path: 'setting',
                                name: 'admin-client-setting',
                                component: () => import('./../fadel/admin/AdminClientSetting')
                            }
                        ]
                    }
                ]
            },
            {
                path: 'setting',
                name: 'setting',
                component: () => import('./../fadel/setting/SettingPage'),
                children : [
                    {
                        path: 'user-profile',
                        name: 'profile',
                        component: () => import('./../fadel/user/UserProfile')
                    },
                    {
                        path: 'general-setting',
                        name: 'general-setting',
                        component: () => import('./../fadel/general-setting/GeneralSettingForm')
                    },
                    {
                        path: 'integration-whatsapp',
                        name: 'integration-whatsapp',
                        component : () => import('./../fadel/setting/IntegrationWhatsappPage'),
                        children: [
                            {
                                path: 'setup',
                                name: 'integration-whatsapp-setup',
                                component : () => import('@/fadel/integration-whatsapp/IntegrationWhatsappSetup'),
                            },
                            {
                                path: 'connection',
                                name: 'integration-whatsapp-connection',
                                component : () => import('@/fadel/integration-whatsapp/IntegrationWhatsappConnection'),
                            }
                        ]
                    },
                    {
                        path: 'user',
                        name: 'user',
                        redirect: '/setting/user/list',
                        component: () => import('./../fadel/user/UserPage'),
                        children: [
                            {
                                path: 'list',
                                name: 'users',
                                component: () => import('./../fadel/user/UserIndex')
                            },
                            {
                                path: 'new',
                                name: 'user-new',
                                meta: { isActionRoute : true },
                                component: () => import('./../fadel/user/UserNew'),
                            },
                            {
                                path: 'edit/:id',
                                name: 'user-edit',
                                meta: { isActionRoute : true },
                                component: () => import('./../fadel/user/UserEdit'),
                            }
                        ]
                    },
                    {
                        // @version V0.0.1 [Sunday - 20201115_022435] : init
                        path: 'authorization',
                        name: 'authorization',
                        redirect: '/setting/authorization/page-role',
                        component: () => import('./../fadel/setting/AuthorizationPage'),
                        children: [
                            {
                                path: 'page-role',
                                name: 'page-role',
                                component: () => import('./../fadel/authorization/PageRole')
                            },
                            {
                                path: 'role',
                                name: 'role',
                                component: () => import('./../fadel/authorization/Role')
                            },
                            {
                                path: 'action-role',
                                name: 'action-role',
                                component: () => import('./../fadel/authorization/ActionRole')
                            },
                            {
                                path: 'restrict-order-flow',
                                name: 'restrict-order-flow',
                                component: () => import('./../fadel/authorization/RestrictOrderFlow')
                            },
                            {
                                name: 'filter-user',
                                path: 'filter-user',
                                component: () => import('./../fadel/filter-user/FilterUserPage'),
                                children: [
                                    {
                                        name: 'filter-user-customer-service',
                                        path: 'customer-service',
                                        redirect: { name: 'filter-user-customer-service-list' },
                                        component: () => import('./../fadel/filter-user-customer-service/FilterUserCustomerServicePage'),
                                        children: [
                                            {
                                                name: 'filter-user-customer-service-list',
                                                path: 'list',
                                                component: () => import('./../fadel/filter-user-customer-service/FilterUserCustomerServiceIndex'),
                                            },
                                            {
                                                name: 'filter-user-customer-service-new',
                                                path: 'new',
                                                meta: { isActionRoute : true },
                                                component: () => import('./../fadel/filter-user-customer-service/FilterUserCustomerServiceNew'),
                                            },
                                            {
                                                name: 'filter-user-customer-service-edit',
                                                path: 'edit',
                                                meta: { isActionRoute : true },
                                                component: () => import('./../fadel/filter-user-customer-service/FilterUserCustomerServiceEdit'),
                                            },
                                        ]
                                    },
                                    {
                                        name: 'filter-user-machine',
                                        path: 'machine',
                                        redirect: { name: 'filter-user-machine-list' },
                                        component: () => import('./../fadel/filter-user-machine/FilterUserMachinePage'),
                                        children: [
                                            {
                                                name: 'filter-user-machine-list',
                                                path: 'list',
                                                component: () => import('./../fadel/filter-user-machine/FilterUserMachineIndex'),
                                            },
                                            {
                                                name: 'filter-user-machine-new',
                                                path: 'new',
                                                meta: { isActionRoute : true },
                                                component: () => import('./../fadel/filter-user-machine/FilterUserMachineNew'),
                                            },
                                            {
                                                name: 'filter-user-machine-edit',
                                                path: 'edit',
                                                meta: { isActionRoute : true },
                                                component: () => import('./../fadel/filter-user-machine/FilterUserMachineEdit'),
                                            },
                                        ]
                                    },
                                    {
                                        name: 'filter-user-group-machine',
                                        path: 'group-machine',
                                        redirect: { name: 'filter-user-group-machine-list' },
                                        component: () => import('./../fadel/filter-user-group-machine/FilterUserGroupMachinePage'),
                                        children: [
                                            {
                                                name: 'filter-user-group-machine-list',
                                                path: 'list',
                                                component: () => import('./../fadel/filter-user-group-machine/FilterUserGroupMachineIndex'),
                                            },
                                            {
                                                name: 'filter-user-group-machine-new',
                                                path: 'new',
                                                meta: { isActionRoute : true },
                                                component: () => import('./../fadel/filter-user-group-machine/FilterUserGroupMachineNew'),
                                            },
                                            {
                                                name: 'filter-user-group-machine-edit',
                                                path: 'edit',
                                                meta: { isActionRoute : true },
                                                component: () => import('./../fadel/filter-user-group-machine/FilterUserGroupMachineEdit'),
                                            },
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'limit-order-demand',
                                name: 'limit-order-demand',
                                redirect: { name: 'limit-order-demand-list'},
                                component: () => import('./../fadel/limit-order-demand/LimitOrderDemandPage'),
                                children: [
                                    {
                                        path: 'list',
                                        name: 'limit-order-demand-list',
                                        component: () => import('./../fadel/limit-order-demand/LimitOrderDemandIndex')
                                    },
                                    {
                                        path: 'new',
                                        name: 'limit-order-demand-new',
                                        meta: { isActionRoute: true },
                                        meta: { actionKey : 'can-manage-limit-order-demand' },
                                        component: () => import('./../fadel/limit-order-demand/LimitOrderDemandNew')
                                    },
                                    {
                                        path: 'edit',
                                        name: 'limit-order-demand-edit',
                                        meta: { actionKey : 'can-manage-limit-order-demand' },
                                        component: () => import('./../fadel/limit-order-demand/LimitOrderDemandEdit')
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'machine',
                        name: 'machine',
                        redirect: { name: 'machine-list'},
                        component: () => import('./../fadel/machine/MachinePage'),
                        children: [
                            {
                                path: 'list',
                                name: 'machine-list',
                                component: () => import('./../fadel/machine/MachineIndex')
                            },
                            {
                                path: 'new',
                                name: 'machine-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/machine/MachineNew')
                            },
                            {
                                path: 'edit',
                                name: 'machine-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/machine/MachineEdit')
                            },
                            // group
                            {
                                path: 'group',
                                name: 'group-machine',
                                component: () => import('./../fadel/group-machine/GroupMachinePage.vue'),
                                children: [
                                    {
                                        path: 'new',
                                        name: 'group-machine-new',
                                        meta: { isActionRoute: true },
                                        component: () => import('./../fadel/group-machine/GroupMachineNew')
                                    },
                                    {
                                        path: 'edit',
                                        name: 'group-machine-edit',
                                        meta: { isActionRoute: true },
                                        component: () => import('./../fadel/group-machine/GroupMachineEdit')
                                    },
                                    {
                                        path: 'detail',
                                        name: 'group-machine-detail',
                                        meta: { isActionRoute: true },
                                        component: () => import ('./../fadel/group-machine/GroupMachineDetail')
                                    }
                                ]
                            },
                            // machine target
                            {
                                path: 'target',
                                name: 'target-machine',
                                redirect: { name : 'target-machine-list'},
                                component: () => import('./../fadel/target/TargetPage.vue'),
                                props: { target_key : 'monthly_target', targetable_type : 'App\\Models\\Machine' },
                                children: [
                                    {
                                        path: 'list',
                                        name: 'target-machine-list',
                                        component: () => import('./../fadel/target/TargetIndex')
                                    },
                                    {
                                        path: 'new',
                                        name: 'target-machine-new',
                                        meta: { isActionRoute: true },
                                        props: { editMode : false },
                                        component: () => import('./../fadel/target/TargetForm')
                                    },
                                    {
                                        path: 'edit',
                                        name: 'target-machine-edit',
                                        meta: { isActionRoute: true },
                                        props: { editMode : true },
                                        component: () => import('./../fadel/target/TargetForm')
                                    }
                                ]
                            },
                            // machine group target
                            {
                                path: 'group-target',
                                name: 'target-machine-group',
                                redirect: { name : 'target-machine-group-list'},
                                component: () => import('./../fadel/target/TargetPage.vue'),
                                props: { target_key : 'monthly_target', targetable_type : 'App\\Models\\Group' },
                                children: [
                                    {
                                        path: 'list',
                                        name: 'target-machine-group-list',
                                        component: () => import('./../fadel/target/TargetIndex')
                                    },
                                    {
                                        path: 'new',
                                        name: 'target-machine-group-new',
                                        meta: { isActionRoute: true },
                                        props: { editMode : false },
                                        component: () => import('./../fadel/target/TargetForm')
                                    },
                                    {
                                        path: 'edit',
                                        name: 'target-machine-group-edit',
                                        meta: { isActionRoute: true },
                                        props: { editMode : true },
                                        component: () => import('./../fadel/target/TargetForm')
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'branch',
                        name: 'branch',
                        redirect: { name: 'branch-list'},
                        component: () => import('./../fadel/branch/BranchPage'),
                        children: [
                            {
                                path: 'list',
                                name: 'branch-list',
                                component: () => import('./../fadel/branch/BranchIndex')
                            },
                            {
                                path: 'new',
                                name: 'branch-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/branch/BranchNew')
                            },
                            {
                                path: 'edit',
                                name: 'branch-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/branch/BranchEdit')
                            },
                        ]
                    },
                    {
                        path: 'cash-account',
                        name: 'cash-account',
                        redirect: { name: 'cash-account-list'},
                        component: () => import('./../fadel/cash-account/CashAccountPage'),
                        children: [
                            {
                                path: 'list',
                                name: 'cash-account-list',
                                component: () => import('./../fadel/cash-account/CashAccountIndex')
                            },
                            {
                                path: 'new',
                                name: 'cash-account-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/cash-account/CashAccountNew')
                            },
                            {
                                path: 'edit',
                                name: 'cash-account-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/cash-account/CashAccountEdit')
                            },
                        ]
                    },
                    {
                        path: 'product',
                        name: 'product',
                        redirect: { name : 'product-list'},
                        component: () => import('./../fadel/product/ProductPage'),
                        children: [
                            {
                                path: 'list',
                                name: 'product-list',
                                component: () => import('./../fadel/product/ProductIndex')
                            },
                            {
                                path: 'new',
                                name: 'product-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/product/ProductNew')
                            },
                            {
                                path: 'edit',
                                name: 'product-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/product/ProductEdit')
                            },
                            {
                                path: 'product-category-forms',
                                name: 'product-category-forms',
                                meta: { isActionRoute: true},
                                component: () => import('./../fadel/product-category/ProductCategoryForms')
                            },
                            {
                                path: 'product-logs',
                                name: 'product-logs',
                                // is free route for temp
                                meta: { isFreeRoute: true },
                                props: {
                                    category: 'pengaturan produk',
                                    activityName: '/ Log Produk'
                                },
                                component: () => import('@/fadel/component/RecentActivity')
                            }

                        ]
                    },
                    {
                        path: 'client',
                        name: 'client',
                        redirect: { name : 'client-list'},
                        component: () => import('./../fadel/client/ClientPage'),
                        children: [
                            {
                                path: 'list',
                                name: 'client-list',
                                component: () => import('./../fadel/client/ClientIndex')
                            },
                            {
                                path: 'new',
                                name: 'client-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/client/ClientNew')
                            },
                            {
                                path: 'edit',
                                name: 'client-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/client/ClientEdit')
                            },
                            {
                                path: 'client-logs',
                                name: 'client-logs',
                                // is free route for temp
                                meta: { isFreeRoute: true },
                                props: {
                                    category: 'pelanggan/mitra',
                                    activityName: 'Pelanggan'
                                },
                                component: () => import('@/fadel/component/RecentActivity')
                            }
                        ]
                    },
                    {
                        path: 'customer-service',
                        name: 'customer-service',
                        redirect: { name : 'customer-service-list'},
                        component: () => import('./../fadel/customer-service/CustomerServicePage'),
                        children: [
                            {
                                path: 'list',
                                name: 'customer-service-list',
                                component: () => import('./../fadel/customer-service/CustomerServiceIndex')
                            },
                            {
                                path: 'new',
                                name: 'customer-service-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/customer-service/CustomerServiceForm'),
                                props: { editMode : false }

                            },
                            {
                                path: 'edit',
                                name: 'customer-service-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/customer-service/CustomerServiceForm'),
                                props: { editMode : true }
                            },
                            // group
                            {
                                path: 'group',
                                name: 'group-customer-service',
                                component: () => import('./../fadel/group-customer-service/GroupCustomerServicePage.vue'),
                                children: [
                                    {
                                        path: 'new',
                                        name: 'group-customer-service-new',
                                        meta: { isActionRoute: true },
                                        component: () => import('./../fadel/group-customer-service/GroupCustomerServiceNew')
                                    },
                                    {
                                        path: 'edit',
                                        name: 'group-customer-service-edit',
                                        meta: { isActionRoute: true },
                                        component: () => import('./../fadel/group-customer-service/GroupCustomerServiceEdit')
                                    },
                                    {
                                        path: 'detail',
                                        name: 'group-customer-service-detail',
                                        meta: { isActionRoute: true },
                                        component: () => import ('./../fadel/group-customer-service/GroupCustomerServiceDetail')
                                    }
                                ]
                            },
                            // target
                            {
                                path: 'target',
                                name: 'target-customer-service',
                                redirect: { name : 'target-customer-service-list'},
                                component: () => import('./../fadel/target/TargetPage.vue'),
                                props: { target_key : 'monthly_target', targetable_type : 'App\\Models\\CustomerService' },
                                children: [
                                    {
                                        path: 'list',
                                        name: 'target-customer-service-list',
                                        component: () => import('./../fadel/target/TargetIndex')
                                    },
                                    {
                                        path: 'new',
                                        name: 'target-customer-service-new',
                                        meta: { isActionRoute: true },
                                        props: { editMode : false },
                                        component: () => import('./../fadel/target/TargetForm')
                                    },
                                    {
                                        path: 'edit',
                                        name: 'target-customer-service-edit',
                                        meta: { isActionRoute: true },
                                        props: { editMode : true },
                                        component: () => import('./../fadel/target/TargetForm')
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'computer',
                        name: 'computer',
                        redirect: { name : 'computer-list'},
                        component: () => import('./../fadel/computer/ComputerPage'),
                        children: [
                            {
                                path: 'list',
                                name: 'computer-list',
                                component: () => import('./../fadel/computer/ComputerIndex')
                            },
                            {
                                path: 'new',
                                name: 'computer-new',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/computer/ComputerNew')
                            },
                            {
                                path: 'edit',
                                name: 'computer-edit',
                                meta: { isActionRoute: true },
                                component: () => import('./../fadel/computer/ComputerEdit')
                            },
                        ]
                    },
                    {
                        path: 'receipt-code',
                        name: 'receipt-code-setting',
                        redirect: { name: 'receipt-code-setting-list'},
                        component: () => import('./../fadel/receipt-code/ReceiptCodePage'),
                        children: [
                            {
                                path: 'list',
                                name: 'receipt-code-setting-list',
                                component: () => import('./../fadel/receipt-code/ReceiptCodeIndex')
                            },
                            {
                                path: 'new',
                                name: 'receipt-code-setting-new',
                                meta: { actionKey : 'can-manage-receipt-code' },
                                component: () => import('./../fadel/receipt-code/ReceiptCodeForm'),
                                props: { editMode : false }

                            },
                            {
                                path: 'edit',
                                name: 'receipt-code-setting-edit',
                                meta: { actionKey : 'can-manage-receipt-code' },
                                component: () => import('./../fadel/receipt-code/ReceiptCodeForm'),
                                props: { editMode : true }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    // other-access client
    {
        path: '/oa/client/landing-page',
        name: 'client-landing-page',
        meta: { isFreeRoute: true, isPackageSpecial: true },
        props: route => ({
            clientCode: route.query.cc
        }),
        redirect: { name: 'client-landing-page-home' },
        component: () => import ('./../fadel/other-access/client/LandingPage'),
        children: [
            {
                path: 'home',
                name: 'client-landing-page-home',
                meta: { isFreeRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/LandingHome'),
            },
            {
                path: 'join-us',
                name: 'client-landing-page-join-us',
                meta: { isFreeRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/LandingJoinUs.vue'),
            },
            {
                path: 'login',
                name: 'client-landing-page-login',
                meta: { isFreeRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/LandingLogin.vue'),
            }
        ]
    },
    {
        path: '/oa/client/login',
        alias: '/partner',
        name: 'client-login',
        meta: { isFreeRoute: true, isPackageSpecial: true },
        component: () => import ('./../fadel/other-access/client/LandingLogin.vue'),
    },
    // easy path to register by -> ClientType : client-printing, client-corporate, etc
    {
        path: '/register/:clientType', //clientType
        meta: { isFreeRoute: true, isPackageSpecial: true },
        redirect: to => ({ name: 'client-landing-page-join-us', query: { cc : to.params.clientType } })
    },
    {
        path: '/oa/client/dashboard',
        name: 'client-dashboard',
        meta: { isClientRoute: true, isPackageSpecial: true },
        redirect: { name: 'client-dashboard-order-demand' },
        component: () => import ('./../fadel/other-access/client/dashboard/DashboardClient.vue'),
        children: [
            {
                path: 'order-demand',
                name: 'client-dashboard-order-demand',
                meta: { isClientRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/order-demand/OrderDemandForm.vue'),
            },
            {
                path: 'order-status',
                name: 'client-dashboard-order-status',
                meta: { isClientRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/order-status/OrderStatusIndex.vue'),
            },
            {
                path: 'receivable-periodically-report',
                name: 'client-dashboard-receivable-periodically-report',
                meta: { isClientRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/report/ReceivablePeriodicallyReport.vue'),
            },
            {
                path: 'sale-daily-by-monthly-report',
                name: 'client-dashboard-sale-daily-by-monthly-report',
                meta: { isClientRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/report/SaleDailyByMonthlyReport.vue'),
            },
            {
                path: 'transaction',
                name: 'client-dashboard-transaction',
                meta: { isClientRoute: true, isPackageSpecial: true },
                component: () => import ('./../fadel/other-access/client/transaction/TransactionPage.vue'),
            }
        ]
    }
])
