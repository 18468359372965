import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    RESET_FORM, SET_CLIENT_TO_FORM, SET_FORM
} from "@/fadel/vuex/constant-types"
import {sendRequest} from "@/fadel/vuex/api"

const getDefaultState = () => {
    return {
        form: getDefaultFormState(),
        form_hidden_mode: false
    }
}

const getDefaultFormState = () => ({
    id: '',
    client_id: '',
    client: '', // as reference
    memo_to_cashier: '',
    memo_receipt: '',
    order_status_category_id: 1, // 1 as default, will attach/setup later to OrderStatus event
    order_demand_items: []
})

const getDefaultItemState = () => ({
    id: '',
    product: '', // for reference
    product_id: '',
    calc_of_dimension: '',
    calc_of_dimension_raw: {},
    calc_of_dimension_no_price: '',
    dimension: '',
    n_markup_price: 0,
    n_price: 0,
    qty: 0,
    markup_price: 0,
    price: 0,
    memo_to_operator: '',
    machine_id: '',
    price_by_client_categories: []
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        ADD_ITEM(state) {
            state.form.order_demand_items.push(getDefaultItemState())
        },
        REMOVE_ITEM(state, idx) {
            state.form.order_demand_items.splice(idx, 1)
        },
        SAVE_CALCULATION_ROW_ITEM(state, { calculationResult, itemIndex }) {
            // let item = state.rows[itemIndex]
            // item.calc_of_dimension = calculationResult.calc_of_dimension
            Object.assign(state.form.order_demand_items[itemIndex], calculationResult)
        },
        SET_ITEMS(state, items) {
            state.form.order_demand_items = _.map(items, i => {
                return _.pick(i, ['id', 'product', 'product_id', 'calc_of_dimension', 'calc_of_dimension_no_price', 'n_markup_price', 'n_price', 'qty', 'markup_price', 'price', 'memo_to_operator', 'machine_id'])
            })
        },
        [RESET_FORM](state) {
            Object.assign(state.form, getDefaultFormState())
        },
        [SET_CLIENT_TO_FORM](state, client) {
            state.form.client_id = client.id
            state.form.client = client
        },
        [SET_FORM](state, orderDemand) {
            Object.assign(state.form, _.pick(orderDemand, ['id', 'client_id', 'client', 'memo_to_cashier', 'memo_receipt']))
        },
        updateField

    },
    // can async
    actions: {
        requestCreateOrderDemand({commit, state, dispatch}) {
            commit('requestStatus/REQUEST_SENDING', null, { root : true})
            // change form to json send, duo to size, change to sendRequest
            return sendRequest('oa-client/order-demand', state.form, 'post', 
                (createdOrderDemand) => {
                    commit('requestStatus/REQUEST_SUCCESS', null, { root : true})
                    // reset form
                    commit('RESET_FORM')
                    // set client
                    dispatch('setAuthClientToForm')
                    // reload list
                    dispatch('oaClient/order/loadList', null, { root: true })
                },
                (failedResponse) => {
                    commit('requestStatus/REQUEST_FAILED', null, { root : true})
                },
                () => {
                    commit('requestStatus/REQUEST_SENDED', null, { root : true})
                }, false, true, false
            )
        },
        setAuthClientToForm({commit, rootState}) {
            // only set when client exist / logged
            if (!rootState.oaClient.authClient.client) return

            commit('SET_CLIENT_TO_FORM', {
                id: rootState.oaClient.authClient.client.id,
                client_category_id: rootState.oaClient.authClient.client.client_category_id
            })
        },
        resetForm({commit, dispatch}) {
            commit("RESET_FORM")
            dispatch('setAuthClientToForm')
        }
    },
    // call variable with custom display
    getters: {
        getField,
        getProductPriceByClientCategory: (state, getters, rootState, rootGetters) => (itemIndex) => {
            let client = state.form.client
            let product = state.form.order_demand_items[itemIndex].product
            // return if client or product is not set yet
            if ( !client || !product ) {
                return ;
            } else {
                let clientCategoryId = client.client_category_id
                let productPrice = product.product_prices.find(p => +p.client_category_id === +clientCategoryId)
                return productPrice ? +productPrice.sell_price : 0;
            }
        }
    }
})
