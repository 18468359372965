import { getSearch, requestSubmit, sendRequest } from "../api"
import {
    RESET_STATE, SET_DETAIL, SET_LIST
} from "../constant-types"
import cashierCommonCostForm from "./form/cashierCommonCostForm"
import cashierForm from "./form/cashierForm"
import cashierItemForm from "./form/cashierItemForm"
import paymentForm from "./form/paymentForm"
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        list: null,
        search_params: getDefaultSearchParamsState(),
        detail: null
    }
}

const getDefaultSearchParamsState = () => {
    return {
        filter_search_text : '',
        loading : false,
        filter_sort : [{
            column : 'created_at',
            sort : 'desc',
            icon : ['fas', 'angle-down']
        }],
        filter_page : 1,
        filter_limit : 40
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_LIST](state, fetchedList) {
            state.list = fetchedList
        },
        [SET_DETAIL](state, cashier) {
            state.detail = cashier
        },
        updateField

    },
    // can async
    actions: {
        loadDetail({commit}, cashierId) {
            app.$Progress.start()
            return sendRequest(`cashier/${cashierId}`, {}, 'get', (fetchedCashier) => { commit(SET_DETAIL, fetchedCashier) ; app.$Progress.finish()})
        },
        loadList({commit, state, getters, rootGetters}, payload = { resetPage : false}) {
            if (payload.resetPage) {
                commit('updateField', { path : 'search_params.filter_page', value : 1})
            }
            // gate : check if user can see last transaction
            let filterMusts = []
            if (!rootGetters['authUser/checkAbility']('can-see-old-cashier')) {
                let today = (new Date()).toISOString().split('T')[0]
                filterMusts.push([today, '=', 'modeDate', 'created_at'])
            }

            return getSearch(
                {words: _.split(state.search_params.filter_search_text, ' '), fields : ['code','orderDemand.client>name']},
                filterMusts,
                [],
                state.search_params.filter_sort,
                state.search_params.filter_page,
                state.search_params.filter_limit,
                ['orderDemand.orderDemandItems.orderStatus.orderStatusCategory', 'orderDemand.client'],
                [],
                'cashier',
                (response) => commit(SET_LIST, response),
                ()=>{},
                ()=>{}
            )
        },
        requestCreateCashier({commit, state, rootState}) {
            let createdForm = {
                ...state.cashierForm.rows[0],
                client_macs : rootState.client_macs,
                cashier_items : state.cashierItemForm.rows,
                cashier_common_costs : state.cashierCommonCostForm.rows,
                payments : state.paymentForm.rows
            };

            commit('requestStatus/REQUEST_SENDING', null, { root : true})
            // change form to json send, duo to size, change to sendRequest
            return sendRequest('cashier', createdForm, 'post',
                (createdCashier) => {
                    commit('requestStatus/REQUEST_SUCCESS', null, { root : true})
                    commit(SET_DETAIL, createdCashier)
                },
                (failedResponse) => {
                    commit('requestStatus/REQUEST_FAILED', null, { root : true})
                },
                () => {
                    commit('requestStatus/REQUEST_SENDED', null, { root : true})
                }, false, true, false
            )
        },
        requestChangeBranch({commit, rootState }, { cashierId, branchId }) {
            commit('requestStatus/REQUEST_SENDING', null, { root : true})
            // change form to json send, duo to size, change to sendRequest
            return sendRequest('cashier/' + cashierId + '/change-branch', { branch_id : branchId }, 'put',
                () => {
                    commit('requestStatus/REQUEST_SUCCESS', null, { root : true})
                    // branch name
                    let branch = rootState.branch.options.find(i => i.id === branchId)
                    if ( branch ) {
                        commit('updateField', { path: 'detail.branch.name', value: branch.name})
                    }
                },
                () => {
                    commit('requestStatus/REQUEST_FAILED', null, { root : true})
                },
                () => {
                    commit('requestStatus/REQUEST_SENDED', null, { root : true})
                }, false, true, false
            )
        },
        requestSavePayments({state, rootState, commit, dispatch}) {
            let createdForm = {
                payments: state.paymentForm.rows,
                client_macs : rootState.client_macs
            }
            return requestSubmit(`cashier/${state.cashierForm.rows[0].id}/payments`, createdForm, commit, 'put', (updatedCashier) => {
                commit(SET_DETAIL, updatedCashier)
                // reload list
                dispatch('loadList')
            })

        },
        resetForm({commit}) {
            commit('cashier/cashierForm/RESET_FORM', null, { root:true} )
            commit('cashier/cashierItemForm/RESET_FORM', null, { root:true})
            commit('cashier/cashierCommonCostForm/RESET_FORM', null, { root:true})
            commit('cashier/paymentForm/RESET_FORM', null, { root:true })
        }
    },
    // call variable with custom display
    getters: {
        detailSumCommonCosts: state => state.detail.cashier_common_costs.reduce((acc, cur) => acc + cur.nominal, 0),
        detailSumItems : state => state.detail.cashier_items.reduce((acc, cur) => acc + cur.price_after_disc, 0),
        detailSumPaymentPaid : state => state.detail.payments.reduce((acc, cur) => {
            if (cur.paid_date !== null) {
                return acc + cur.nominal
            } else {
                return acc
            }
         }, 0),
        detailSumPaymentUnpaid: (state, getters) => state.detail.payments.reduce((acc, cur) => acc + cur.nominal, 0) - getters.detailSumPaymentPaid,
        getField
    },
    // modules
    modules: {
        cashierForm, cashierItemForm, cashierCommonCostForm, paymentForm
    }
})
