import {
    getField,
    updateField
} from "vuex-map-fields"
import {requestSubmit} from "../../api"
import {
    ADD_ROW, RESET_FORM, SET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: [getDefaultRowState()],
        setting_form: {
            id: '',
            username: '',
            password: '',
            password_confirmation: '',
            client_code: '',
            status_active: false
        },
        setting_form_setted: false
    }
}

const getDefaultRowState = () => ({
    id: '',
    customer_service_id: '',
    client_category_id: '',
    name: '',
    full_name: '',
    phone: '',
    im: '',
    address: ''
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.push(getDefaultRowState())
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, client) {
            state.rows[0] = _.pick(client, ['id', 'customer_service_id', 'client_category_id', 'name', 'full_name', 'phone', 'im', 'address'])
        },
        SET_SETTING_FORM(state, payload) {
            state.setting_form = JSON.parse(JSON.stringify(payload))
            state.setting_form_setted = true
        },
        updateField
    },
    // can async
    actions: {
        requestUpdateSetting({commit, state}) {
            return requestSubmit(`client-setting/${state.setting_form.id}`, state.setting_form, commit, 'post', () => {
            });
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
