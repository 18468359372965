import { requestDelete, requestSubmit, sendRequest } from "../api"
import {
    getField,
    updateField
} from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        options: [],
        option_loading: false,
        form: getDefaultFormState()
    }
}
const getDefaultFormState = () => {
    return {
        id: '',
        name: '',
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_OPTION(state, payload) {
            state.options = payload
        },
        ADD_TO_OPTION(state, option) {
            state.options.unshift(option)
        },
        UPDATE_TO_OPTION(state, updatedOption) {
            let idx = state.options.findIndex(o => o.id == updatedOption.id)
            state.options[idx] = updatedOption
        },
        REMOVE_FROM_OPTION(state, machine) {
            // _.remove(state.options, { id : machine.id})
            let idx = state.options.findIndex(o => o.id === machine.id)
            state.options.splice(idx, 1);
        },
        RESET_FORM(state) {
            Object.assign(state.form, getDefaultFormState())
        },
        SET_FORM(state, machine) {
            Object.assign(state.form, machine)
        },
        updateField
    },
    // can async
    actions: {
        loadOption({
            commit
        }) {
            commit('updateField', { path: 'option_loading', value: true })
            return sendRequest('branch/option', {}, 'get', (responseOptions) => commit('SET_OPTION', responseOptions), () => {}, () => { commit('updateField', { path: 'option_loading', value: false }) })
        },
        requestCreateBranch({
            commit,
            state
        }) {
            return requestSubmit('branch', state.form, commit, 'post', (createdBranch) => commit('ADD_TO_OPTION', createdBranch))
        },
        requestUpdateBranch({
            commit,
            state
        }) {
            return requestSubmit(`branch/${state.form.id}`, state.form, commit, 'put', (updatedBranch) => commit('UPDATE_TO_OPTION', updatedBranch))
        },
        requestDeleteBranch({
            commit
        }, branch) {
            return requestDelete(`branch/${branch.id}`, {}, commit, branch.name, 'REMOVE_FROM_OPTION')
        }
    },
    // call variable with custom display
    getters: {
        getField
    },
    // modules
    modules: {

    }
})