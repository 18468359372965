import { sendRequest } from "../api"
import {
    RESET_STATE, SET_OPTION
} from "../constant-types"

const getDefaultState = () => {
    return {
        options : []
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_OPTION](state, options) {
            state.options = options
        }

    },
    // can async
    actions: {
        loadOptions({commit}) {
            sendRequest('client-category/option', {}, 'get', (responseOptions) => commit(SET_OPTION, responseOptions))
        }
    },
    // call variable with custom display
    getters: {
        filteredOptions: state => ({asParent, parentId}) => {
            if (asParent) return state.options
            // if is not parent -> is nested
            let includesId = []
            switch (parentId) {
                case 1: // 1. eksternal/user
                    includesId = [2,3];
                    break;
                case 2: // 2. dealer/percetakan
                    includesId = [3];
                    break;
                default:
                    // 3. internal/mitra ( as the lowest )
                    includesId = [];
                    break;
            }

            return state.options.filter(o => includesId.some(i => i === o.id))
        }

    },
    // modules
    modules: {

    }
})
