// @version V0.0.1 [Saturday - 20201017_201751] : init
/**
 * Instruction : just add require('./LoadAddon/loadFontAwesome') on app.js
 */

// Vue Font Awesome 5
import {
    library
} from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare, faFlag, faImage } from "@fortawesome/free-regular-svg-icons";
import { faShoePrints } from "@fortawesome/free-solid-svg-icons";

// Solid
import {
    faEye,
    faAngleDown,
    faAngleUp,
    faBook,
    faCode,
    faCogs,
    faEdit,
    faFan,
    faGripVertical,
    faPencilRuler,
    faPrint,
    faSearch,
    faSignInAlt,
    faSort,
    faTachometerAlt,
    faTrashAlt,
    faUser,
    faUserPlus,
    faUsers,
    faSignOutAlt,
    faShieldAlt,
    faEyeSlash,
    faMoneyBill,
    faList,
    faRetweet,
    faDotCircle,
    faCheckDouble,
    faFileInvoice,
    faCashRegister,
    faNewspaper,
    faUserFriends,
    faVolumeUp, faVolumeMute, faServer, faAngleLeft, faAngleRight, faHome, faUserClock, faPlay, faStop, faDownload, faUpload, faImages, faPaperclip, faBuilding, faFileExcel, faTimes, faEnvelope, faGlobe, faPhoneAlt, faSave, faMoneyCheck, faSortNumericDown
} from "@fortawesome/free-solid-svg-icons";

// Brand
// import {  } from "@fortawesome/free-brands-svg-icons";

// Regular
// import {  } from "@fortawesome/free-regular-svg-icons";

library.add(
    faFan,
    faCode,
    faCogs,
    faBook,
    faPencilRuler,
    faPrint,
    faTachometerAlt,
    faGripVertical,
    faWhatsapp,
    faUser,
    faUsers,
    faEdit,
    faTrashAlt,
    faImage,
    faSearch,
    faSort,
    faAngleUp,
    faAngleDown,
    faEye,
    faSignInAlt,
    faUserPlus,
    faSignOutAlt,
    faShieldAlt,
    faEyeSlash,
    faMoneyBill, faList, faRetweet, faDotCircle, faCheckDouble, faFileInvoice, faCashRegister,
    faNewspaper, faUserFriends,
    faVolumeUp, faVolumeMute,
    faServer,
    faAngleLeft, faAngleRight, faHome, faUserClock,
    faPlay, faStop, faDownload, faUpload, faImages, faPaperclip, faBuilding,
    faFileExcel, faTimes, faEnvelope, faFacebook, faInstagram, faGlobe, faPhoneAlt, faShieldAlt, faSave,
    faFlag, faMoneyCheck, faCheckSquare, faSortNumericDown, faShoePrints, faComment
)


// Initialize component
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

import Vue from "vue"

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
