// @version V0.0.1 [Tuesday - 20201103_092924] : init

const getDefaultState = () => {
    return {
        sending: false,
        success: false,
    }
}

import {
    RESET_STATE,
    REQUEST_SENDING,
    REQUEST_SENDED,
    REQUEST_SUCCESS,
    REQUEST_FAILED
} from "../../constant-types";

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [REQUEST_SENDING](state) {
            state.sending = true;
            state.success = false;
        },
        [REQUEST_SUCCESS](state) {
            state.success = true;
        },
        [REQUEST_SENDED](state) {
            state.sending = false;
        },
        [REQUEST_FAILED](state) {
            state.sending = false;
        }
    },
    // can async
    actions: {
        
        
    },
    // call variable with custom display
    getters: {
        
    }
})
