const getDefaultFormEntryCash = () => {
    let today = (new Date()).toISOString();
    return {
        entry_date_time: today,
        description: '',
        cash_category_id: '',
        cash_type: '',
        account_destination_type: '',
        cash_amount: '',
        cash_balance: 0,
        input_by_id: '',
        revision_from_cash_id: ''
    }
}

const getDefaultEntriedCashierIncome = () => {
    return {
        filter_date: '',
        entried_total: 0,
        entried_not_yet: 0,
        entried_done: 0,
        loading: false,
        cash_item_id : null
    }
}
const getDefaultEntriedCashierExpense = () => {
    return {
        filter_date: '',
        entried_total: 0,
        entried_not_yet: 0,
        entried_done: 0,
        loading: false,
        cash_item_id : null
    }
}

const getDefaultState = () => {

    return {
        current_branch_id: null,
        form_entry_cash: getDefaultFormEntryCash(),
        entried_cashier_income: getDefaultEntriedCashierIncome(),
        entried_cashier_expense: getDefaultEntriedCashierExpense(),
        list_cashier_income : [],
        list_cashier_income_loading : false,
        list_cashier_expense : [],
        list_cashier_expense_loading : false,
        last_cash_balance: 0,
        option_cash_categories: [],
        cash_list: [],
        cash_list_loading : false
    }
}

import {
    getField,
    updateField
} from "vuex-map-fields";

import {
    sendRequest
} from "./../api";

import {
    REQUEST_SENDED,
    REQUEST_SENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILED,
    RESET_STATE
} from "./../constant-types";

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        RESET_ENTRY_CASH_FORM(state) {
            state.form_entry_cash = getDefaultFormEntryCash()
        },
        RESET_ENTRIED_CASHIER_INCOME(state) {
            state.entried_cashier_income = getDefaultEntriedCashierIncome()
        },
        RESET_ENTRIED_CASHIER_EXPENSE(state) {
            state.entried_cashier_expense = getDefaultEntriedCashierExpense()
        },
        UPDATE_FORM_ENTRY_CASH_FIELD(state, field) {
            updateField(state.form_entry_cash, field)
        },
        UPDATE_ENTRIED_CASHIER_INCOME_FIELD(state, field) {
            updateField(state.entried_cashier_income, field)
        },
        UPDATE_ENTRIED_CASHIER_EXPENSE_FIELD(state, field) {
            updateField(state.entried_cashier_expense, field)
        },
        SET_OPTION_CASH_CATEGORIES(state, payload) {
            state.option_cash_categories = payload
        },
        SET_CASH_LIST(state, payload) {
            state.cash_list = payload
        },
        SET_FORM_ENTRY_CASH(state, payload) {
            state.form_entry_cash = payload
        },
        SET_ENTRIED_CASHIER_INCOME(state, payload) {
            state.entried_cashier_income = payload
        },
        SET_ENTRIED_CASHIER_EXPENSE(state, payload) {
            state.entried_cashier_expense = payload
        },
        SET_LIST_CASHIER_INCOME(state, payload) {
            state.list_cashier_income = payload
        },
        SET_LIST_CASHIER_EXPENSE(state, payload) {
            state.list_cashier_expense = payload
        },
        SET_LAST_CASH_BALANCE(state, payload) {
            state.last_cash_balance = payload
        },
        SET_LOADING(state, { stateName, status = false }) {
            state[stateName] = status
        },
        updateField
    },
    // can async
    actions: {
        loadOptionCashCategories({
            commit
        }) {
            return sendRequest('cash-category/option', {}, 'get', (successResponse) => commit('SET_OPTION_CASH_CATEGORIES', successResponse), () => {}, () => {})
        },
        loadLastCashBalance({
            commit, state
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            return sendRequest('cash/last-balance/' + state.current_branch_id, {}, 'get', (successResponse) => commit('SET_LAST_CASH_BALANCE', successResponse), () => {}, () => {})
        },
        loadCashList({
            commit, state
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('SET_LOADING', { stateName : 'cash_list_loading', status : true })
            return sendRequest('cash', { filter_branch_id : state.current_branch_id }, 'get', (successResponse) => commit('SET_CASH_LIST', successResponse), () => {}, () => { commit('SET_LOADING', { stateName : 'cash_list_loading', status : false }) })
        },
        loadEntriedCashierIncome({
            commit,
            state
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('UPDATE_ENTRIED_CASHIER_INCOME_FIELD', {
                path: 'loading',
                value: true
            });
            sendRequest('cash/entried-cashier-income', {
                    filter_date: state.entried_cashier_income.filter_date,
                    filter_branch_id: state.current_branch_id
                }, 'get',
                (successResponse) => commit('SET_ENTRIED_CASHIER_INCOME', successResponse),
                (errorResponse) => {},
                () => {
                    commit('UPDATE_ENTRIED_CASHIER_INCOME_FIELD', {
                        path: 'loading',
                        value: false
                    });
                })
        },
        loadEntriedCashierExpense({
            commit,
            state
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('UPDATE_ENTRIED_CASHIER_EXPENSE_FIELD', {
                path: 'loading',
                value: true
            });
            sendRequest('cash/entried-cashier-expense', {
                    filter_date: state.entried_cashier_expense.filter_date,
                    filter_branch_id: state.current_branch_id
                }, 'get',
                (successResponse) => commit('SET_ENTRIED_CASHIER_EXPENSE', successResponse),
                (errorResponse) => {},
                () => {
                    commit('UPDATE_ENTRIED_CASHIER_EXPENSE_FIELD', {
                        path: 'loading',
                        value: false
                    });
                })
        },
        loadListCashierIncome({commit, state}, filterDate) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('SET_LOADING', { stateName : 'list_cashier_income_loading', status: true  })
            return sendRequest('cash/cashier-income', { filter_date : filterDate, filter_branch_id : state.current_branch_id }, 'get', (successResponse) => commit('SET_LIST_CASHIER_INCOME', successResponse), () => {}, () => commit('SET_LOADING', { stateName : 'list_cashier_income_loading', status: false  }))
        },
        loadListCashierExpense({commit, state}, filterDate) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('SET_LOADING', { stateName : 'list_cashier_expense_loading', status: true  })
            return sendRequest('cash/cashier-expense', { filter_date : filterDate, filter_branch_id : state.current_branch_id }, 'get', (successResponse) => commit('SET_LIST_CASHIER_EXPENSE', successResponse), () => {}, () => commit('SET_LOADING', { stateName : 'list_cashier_expense_loading', status: false  }))
        },
        requestSaveManualEntry({
            commit,
            dispatch,
            state
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('requestStatus/' + [REQUEST_SENDING], null, {
                root: true
            })

            return sendRequest('cash/manual-entry', { branch_id : state.current_branch_id, ...state.form_entry_cash }, 'post',
                (successResponse) => {
                    commit('requestStatus/' + [REQUEST_SUCCESS], null, {
                        root: true
                    })
                    commit('RESET_ENTRY_CASH_FORM')
                    dispatch('loadCashList')
                },
                (failedResponse) => {
                    commit('requestStatus/' + [REQUEST_FAILED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/' + [REQUEST_SENDED], null, {
                        root: true
                    })
                }, true, true
            )
        },
        requestSaveEntriedCashierIncome({
            state,
            commit,
            dispatch,
            getters
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('requestStatus/' + [REQUEST_SENDING], null, {
                root: true
            })

            return sendRequest('cash/cashier-income', { branch_id : state.current_branch_id, ...state.entried_cashier_income }, 'post',
                (successResponse) => {
                    commit('requestStatus/' + [REQUEST_SUCCESS], null, {
                        root: true
                    })
                    commit('RESET_ENTRIED_CASHIER_INCOME')
                    dispatch('loadCashList')
                },
                (failedResponse) => {
                    commit('requestStatus/' + [REQUEST_FAILED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/' + [REQUEST_SENDED], null, {
                        root: true
                    })
                }, true, true
            )
        },
        requestSaveEntriedCashierExpense({
            state,
            commit,
            dispatch
        }) {
            if (!state.current_branch_id) return // cancel when not selected

            commit('requestStatus/' + [REQUEST_SENDING], null, {
                root: true
            })

            return sendRequest('cash/cashier-expense', { branch_id : state.current_branch_id, ...state.entried_cashier_expense }, 'post',
                (successResponse) => {
                    commit('requestStatus/' + [REQUEST_SUCCESS], null, {
                        root: true
                    })
                    commit('RESET_ENTRIED_CASHIER_EXPENSE')
                    dispatch('loadCashList')
                },
                (failedResponse) => {
                    commit('requestStatus/' + [REQUEST_FAILED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/' + [REQUEST_SENDED], null, {
                        root: true
                    })
                }, true, true
            )
        }
    },
    // call variable with custom display
    getters: {
        getField,
        getFormEntryCashField(state, field) {
            return getField(state.form_entry_cash, field)
        },
        getEntriedCashierIncomeField(state, field) {
            return getField(state.entried_cashier_income, field)
        },
        getEntriedCashierExpenseField(state, field) {
            return getField(state.entried_cashier_expense, field)
        },
        getNextCashBalance(state) {
            // substract and minus base on cash type
            if (state.form_entry_cash.cash_type == 'masuk') {
                return state.last_cash_balance + +state.form_entry_cash.cash_amount;
            } else if (state.form_entry_cash.cash_type == 'keluar') {
                return state.last_cash_balance - +state.form_entry_cash.cash_amount;
            } else {
                return 0;
            }
        },
        getCategoryName: (state) => (categoryId) => {
            return (state.option_cash_categories.find(category => category.id == categoryId)).category
        },
        enableButtonIncomeEntry: (state) => {
            return (state.entried_cashier_income.entried_total > 0 && state.entried_cashier_income.entried_done == 0)
        },
        enableButtonIncomeRevision: (state) => {
            return (state.entried_cashier_income.entried_not_yet != 0 && state.entried_cashier_income.entried_done > 0)
        },
        enableButtonIncomeShowDetail: (state) => {
            return state.entried_cashier_income.entried_total > 0;
        },
        enableButtonExpenseEntry: (state) => {
            return (state.entried_cashier_expense.entried_total > 0 && state.entried_cashier_expense.entried_done == 0)
        },
        enableButtonExpenseRevision: (state) => {
            return (state.entried_cashier_expense.entried_not_yet != 0 && state.entried_cashier_expense.entried_done > 0)
        },
        enableButtonExpenseShowDetail: (state) => {
            return state.entried_cashier_expense.entried_total > 0;
        }
    }
})
