import { getField, updateField } from "vuex-map-fields";
import { sendRequest } from "../api";
import targetForm from "./form/targetForm";

const getMonthAndYear = () => {
    const now = new Date();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    return { month, year };
};

// field reference from App\Models\Target
const getDefaultState = () => {
    const { month, year } = getMonthAndYear();

    return {
        list_monthly: [],
        list_loading_monthly: false,
        // filter
        filter_target_type: "App\\Models\\CustomerService", // ex: App\Models\CustomerService | App\Models\Machine | App\Models\Group
        filter_month: month,
        filter_year: year,
        filter_day: 1,
        // detail
        detail: null,
        loading: false, // Add this line to initialize the loading state
    };
};

export default {
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        // reset state
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        // set monthly
        SET_LIST_MONTHLY(state, payload) {
            state.list_monthly = payload;
        },
        SET_LIST_LOADING_MONTHLY(state, payload) {
            state.list_loading_monthly = payload;
        },
        ADD_TO_LIST_MONTHLY(state, payload) {
            state.list_monthly.unshift(payload);
        },
        UPDATE_TO_LIST_MONTHLY(state, payload) {
            const index = state.list_monthly.findIndex(target => target.id === payload.id);
            state.list_monthly.splice(index, 1, payload);
        },
        REMOVE_FROM_LIST_MONTHLY(state, targetId) {
            const index = state.list_monthly.findIndex(target => target.id === targetId);
            state.list_monthly.splice(index, 1);
        },
        SET_DETAIL(state, payload) {
            state.detail = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_FILTER_TARGET_TYPE(state, payload) {
            if (payload === "App\\Models\\CustomerService" || payload === "App\\Models\\Machine" || payload === "App\\Models\\Group") {
                state.filter_target_type = payload;
            } else {
                console.error("Invalid target type. Must be 'App\\Models\\CustomerService' or 'App\\Models\\Machine'.");
            }
        },
        updateField,
    },
    getters: {
        getField,
        sortedListMonthlyByName: state => {
            return [...state.list_monthly].sort((a, b) => {
                if (a.targetable.name < b.targetable.name) return -1;
                if (a.targetable.name > b.targetable.name) return 1;
                return 0;
            });
        },
    },
    actions: {
        // get list monthly
        getListMonthly: async ({ commit, state }) => {
            commit("SET_LIST_LOADING_MONTHLY", true);
            const params = {
                filter_target_type: state.filter_target_type,
                filter_month: state.filter_month,
                filter_year: state.filter_year,
            };
            return await sendRequest(
                "target/monthly",
                params,
                "get",
                (fetchedList) => commit('SET_LIST_MONTHLY', fetchedList),
                (e) => e,
                () => commit("SET_LIST_LOADING_MONTHLY", false)
            );
        },
        getDetail: async ({ commit }, targetId) => {
            return await sendRequest(
                `target/${targetId}`,
                {},
                "get",
                (fetchedTarget) => commit(SET_DETAIL, fetchedTarget),
                (e) => e,
                () => ({})
            );
        },
        updateAchievement: async ({ commit, state }, { targetableId, filterMonth, filterYear }) => {
            if (state.loading) {
                console.log("Update is already in progress. Cancelling this request.");
                return false; // Cancel the current action if loading is true
            }
            try {
                commit('SET_LOADING', true); // Set loading state to true at the start of the action
                const params = {
                    targetable_id: targetableId,
                    targetable_type: state.filter_target_type,
                    filter_month: filterMonth,
                    filter_year: filterYear,
                };
                const result = await sendRequest(
                    "target/update-achievement",
                    params,
                    "post",
                    (updatedData) => {
                        if (!updatedData) {
                            console.error("Failed to update data");
                            return false;
                        }
                        commit('UPDATE_TO_LIST_MONTHLY', updatedData);
                        return true;
                    },
                    (e) => e,
                    () => commit('SET_LOADING', false) // Set loading state to false once the request is completed
                );
                return result;
            } catch (error) {
                console.error("Failed to update achievement:", error);
                commit('SET_LOADING', false); // Ensure loading state is set to false even if an error occurs
                throw error;
            }
        },
    },
    modules: {
        targetForm,
    },
};
