<template>
    <label class="inline-flex items-center text-gold-700">
        <input class="w-6 h-6 cursor-pointer" :checked="value" :value="value" @input="e => $emit('input', e.target.checked)" type="checkbox" />
        <span class="ml-2" v-if="showLabel">
            {{ value ? labelChecked : labelUnchecked }}
        </span>
  </label>
</template>
<script>
export default {
    name: "CheckboxInput",
    props: {
        showLabel: {
            type: Boolean,
            default: true
        },
        labelChecked: {
            type: String,
            default: 'Iya'
        },
        labelUnchecked: {
            type: String,
            default: 'Tidak'
        },
        value: {
            type: Boolean,
            default: false
        },
    }
}
</script>

