// @version V0.0.1 [Wednesday - 20201028_093540] : init

import Vue from 'vue';

// Vue Router
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import routes from "./routes";

export const router = new VueRouter({
    routes,
    // V1.1.2 [Wednesday - 20190501_150500] : router linkActiveClass to bootstrap
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active"
}); //dispatch to app.js
