import { sendRequest } from "../api"
import {
    RESET_STATE, SET_OPTION
} from "../constant-types"

const getDefaultState = () => {
    return {
        options : []
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_OPTION](state, options) {
            state.options = options
        }
    },
    actions: {
        loadOptions({commit}) {
            sendRequest('category/option', {}, 'get', (responseOptions) => commit(SET_OPTION, responseOptions))
        }
    },
    getters: {
        getCategoryOptions : state => group => {
            if ( state.options.length === 0 ) return []
            return state.options.filter(f => f.group === group)
        }
    }
})
