<template>
    <div class="flex items-center mb-2 overflow-hidden bg-green-300 border border-green-400 rounded-md">
        <span class="px-2 text-green-600">
            <font-awesome-icon icon='search'></font-awesome-icon>
        </span>
        <input type="text" v-debounce="500" v-model.lazy="filterSearchText" @change="updateParent"
            class="flex-grow px-2 py-1 text-gray-600 form-control focus:outline-none" placeholder="cari...">
    </div>
</template>

<script>
// use debounce for relay texting
import debounce from "v-debounce";

    export default {
        name: "FilterSearchText",
        data() {
            return {
                filterSearchText : ''
            };
        },
        directives: {
            debounce
        },
        mounted() {
            this.filterSearchText = this.value;
        },
        methods: {
            updateParent() {
                this.$emit('input', this.filterSearchText)
            }
        },
        props: {
            value: String
        }
    };

</script>

<style lang="css" scoped>
</style>
