// @modified : group to preload, async for client macs
// @version V0.0.2 [Tuesday - 20201103_092526] : added precall vuex for some predata, and loadParentMethodMixin
// @version V0.0.1 [Wednesday - 20201028_093838] : init

require('./bootstrap');
require('./LoadAddon/loadFontAwesome');
require('./LoadAddon/loadOther')

import Vue from 'vue';
import { router } from "./LoadAddon/loadVueRouter";
import { store } from "./LoadAddon/loadVueX";

// router hook, should call before Vue instance
require('./LoadAddon/routerHook')

/**
 * precall vuex, need some predata
 */
// get page list
const preload = async() => {
    store.dispatch('page/getPage');
    await store.dispatch('generalSetting/loadKeyList');
    await store.dispatch('advancedSetting/loadList');
    // only get client mac for this feature for a while
    if (store.state.generalSetting.key_list.transaction_cashier_computer_lock === "1" ) { store.dispatch('getClientMacs') }
}
preload()

import loadParentMethodMixin from './../../code_libraries/vue.git/Examples/Mixin/parentMethodMixin'

const app = new Vue({
    el: '#app',
    router,
    store,
    mixins: [ loadParentMethodMixin ]
}).$mount('#app')

window.app = app;


