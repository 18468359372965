<template>
    <div class="inline-block">
        <router-link :to="routerLinkTo" :tag="routerLinkTag" class="cancel-class" v-if="routerLinkTo" :disabled="lock" :class="{ 'cursor-not-allowed' : lock }">
            <slot/>
        </router-link>
        <button class="cancel-class" v-else :disabled="lock" :class="{ 'cursor-not-allowed' : lock }">
            <slot/>
        </button>
    </div>
</template>

<script>
    export default {
        name: "CancelButton",
        data() {
            return {};
        },
        props: {
            // isActive : {
            //     type : Boolean,
            //     default :false
            // },
            // indicate as router-link
            routerLinkTo : {
                type : String,
                default : ''
            },
            routerLinkTag : {
                type : String,
                default : 'a'
            },
            lock : {
                type: Boolean,
                default: false
            }
        }
    };
</script>

<style lang="css" scoped>
    .cancel-class {
        @apply px-2 py-1 text-xl text-gray-200 bg-orange-700 rounded-tr-xl rounded-bl-xl 
    }
    .cancel-class:focus {
        @apply outline-none
    }
    .cancel-class:hover {
        @apply bg-orange-900
    }

</style>