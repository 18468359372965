<template>
    <div class="bg-green-200 rounded p-2 shadow-2xl border-gray-500 border">
        <div class="uppercase font-semibold leading-tight text-xl border-b-2 border-green-200">
            <font-awesome-icon icon='code'></font-awesome-icon>
            Info Update Aplikasi
        </div>
        <dl class="mt-2" v-for="(vi, keyVi) in versionInfo" :key="'vi'+keyVi">
            <dt class="text-sm">Versi {{ vi.version }} - Release {{ vi.release }}</dt>
            <dd class="text-xs text-gray-700 font-mono">
                <div v-for="(memo, key) in vi.memos" :key="'memo'+key">
                    # {{ memo }}
                </div>
            </dd>
        </dl>
        <hr>
        <small class="bg-black text-white rounded px-1 text-xs">tips : untuk merefresh aplikasi, tekan <kbd>ctrl+shift+del</kbd> lalu clear data</small>
    </div>
</template>

<script>
    export default {
        name: "VersionInfo",
        data() {
            return {};
        },
        props: {
            versionInfo: {
                type: Array,
                default: () => {
                    return []
                }
            }
        }
    };

</script>

<style lang="css" scoped>
</style>
