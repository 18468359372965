import { sendRequest } from "@/fadel/vuex/api";
import { RESET_STATE } from "@/fadel/vuex/constant-types";
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        option_categories : [],
        list_by_machine: null, // { list, machine }
        list_by_machine_loading: false,
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            Object.assign(state, getDefaultState())
        },
        SET_OPTION_CATEGORIES(state, payload) {
            state.option_categories = payload;
        },
        SET_LIST_BY_MACHINE(state, fetchedList) {
            let { list, machine } = fetchedList
            state.list_by_machine = {
                machine,
                list: list.map(i => ({ show: true, ...i }))
            }
        },
        // toggle, to show only selected order status
        TOGGLE_SHOW_ONLY_ORDER_STATUS_BY_MACHINE(state, orderStatusId ) {
            let allShowed = state.list_by_machine.list.every(i => i.show)
            if (allShowed) {
                // hide other
                state.list_by_machine.list
                    .filter(l => l.id !== orderStatusId)
                    .forEach(l2 => l2.show = false)
            } else {
                // show all
                state.list_by_machine.list.forEach(l => l.show = true)
            }
        },
        // after status change, should update position
        UPDATE_STATUS_POSITION(state, { orderStatusId, toOrderStatusCategoryId, fromOrderStatusCategoryId, message }) {
            // ON LIST BY MACHINE
            if (state.list_by_machine) {
                // find exact position and move the data
                let fromOrderStatusCategoryIdx = state.list_by_machine.list.findIndex(l => l.id === fromOrderStatusCategoryId)
                let fromOrderStatusIdx = state.list_by_machine.list[fromOrderStatusCategoryIdx].order_statuses.findIndex(o => o.id === orderStatusId)

                // only update when operator page open the same detail ( state.list_by_machine )
                if (fromOrderStatusIdx >= 0) {
                    let orderStatus = state.list_by_machine.list[fromOrderStatusCategoryIdx].order_statuses[fromOrderStatusIdx]
                    // change
                    orderStatus.order_status_category_id = toOrderStatusCategoryId
                    orderStatus.message = message
                    // add to next
                    let nextOrderStatusCategoryIdx = state.list_by_machine.list.findIndex(l => l.id === toOrderStatusCategoryId)
                    // push when status exist
                    let next = state.list_by_machine.list[nextOrderStatusCategoryIdx]
                    if (next) {
                        next.order_statuses.push(orderStatus)
                    }

                    // delete on current
                    state.list_by_machine.list[fromOrderStatusCategoryIdx].order_statuses.splice(fromOrderStatusIdx, 1)
                }
            }
        },
        updateField
    },
    actions: {
        loadOptionCategory({commit}) {
            return sendRequest('oa-client/order-status-category/option', {}, 'get', (response) => { commit('SET_OPTION_CATEGORIES', response)})
        },
        loadListByMachine({commit}, machineId) {
            commit('updateField', { path: 'list_by_machine_loading', value: true})
            return sendRequest('oa-client/order-status/machine/' + machineId, {}, 'get', (response) => {
                commit('SET_LIST_BY_MACHINE', response)
                commit('updateField', { path: 'list_by_machine_loading', value: false})
            })
        },
    },
    getters: {
        translateOperator: state => {
            if (!state.list_by_machine) return;

            let result = state.list_by_machine.machine.head_operator.name + ' ( K.OP )'
            result += ' - ' + state.list_by_machine.machine.operators.map(op => op.name).join(', ')
            return result
        },
        getField
    }
})

