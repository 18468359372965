// @version V0.1.1 add JSON.parse to exit reactivity
// @version v0.1.0 add option
// @version v0.0.1 init
// state
export const defaultListState = {
    list : null,
    list_loading : true,
    search_params: {
        // override sample : 
        // const overrided = JSON.parse(JSON.stringify(defaultListState))
        // overrided.search_params.filter_search_text = 'override'
        filter_search_text: '',
        filter_sort: [{
            'column': 'name',
            'sort': 'asc',
            'icon': ['fas', 'angle-up']
        }], //default value, for empty --> filterSort:[]
        filter_page: 1,
        filter_limit: 10,
        loading: true
    }
}
export const defaultOptionState = {
    options : [],
    option_loading: false
}

// mutation
export const defaultListMutation = {
    ADD_TO_LIST(state, fetchedNewItem) {
        let list = getList(state.list)
        list.unshift(fetchedNewItem);
    },
    SET_LIST(state, fetchedList) {
        state.list = fetchedList
    },
    SET_LIST_LOADING(state, status) {
        state.list_loading = status
    },
    UPDATE_IN_LIST(state, fetchedUpdateItem) {
        let list = getList(state.list)
        // update
        let idx = list.findIndex(l => l.id == fetchedUpdateItem.id)
        list[idx] = fetchedUpdateItem
    },
    REMOVE_IN_LIST(state, removedItem) {
        let list = getList(state.list)
        // remove
        let idx = list.findIndex(l => l.id == removedItem.id)
        if (idx >= 0) list.splice(idx, 1)
    }
}
export const defaultOptionMutation = {
    ADD_TO_OPTION(state, newOption) {
        state.options.unshift(newOption)
    },
    SET_OPTION(state, payload) {
        state.options = payload
    },
    SET_OPTION_LOADING(state, booleanStatus) {
        state.option_loading = booleanStatus
    },
    UPDATE_IN_OPTION(state, updatedOption) {
        let idx = state.options.findIndex(l => l.id == updatedOption.id)
        state.options[idx] = updatedOption
    },
    REMOVE_IN_OPTION(state, removedOption) {
        let idx = state.options.findIndex(l => l.id == removedOption.id)
        if (idx >= 0) state.options.splice(idx, 1)
    }
}

// action


// getter
export const defaultListGetter = {
    noDataInList: state => {
        if (state.list == null) return false
        let list = getList(state.list)
        return list.length == 0
    }
}

// helper
/**
 * determine if list has pagination or not
 * @param {state} stateList state.list
 * @returns list
 */
function getList(stateList) {
    return Array.isArray(stateList) ? stateList : stateList.data
}

