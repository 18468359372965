// @version V0.0.1 [Tuesday - 20201103_092924] : init
/**
 * **notification**
 * 
 * @param {string} type default, success, info, error
 * @param {string} message akses tidak diijinkan
 * @param {number} duration in miliseconds, null if forever
 * @param {string} position Position of the toast container ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
 */
export function notify(type = 'default', message, duration = 3000, position = 'top-right') {
    app.$toasted.show(message, { 
        type: type, // default, success, info, error 
        theme: "toasted-primary", 
        position: position, 
        duration : duration // null if forever
    });
}

export function clearNotify() {
    app.$toasted.clear()
}

export function notifyConfirmation(message, callbackContinue, callbackStop) {
    app.$toasted.show(message , {
        type: 'warning',
        duration: '5000',
        action: [{
            text: 'Ya',
            onClick: (e, toastedObject) => {
              toastedObject.goAway(0); // clear pop up
              callbackContinue(e);
            }
          },
          {
            text: 'Tidak',
            onClick: (e, toastedObject) => {
              //cancel to delete
              toastedObject.goAway(0); // clear pop up
              callbackStop(e);
            }
          }
        ]
    })
}