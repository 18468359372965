import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, RESET_FORM, SET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: [getDefaultRowState()]
    }
}

const getDefaultRowState = () => ({
    id: '',
    category: '',
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.push(getDefaultRowState())
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, payload) {
            Object.assign(state.rows[0], payload)
        },
        updateField

    },
    // can async
    actions: {

    },
    // call variable with custom display
    getters: {
        getField
    }
})