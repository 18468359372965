// @version V0.0.1 [Wednesday - 20201028_093439] : init

import Vue from 'vue';

window.moment = require('moment');
window.moment.locale('id');

//Vue Animate
// require('vue2-animate/dist/vue2-animate.min.css');


//Vue Events
// const VueEvents = require('vue-events');
// Vue.use(VueEvents);
import VueEvents from 'vue-events';
Vue.use(VueEvents);


// Vue Directive [Monday - 20190429_104827] V1.1.1
Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
})

// Vue Html To Paper
// import VueHtmlToPaper from 'vue-html-to-paper';
// const options = {
//   name: '_blank',
//   specs: [
//     'fullscreen=yes',
//     'titlebar=yes',
//     'scrollbars=yes'
//   ],
//   styles: [
//     'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
//     'https://unpkg.com/kidlat-css/css/kidlat.css'
//   ]
// }
// Vue.use(VueHtmlToPaper, options);
// or, using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);

// VModal
import VModal from 'vue-js-modal'
Vue.use(VModal)

// Vue2Filter
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

//-> another part
// Vue.filter('startCase', function (value) {
//     return !value ? '' : _.startCase(value) // ex : Barney Lee
// });

// Vue.filter('boolWord', function (value, mode) {
//     if (mode == 'm1') {
//         return value == 0 ? 'non-aktif' : 'aktif'
//     } else if (mode == 'm2') {
//         return value == 0 ? 'belum' : 'sudah'
//     } else {
//         return value == 0 ? 'tidak' : 'ya'
//     }
// });



// Vue Toasted
import Toasted from 'vue-toasted';
// require('vue-toasted'); // <-- this also works
Vue.use(Toasted);
// Vue.use(Toasted, {
//     theme: 'bubble',
//     position: "top-right",
//     duration: 3000,
// })
// //register global notification
// /* saving */
// Vue.toasted.register('savingData', 'Menyimpan Data...', {
//     type: 'info',
//     duration: null,
// })
// /* saving success */
// Vue.toasted.register('savingDataSuccess', 'Menyimpan Data Berhasil...', {
//     type: 'success'
// })
// /* saving failed */
// Vue.toasted.register('savingDataFailed', 'Menyimpan Data Gagal...', {
//     type: 'error'
// })
// /* loading data */
// Vue.toasted.register('loadingData', 'Memuat Data...', {
//     type: 'info',
//     duration: null
// })
// /* loading data failed */
// Vue.toasted.register('loadingDataFailed', 'Memuat Data Gagal...', {
//     type: 'error'
// })
// /* deleting data */
// Vue.toasted.register('deletingData', 'Menghapus Data...', {
//     type: 'info',
//     duration: null
// })
// /* deleting success */
// Vue.toasted.register('deletingDataSuccess', 'Menghapus Berhasil...', {
//     type: 'success'
// })
// /* deleting data failed */
// Vue.toasted.register('deletingDataFailed', 'Menghapus Data Gagal...', {
//     type: 'error'
// })

// Vue ProgressBar
import VueProgressBar from "vue-progressbar";
// Vue.use(VueProgressBar);

Vue.use(VueProgressBar, {
    color: 'rgb(255, 255, 255)', // as white
    failedColor: 'red',
    height: '2px'
  })


// VModal
// import VModal from 'vue-js-modal'
// Vue.use(VModal)