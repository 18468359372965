import {
    RESET_FORM, SET_FORM
} from "../../constant-types"
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        rows : [getDefaultRowState()],
        filter_roles : []
    }
}

const getDefaultRowState = () => (
    {
        id : '',
        barcode : '',
        product_category_id : '',
        product_name: '',
        unit: '',
        is_deprecated: false
    }
)
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_FORM](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, product) {
            state.rows[0] = _.pick(product, ['id', 'barcode', 'product_category_id', 'product_name', 'unit', 'is_deprecated'])
            state.filter_roles = product.filter_roles
        },
        updateField

    },
    // can async
    actions: {

    },
    // call variable with custom display
    getters: {
        getField
    }
})
