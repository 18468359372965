<template>
    <div class="flex justify-center">
        <!-- bayar, batal dan struk tanda terima  -->
        <submit-button :label="submitLabel" :request-sending="sending ? true : $store.state.requestStatus.sending" @click.native="$emit('submit-clicked')" :disabled="disabled ? true : $store.getters['requestStatus/getIsLocked']"
            class="mr-1" />
        <cancel-button @click.native="$emit('cancel-clicked')">{{ cancelLabel }}</cancel-button>
    </div>
</template>

<script>
import { SubmitButton, CancelButton } from '@/component'

    export default {
        name: "ButtonForm",
        components: {
            SubmitButton, CancelButton
        },
        data() {
            return {};
        },
        props: {
            submitLabel : {
                type : String,
                default : 'Simpan'
            },
            cancelLabel : {
                type : String,
                default : 'Batal'
            },
            disabled : {
                type : Boolean,
                default : false
            },
            sending: {
                type: Boolean,
                default: false
            }
        }
    };

</script>

<style lang="css" scoped>
</style>
