<template>
	<label class="block">
		<span class="text-gray-700">{{ label }}</span>
		<input v-bind="inputBind" :type="type" :class="inputClassWrapper" class="block w-full mt-1 form-input" :placeholder="placeholder" :value="value" @input="e => $emit('input', e.target.value)" />
	</label>
</template>

<script>
export default {
	name: "TextInput",
	data() {
		return {}
	},
    props: {
        label: {
            type: String,
            default: 'Deskripsi'
        },
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        type: {
            type: String, 
            default: 'text', // text | number | email | ...
        },
        inputClassWrapper: {
            type: String,
            default: ''
        },
        inputBind: {
            type: Object,
            default: () => {} 
        }
    }
}
</script>

<style lang="css" scoped>
</style>
