<template>
    <div class="min-w-full overflow-auto rounded-lg shadow">
        <table class="min-w-full bg-white">
            <thead class="text-green-700 bg-green-300 ">
                <slot name="head-data">
                    <tr>
                        <th>Name</th>
                        <th>Last name</th>
                        <th>Phone</th>
                        <th>Email</th>
                    </tr>
                </slot>
            </thead>
            <tbody class="text-gray-700">
                <tr key="loading" v-if="isLoading">
                    <td :colspan="totalColumn">
                        <loading-block :loading="isLoading" />
                    </td>
                </tr>
                <tr key="no-data" v-else-if="isNoData">
                    <td :colspan="totalColumn" class="text-center text-info">{{ noDataCaption }}</td>
                </tr>
                <template v-else>
                    <slot name="body-data"></slot>
                </template>
            </tbody>
            <tfoot class="text-green-700">
                <slot name="foot-data"></slot>
            </tfoot>
        </table>
    </div>
</template>

<script>
import  LoadingBlock from './LoadingBlock'

    export default {
        name: "TablePrimary",
        components: { LoadingBlock },
        data() {
            return {};
        },
        props: {
            totalColumn: {
                type: Number,
                default: 1
            },
            noDataCaption: {
                type: String,
                default: 'Data Tdk Tersedia...'
            },
            isNoData: {
                type: Boolean,
                default: true
            },
            isLoading: {
                type: Boolean,
                default: false
            }

        }
    };

</script>

<style lang="css" scoped>
    tr th {
        @apply uppercase text-sm;
    }

    tr td,
    tr th {
        @apply py-2 px-2;
    }

    tbody tr:nth-child(2n + 1) {
        @apply bg-gray-100;
    }

    tbody tr:hover {
        @apply bg-gray-300;
    }

</style>
