<template>
    <div class="flex border-t-2 border-b-2 text-gray-500 overflow-x-auto" v-if="menuList.length > 0">
        <router-link 
            :to="m.key ? { name: m.key } : m.uri" 
            v-for="(m, mKey) in menuList" 
            :key="'m' + mKey"
            class="px-2 py-1 font-semibold cursor-pointer hover:bg-green-200 whitespace-nowrap" 
            :class="{ active: m.active }">
            <font-awesome-icon :icon="m.icon" v-if="m.icon"></font-awesome-icon>
            {{ m.title }}
        </router-link>
    </div>
</template>

<script>
// @modified : add overflow-x-auto
// @version V0.0.2 [Friday - 20210129_081853] : router link by key if exist , router active condition
// @version V0.0.1 [Friday - 20201225_072127] : init
export default {
    name: "MenuListRow",
    data() {
        return {};
    },
    props: {
        menuList: {
            type: Array,
            default: () => ([])
        }
    }
};
</script>

<style lang="css" scoped>
.active {
    @apply bg-green-300;
}
</style>