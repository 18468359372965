<template>
    <!-- error info -->
    <div v-if="responseData" class="border rounded-lg shadow-md third-transition-enter-active">
        <!-- title -->
        <div class="p-2 text-xl font-bold text-center text-gray-600 uppercase bg-red-200 leading-wide">
            Terjadi Kesalahan
        </div>
        <div class="px-3 pt-1" v-if="responseData.message">
            <div class="flex items-center">
                <!-- icon -->
                <svg class="w-6 h-6 text-red-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="12" y1="8" x2="12" y2="12" />
                    <line x1="12" y1="16" x2="12.01" y2="16" /></svg>

                <span class="px-2 mr-1 text-xs text-gray-200 bg-red-700 rounded">{{ responseData.message }}</span>
            </div>
        </div>
        <div class="p-3" v-if="responseData.errors">
            <div v-for="(e,eKey) in responseData.errors" :key="'e'+eKey" class="flex items-center">
                <!-- icon -->
                <svg class="w-6 h-6 text-red-500" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                    stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="12" y1="8" x2="12" y2="12" />
                    <line x1="12" y1="16" x2="12.01" y2="16" /></svg>

                <span v-for="(i,iKey) in e" :key="'e'+eKey+'i'+iKey"
                    class="px-2 mr-1 text-xs text-gray-200 bg-red-700 rounded">{{ i }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrorCenter",
        data() {
            return {
                responseData: null
            };
        },
        events: {
            'show-error-response'(responseData) {
                this.responseData = _.isEmpty(responseData) ? null : responseData;
            },
            'show-success-response'() {
                this.responseData = null
            },
        },
    };

</script>

<style lang="css" scoped>
</style>
