<template>
    <div class="flex flex-wrap">
        <div class="p-2 text-gray-500 bg-gray-100 border rounded-l">
            {{ yearOnly ? 'Tahun' : 'Bulan'}}
        </div>
        <select v-if="!yearOnly" class="rounded-l-none rounded-r-none form-select" v-model.lazy="partMonth" @change="updateParent()" style="min-width:60px">
            <option :value="1">Januari</option>
            <option :value="2">Februari</option>
            <option :value="3">Maret</option>
            <option :value="4">April</option>
            <option :value="5">Mei</option>
            <option :value="6">Juni</option>
            <option :value="7">Juli</option>
            <option :value="8">Agustus</option>
            <option :value="9">September</option>
            <option :value="10">Oktober</option>
            <option :value="11">November</option>
            <option :value="12">Desember</option>
        </select>
        <div class="flex">
            <input type="text" v-model.lazy="partYear"  @change="updateParent()" class="mr-1 rounded-l-none form-input part-year">
            <button class="p-2 border rounded-l focus:outline-none hover:bg-gray-100" @click="yearOnly ? partYearChange(-1) : partMonthChange(-1)">
                <font-awesome-icon :icon="['fas','angle-left']" />
            </button>
            <button class="p-2 border focus:outline-none hover:bg-gray-100" @click="yearOnly ? partYearChange(0) : partMonthChange(0)">
                <font-awesome-icon :icon="['fas','home']" />
            </button>
            <button class="p-2 border rounded-r focus:outline-none hover:bg-gray-100" @click="yearOnly ? partYearChange(1) : partMonthChange(1)">
                <font-awesome-icon :icon="['fas','angle-right']" />
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MonthYearInput",
        data() {
            return {
                partMonth: 1,
                partYear: 2012,
            };
        },
        methods: {
            partYearChange(n) {
                if (n == 0) {
                    this.resetPartYear();
                } else {
                    this.partYear = +this.partYear + n
                }
                this.updateParent();
            },
            partMonthChange(n) {
                if (n == 0) {
                    this.resetPartMonth();
                    this.resetPartYear();
                } else {
                    // adjust month and year when it's first or last year
                    let result = this.partMonth + n
                    if (result === 0) {
                        this.partYear--
                        this.partMonth = 12
                    } else if (result === 13) {
                        this.partYear++
                        this.partMonth = 1
                    } else {
                        this.partMonth = this.partMonth + n
                    }
                }
                this.updateParent();
            },
            resetPartMonth() {
                this.partMonth = +moment().format('M');
            },
            resetPartYear() {
                this.partYear = +moment().format('YYYY');
            },
            updateParent() {
                // this.$emit('input', this.partDate);
                !this.yearOnly ? this.$emit('update:filterMonth', +this.partMonth) : '';
                this.$emit('update:filterYear', +this.partYear);
                this.$emit('change');
            },
        },
        props: {
            filterMonth: {
                type: [String, Number]
            },
            filterYear: {
                type: [String, Number]
            },
            yearOnly: {
                type: Boolean,
                default: false
            },
        },
        mounted() {
            this.partMonth = this.filterMonth;
            this.partYear = this.filterYear;
        }

    };

</script>

<style lang="css" scoped>

.part-year {
        width: 100px;
        text-align: center;
    }
</style>
