import { sendRequest } from "../api"
import { ADD_TO_QUICK_LIST, RESET_STATE, SET_QUICK_LIST } from "../constant-types"

const getDefaultState = () => {
    return {
        quick_list: []
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [ADD_TO_QUICK_LIST](state, createdItemList) {
            state.quick_list.unshift(createdItemList)
        },
        [SET_QUICK_LIST](state, fetchedList) {
            state.quick_list = fetchedList
        }

    },
    // can async
    actions: {
        loadQuickList({commit}, category = null) {
            let form = {}
            if(category) {
                form.category = category
            }
            sendRequest('activity-log/quick-list', form, 'get', (fetchedList) => commit(SET_QUICK_LIST, fetchedList))
        }
    },
    // call variable with custom display
    getters: {
        quickList: state => ({category, keyId}) => {
            if (category === 'status cetakan') {
                return state.quick_list.filter(l => l.category == category && l.loggable.order_demand_item.machine_id == keyId)
            } else if (category === 'pengaturan produk') {
                return state.quick_list.filter(l => l.category == category)
            } else if (category === 'pelanggan/mitra') {
                return state.quick_list.filter(l => l.category == category)
            } else {
                return state.quick_list
            }
        }
    },
    // modules
    modules: {

    }
})
