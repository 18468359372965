import {sendRequest} from "../../api"
import { updateField, getField } from "vuex-map-fields"

const getDefaultState = () => ({
    //label: '',
    //key: '',
    //description: '',
    //related_table: '',
    //related_ids: []
    list: [],
    list_loading: false,
    list_fetched: false,
    list_updating: false
})

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        updateField
    },
    actions: {
        loadList({commit}) {
            commit('updateField', { path: 'list_loading', value: true })
            return sendRequest('advanced-setting', {}, 'get', (fetchedList) => {
                commit('updateField', { path: 'list_loading', value: false })
                commit('updateField', { path: 'list_fetched', value: true })
                commit('SET_LIST', fetchedList)
            })
        },
        updateList({state, commit}) {
            commit('updateField', { path: 'list_updating', value: true })
            return sendRequest('advanced-setting', { list : state.list }, 'put', () => {
                commit('updateField', { path: 'list_updating', value: false })
            }, () => {}, () => {}, true, false, true)
        }
    },
    getters: {
        getField,
        getIds: (state) => (key) => {
            return state.list.find(i => i.key === key)?.related_ids
        },
        isInsideKey: (state, getters) => (key, roleId) => {
            let ids = getters.getIds(key)
            return ids.some(i => i === roleId)
        }
    }
})

