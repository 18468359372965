import { render, staticRenderFns } from "./FooterCopyright.vue?vue&type=template&id=3d43f86a&scoped=true&"
import script from "./FooterCopyright.vue?vue&type=script&lang=js&"
export * from "./FooterCopyright.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_babel-core@6.26.3_css-loader@1.0.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d43f86a",
  null
  
)

export default component.exports