import { clearNotify, notify, requestUpdate, sendRequest } from "../api"
import {
    getField,
    updateField
} from 'vuex-map-fields'

const getDefaultFormState = () => ({
    key: '',
    contacts: [],
    success: false,
    sending: false,
})

const getDefaultState = () => {
    return {
        notification_config_options: [
            { key : 'notification_status_cashier', label : 'notifikasi pembayaran' },
            { key : 'notification_status_category', label : 'notifikasi status cetakan' },
            { key : 'notification_status_cancelled', label : 'notifikasi pembatalan' },
            { key : 'notification_status_order_demand', label : 'notifikasi spk' },
        ],
        form: getDefaultFormState()
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        updateField,
        RESET_FORM(state) {
            Object.assign(state.form, getDefaultFormState())
        }
    },
    actions: {
        // load contacts with related given key
        async requestLoadForm({ state }, configKey) {
            notify('info', 'loading...', 7000)
            return sendRequest(`notification-custom-destination/${configKey}`, {}, 'get', (response) => {
                clearNotify()
                //state.form.key = configKey // already set on SelectInput
                state.form.contacts = response.contacts
                state.form.success= false,
                state.form.sending = false
            })
        },
        async requestUpdateCustomDestination({state, commit}) {
            commit('updateField', { path: 'form.sending', value: true })
            await requestUpdate('/notification-custom-destination', {
                notification_config_key : state.form.key,
                contacts : state.form.contacts.map(c => ({ id : c.id }))
            }, commit, undefined, false)
            commit('updateField', { path: 'form.sending', value: false })
        }
    },
    getters: {
        getField
    }
}
