export function dPerimeterCalc(dLong, dWide) {
    return dLong * dWide
}
export function dPriceCalc(dPerimeter, dKonstanta, priceReference) {
    return dPerimeter * dKonstanta * priceReference
}
export function addPriceCalc(addValue, addNPrice) {
    return addValue * addNPrice
}
export function nMarkupPriceCalc(markupPrice, tPcs) {
    return markupPrice / tPcs
}
export function stPriceCalc(dPrice, addPrice) {
    return dPrice + +addPrice
}
export function tPriceCalc(tPcs, stPrice, markupPrice) {
    return (tPcs * stPrice) + +markupPrice
}
export function wordsCalc(dLong, dWide, priceReference, dKonstanta, dPrice, addLabel, addValue, addUnit, addPrice, addNPrice, markupPrice, stPrice,tPcs, tPrice, nMarkupPrice) {
    // arrange word 
    let wordDimension = `${dLong} x ${dWide} @ ${app.$root.formatCurrency(priceReference)} x ${dKonstanta} = ${app.$root.formatCurrency(dPrice)}`

    let wordAdditional = `${addLabel} ${addValue} ${addUnit} @ ${app.$root.formatCurrency(addNPrice)} = ${app.$root.formatCurrency(addPrice)}`

    let wordMarkupPrice = `biaya up total ${app.$root.formatCurrency(markupPrice)}`

    let wordTotal = `total -> ${app.$root.formatCurrency(stPrice)} x ${tPcs}pcs = ${app.$root.formatCurrency(tPrice)}`

    let words = wordDimension
    // add if have additional
    if (addValue > 0) {
        words += '\n' + wordAdditional
    }
    // add if have markup
    if (nMarkupPrice > 0) {
        words += '\n' + wordMarkupPrice
    }

    words += '\n' + wordTotal

    return words
}
export function wordsNoPriceCalc(dLong, dWide, dKonstanta, tPcs, addLabel, addValue, addUnit) {
    // arrange word 
    let wordDimension = `${dLong} x ${dWide} @ ${dKonstanta}(k) x ${tPcs}pcs`

    let wordAdditional = `${addLabel} ${addValue} ${addUnit}`

    let words = wordDimension
    // add if have additional
    if (addValue > 0) {
        words += '\n' + wordAdditional
    }

    return words
}
export function  priceByClientCategoriesCalc(productPrices, dLong, dWide, dKonstanta, addPrice, tPcs) {
    return productPrices.map(pp => {
        return {
            client_category_id: pp.client_category_id,
            client_category_name : pp.client_category.client_category,
            product_id: pp.product_id,
            sell_price: pp.sell_price,
            total_price: (dLong * dWide * dKonstanta * pp.sell_price + addPrice) * tPcs
        }
    })
}
