<template>
    <!-- OPTIMIZE: (sidemenu): not light if nested router with action route, should this cause of PageSeeder list ?  -->
    <div class="flex flex-col text-gray-700">
        <template v-if="menuList.length > 0">
            <router-link  :to="l.link" :class="$route.path == l.link ? 'bg-green-200' : ''" class="px-2 py-1 font-bold tracking-tight capitalize rounded hover:bg-green-200"  v-for="(l,lKey) in menuList" :key="'l'+lKey">
                <font-awesome-icon :icon='l.icon' v-if="l.icon"></font-awesome-icon>
                {{ l.label }} 
            </router-link>
        </template>
        <loading-block v-else></loading-block>
    </div>
</template>

<script>
import { LoadingBlock } from "@/component";
    export default {
        name: "MenuListColumn",
        components: {
            LoadingBlock,
        },
        data() {
            return {};
        },
        props: {
            menuList : {
                type: Array,
                default : () => {
                    return []
                }
            }
        },
    };

</script>

<style lang="css" scoped>
    .active {
        @apply bg-green-200;
    }
</style>
