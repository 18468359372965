import {
    getField,
    updateField
} from "vuex-map-fields"
import {requestSubmit, sendRequest} from "../../api"
import {
    ADD_ROW, RESET_FORM
} from "../../constant-types"
import { groupBy } from "lodash"

const getDefaultState = () => {
    return {
        forms: [],
        form_loading: true
    }
}

const getDefaultRowState = () => ({
    branch_id: '',
    machine_id: '',
    client_category_id: '',
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.forms.unshift(getDefaultRowState())
        },
        REMOVE_ROW(state, idx) {
            state.forms.splice(idx, 1)
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        SET_FORM(state, payload) {
            //// transform to grouping
            ////state.forms = groupBy(payload, 'branch_id')
            //let output = payload.reduce((result, item) => {

                //// Get app object corresponding to current item from result (or insert if not present)
                //var app = result[item.branch_id] = result[item.branch_id] || {};

                //// Get type array corresponding to current item from app object (or insert if not present)
                //var type = app[item.client_category_id] = app[item.client_category_id] || [];

                //// Add current item to current type array
                //type.push(item);

                //// Return the result object for this iteration
                //return result;

            //}, {});

            //state.forms = output
            //
            state.forms = payload
        },
        updateField
    },
    // can async
    actions: {
        loadForm({commit}) {
            commit('updateField', { path: 'form_loading', value: true })
            return sendRequest('restrict-order-flow', {}, 'get', (successResponse) => {
                commit('SET_FORM', successResponse)
                commit('updateField', { path: 'form_loading', value: false })
            })
        },
        requestUpdate({commit, state}) {
            return requestSubmit('restrict-order-flow', { params : state.forms }, commit)
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
