import {
    sendRequest,
} from "@/fadel/vuex/api"
import {
    RESET_STATE,
    SET_OPTION
} from "@/fadel/vuex/constant-types"
import {
    getField,
    updateField
} from 'vuex-map-fields'


const getDefaultState = () => {
    return {
        options: [],
        option_loading: false,
        list: [],
        list_loading: true
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_OPTION](state, option) {
            state.options = option;
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        // update the recap counter in option -> count_wait_for_print, count_print_finished
        'UPDATE_LIST_RECAP_COUNTER'(state, { machineId, countWaitForPrint, countPrintFinished }) {
            let machine = state.list.find(o => o.id === machineId )
            machine.count_wait_for_print = countWaitForPrint
            machine.count_print_finished = countPrintFinished
        },
        updateField
    },
    // can async
    actions: {
        loadOption({
            commit
        }) {
            commit('updateField', { path: 'option_loading', value: true })
            return sendRequest('oa-client/machine/option', {}, 'get', (responseOptions) => commit(SET_OPTION, responseOptions), () => {}, () => { commit('updateField', { path: 'option_loading', value: false }) })
        },
        loadList({
            commit
        }) {
            commit('updateField', { path: 'list_loading', value: true })
            return sendRequest('oa-client/machine/list', {}, 'get', (responseOptions) => {
                commit('SET_LIST', responseOptions)
                commit('updateField', { path: 'list_loading', value: false })
            })
        },
    },
    // call variable with custom display
    getters: {
        getField
    }
})
