import { requestSubmit, sendRequest } from "../api"
import {
    ADD_TO_OPTION,
    RESET_STATE, SET_OPTION, UPDATE_TO_OPTION
} from "../constant-types"
import productCategoryForm from "./form/productCategoryForm"

const getDefaultState = () => {
    return {
        options: []
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_TO_OPTION](state, createdCategory) {
            state.options.unshift(createdCategory)
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_OPTION](state, option) {
            state.options = option
        },
        [UPDATE_TO_OPTION](state, updatedCategory) {
            let idx = state.options.findIndex(o => o.id === updatedCategory.id)
            state.options[idx] = updatedCategory
        }
    },
    // can async
    actions: {
        loadOptions({commit}) {
            return sendRequest('product-category/option', {}, 'get', (options) => commit(SET_OPTION, options))
        },
        requestCreateCategory({commit, state}) {
            return requestSubmit('product-category', state.productCategoryForm.rows[0], commit, 'post', (createdCategory) => commit(ADD_TO_OPTION, createdCategory) )
        },
        requestUpdateCategory({commit, state}) {
            return requestSubmit('product-category', state.productCategoryForm.rows[0], commit, 'put', (updatedCategory) => commit(UPDATE_TO_OPTION, updatedCategory))
        },
        requestUpsertCategory({commit, state}) {
            return requestSubmit('product-category/upsert', { categories : state.productCategoryForm.rows }, commit, 'post', (createdCategories) => commit(SET_OPTION, createdCategories) )
        }
    },
    // call variable with custom display
    getters: {

    },
    // modules
    modules: {
        productCategoryForm
    }
})
