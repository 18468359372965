import {
    sendRequest,
} from "@/fadel/vuex/api"
import {
    RESET_STATE,
} from "@/fadel/vuex/constant-types"
import {
    getField,
    updateField
} from 'vuex-map-fields'


const getDefaultState = () => {
    return {
        list: [],
        list_loading: false,
        recap: {},
        recap_loading: false
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        SET_RECAP(state, payload) {
            state.recap = payload
        },
        // not used yet, keep for optimize later
        ADD_UPDATE_LIST(state, relatedOrderDemand) {
            // when no list or no key found, add as new group
            if (Object.keys(state.list).length === 0 || (state.list[relatedOrderDemand.created_at_date] === undefined)) {
                app.$set(state.list, relatedOrderDemand.created_at_date, [relatedOrderDemand])
            } else if (state.list[relatedOrderDemand.created_at_date] !== undefined) {
                let idx = state.list[relatedOrderDemand.created_at_date].findIndex(i => i.id === relatedOrderDemand.id)
                if (idx >= 0) {
                    state.list[relatedOrderDemand.created_at_date].splice(idx, 1, relatedOrderDemand)
                }
            } else {
                state.list[relatedOrderDemand.created_at_date].unshift(relatedOrderDemand)
            }
        },
        updateField
    },
    // can async
    actions: {
        loadList({
            commit
        }) {
            commit('updateField', { path: 'list_loading', value: true })
            return sendRequest('oa-client/order/list', {}, 'get', (responseOptions) => {
                commit('SET_LIST', responseOptions)
                commit('updateField', { path: 'list_loading', value: false })
            })
        },
        // not used yet, keep for optimize later
        addUpdateList({commit, rootState}, relatedOrderDemand) {
            // should match client id
            if (rootState.oaClient.authClient.client.id !== relatedOrderDemand.client_id) return;
            commit('ADD_UPDATE_LIST', relatedOrderDemand)
        },
        reloadListRelatedClient({rootState, dispatch}, { clientId }) {
            if (rootState.oaClient.authClient.client.id === clientId) {
                dispatch('loadList')
            }
        },
        loadRecap({commit}) {
            commit('updateField', {path: 'recap_loading', value: true})
            return sendRequest('oa-client/order/recap', {}, 'get', (responseOptions) => {
                commit('SET_RECAP', responseOptions)
                commit('updateField', {path: 'recap_loading', value: false})
            })
        },
        loadRecapRelatedClient({dispatch, rootState}, clientId) {
            // should match client id
            if (rootState.oaClient.authClient.client.id !== clientId) return;

            dispatch('loadRecap')
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})

