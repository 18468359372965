import {sendRequest, requestUpdate, requestDelete, notify, clearNotify} from "@/fadel/vuex/api"
import { updateField, getField } from "vuex-map-fields"

const getDefaultState = () => ({
    list: [],
    list_loading: false,
    list_fetched: false,
    list_updating: false,

    counter_rely_options: ['order', 'order_demand_creator'], // client, customer_service is for future development
    counter_rely_labels: {
        order : 'Order / SPK',
        order_demand_creator: 'Pembuat SPK',
        client: 'Pelanggan',
        customer_service: 'Customer Service'
    },
    auto_reset_counter_options: ['manual', 'daily', 'monthly', 'yearly'],
    auto_reset_counter_labels: {
        manual : 'Manual',
        daily: 'Harian',
        monthly: 'Bulanan',
        yearly: 'Tahunan'
    }
})

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        ADD_TO_LIST(state, payload) {
            state.list.unshift(payload)
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        UPDATE_IN_LIST(state, updatedSetting) {
            let idx = state.list.findIndex(e => e.code === updatedSetting.code)
            if ( idx >= 0 ) {
                // UNTESTED: should check item, if is change or not
                state.list[idx] = updatedSetting
            }
        },
        // set active, and other should disactive
        SWITCH_ACTIVE(state, code) {
            state.list.forEach(e => {
                e.status_active = e.code === code ? true : false
            });
        },
        REMOVE_IN_LIST(state, code) {
            let idx = state.list.findIndex(e => e.code === code)
            if ( idx >= 0 ) {
                state.list.splice(idx, 1);
            }
        },
        updateField
    },
    actions: {
        loadList({commit}) {
            commit('updateField', { path: 'list_loading', value: true })
            return sendRequest('receipt-code-setting', {}, 'get', (fetchedList) => {
                commit('updateField', { path: 'list_loading', value: false })
                commit('updateField', { path: 'list_fetched', value: true })
                commit('SET_LIST', fetchedList)
            })
        },
        requestSwitchActiveSetting({commit}, code) {
            return requestUpdate(`/receipt-code-setting/switch/${code}`, { code }, commit, 'SWITCH_ACTIVE', true)
        },
        requestDeleteSetting({commit}, setting) {
            return requestDelete(`/receipt-code-setting/switch/${code}`, {}, commit, setting.code, 'REMOVE_IN_LIST' )
        },
        requestResetCounter({dispatch}, code) {
            notify('info', `mereset nota ${code}...`, null)
            return sendRequest(`/receipt-code-setting/${code}`, {}, 'put', (success) => {
                clearNotify()
                notify('success', `nota ${code} telah direset, refresh list`)
                dispatch('loadList')
            })
        },
    },
    getters: {
        getField,
    }
})

