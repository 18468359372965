<template>
	<label class="block">
        <span class="text-gray-700" v-if="showLabel">{{ label }}</span>
        <select class="block w-full mt-1 form-select" :value="value" @input="e => $emit('input', e.target.value)">
            <option value="" disabled> --- </option>
            <slot name="option" />
        </select>
    </label>
</template>

<script>
export default {
	name: "SelectInput",
	data() {
		return {}
	},
    props: {
        label: {
            type: String,
            default: 'Deskripsi'
        },
        value: {
            type: [String, Number, Boolean],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="css" scoped>
</style>
