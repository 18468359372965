// @modified: add shouldCommit params on requestUpdate
// @version V0.0.2 [Monday - 20201130_215344] : mutation as optional
// @version V0.0.1 [Saturday - 20201114_204124] : init
import {
    ADD_IN_LIST, REMOVE_IN_LIST, UPDATE_IN_LIST
} from "../../constant-types";
import {
    clearNotify,
    notify,
    notifyConfirmation
} from "../notification";
import {
    sendRequest
} from "../request";

export function requestCreate(url, form, commit, commitName = ADD_IN_LIST) {
    notify('info', 'menyimpan...', null)
    return sendRequest(url, form, 'post', (newItem) => {
        clearNotify()
        commit(commitName, newItem)
    }, (error) => {
        clearNotify()
        notify('error', 'gagal menyimpan')
    })
}

export function requestUpdate(url, form, commit, commitName = UPDATE_IN_LIST, shouldCommit = true) {
    notify('info', 'mengupdate...', null)
    return sendRequest(url, form, 'put', (updatedItem) => {
        clearNotify()
        if ( shouldCommit ) commit(commitName, updatedItem)
    }, (error) => {
        clearNotify()
        notify('error', 'gagal mengupdate')
    }, () => {})
}

export function requestDelete(url, form, commit, confirmationLabel, commitName = REMOVE_IN_LIST) {
    // confirmation send delete
    notifyConfirmation('apakah yakin ingin menghapus ' + confirmationLabel,
    // continue
    () => {
        notify('info', 'menghapus...',null)
        // sendRequest('user', user, 'delete',
        sendRequest(url, form, 'delete',
            (deletedItem) => {
                // delete related user in list
                commit(commitName, deletedItem)
                clearNotify()
            },
            (error) => {
                clearNotify()
                notify('error', 'gagal menghapus')
            },
            () => {

            })
    },
    // stop
    () => {}
)
}
