import {
    getField,
    updateField
} from "vuex-map-fields"
import {requestSubmit} from "../../api"

const getDefaultState = () => {
    return {
        setting_form: {
            code: '',
            description: '',
            counter_rely_on: 'order', // order as standard
            auto_reset_counter: 'daily', // daily as standard
            running_day: 0,
            running_month: 0,
            running_year: 0,
            status_active: false,
            receipt_code_setting_items: [getDefaultSettingItems()]
        },
        setting_form_loading: false
    }
}

const getDefaultSettingItems = () => ({
    id: '',
    code: '',
    prefix: '',
    min_pad: 4, // 4 as default
    last_counter: 0,
    reliable_id: null,
    reliable_type: 'App\\Models\\User', // as App\\Models\\User as default
    reliable: null,
    status_active: false
})

export default ({
    namespaced: true,
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        ADD_ITEM(state) {
            state.setting_form.receipt_code_setting_items.push(getDefaultSettingItems())
        },
        REMOVE_ITEM(state, item) {
            let idx = state.setting_form.receipt_code_setting_items.findIndex(e => e.code === item.code)
            state.setting_form.receipt_code_setting_items.splice(idx, 1)
        },
        RESET_FORM(state) {
            Object.assign(state, getDefaultState())
        },
        SET_FORM(state, setting) {
            state.setting_form = JSON.parse(JSON.stringify(setting))
        },
        updateField
    },
    // can async
    actions: {
        // request create
        requestCreateSetting({commit, state}) {
            return requestSubmit(`receipt-code-setting`, state.setting_form, commit, 'post', (createdSetting) => {
                commit('receiptCodeSetting/ADD_TO_LIST', createdSetting, { root: true}  )
            });
        },
        // request update
        requestUpdateSetting({commit, state}) {
            return requestSubmit(`receipt-code-setting`, state.setting_form, commit, 'put', (updatedSetting) => {
                commit('receiptCodeSetting/UPDATE_IN_LIST', updatedSetting, { root: true}  )
            });
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})

