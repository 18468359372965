import {
    ADD_IN_LIST,
    REMOVE_IN_LIST,
    RESET_FORM,
    SET_FORM,
    SET_LIST,
    UPDATE_IN_LIST,
} from "./../constant-types";
import { getField, updateField } from "vuex-map-fields";
import {
    clearNotify,
    notify,
    requestDelete,
    requestSubmit,
    sendRequest,
} from "../api";

const getDefaultState = () => {
    return {
        form: getDefaultFormState(),
        detail: {},
        detail_loading: false,
        list: [],
        list_loading: false,
    };
};

const getDefaultFormState = (category = "customer_service_group") => (
    {
        id: "",
        name: "",
        category,
    }
);
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        [RESET_FORM](state, category) {
            Object.assign(state.form, getDefaultFormState(category));
        },
        [SET_FORM](state, payload) {
            //state.form = _.pick(JSON.parse(JSON.stringify(payload)), ['id', 'name', 'category'])
            state.form = JSON.parse(JSON.stringify({
                id: payload.id,
                name: payload.name,
                category: payload.category,
            }));
        },
        [SET_LIST](state, payload) {
            state.list = payload;
        },
        SET_LIST_LOADING(state, payload) {
            state.list_loading = payload;
        },
        [ADD_IN_LIST](state, payload) {
            state.list.unshift(payload);
        },
        [UPDATE_IN_LIST](state, payload) {
            let idx = state.list.findIndex((f) => f.id === payload.id);
            if (idx >= 0) {
                state.list.splice(idx, 1, payload);
            }
        },
        [REMOVE_IN_LIST](state, payload) {
            let idx = state.list.findIndex((f) => f.id === payload.id);
            state.list.splice(idx, 1);
        },
        ADD_CUSTOMER_SERVICE_IN_DETAIL(state, customerServices) {
            if (state.detail) {
                customerServices.forEach((customerService) => {
                    state.detail.customer_services.unshift(customerService);
                });
            }
        },
        REMOVE_CUSTOMER_SERVICE_IN_DETAIL(state, customerServices) {
            if (state.detail) {
                customerServices.forEach((customerService) => {
                    let idx = state.detail.customer_services.findIndex((f) =>
                        f.id === customerService.id
                    );
                    if (idx >= 0) {
                        state.detail.customer_services.splice(idx, 1);
                    }
                });
            }
        },
        ADD_CUSTOMER_SERVICE_COUNT_IN_GROUP(state, { groupId, count = 1 }) {
            let idx = state.list.findIndex((l) => l.id === groupId);
            if (idx >= 0) {
                state.list[idx]["customer_services_count"] += count;
            }
        },
        SUBTRACT_CUSTOMER_SERVICE_COUNT_IN_GROUP(state, { groupId, count = 1 }) {
            let idx = state.list.findIndex((l) => l.id === groupId);
            if (idx >= 0) {
                state.list[idx]["customer_services_count"] -= count;
            }
        },
        updateField,
    },
    // can async
    actions: {
        loadList({ commit }) {
            commit("SET_LIST_LOADING", true);
            return sendRequest(
                "group/customer-service",
                {},
                "get",
                (successResponse) => {
                    commit(SET_LIST, successResponse);
                    commit("SET_LIST_LOADING", false);
                },
            );
        },
        loadDetail({ commit }, groupId) {
            commit("updateField", { path: "detail_loading", value: true });
            return sendRequest(
                `group/customer-service/${groupId}`,
                {},
                "get",
                (successResponse) => {
                    // sort customer service
                    let { customer_services, ...group } = successResponse;
                    let value = {
                        ...group,
                        customer_services: _.sortBy(customer_services, function(o) {
                            return o.name;
                        }),
                    };
                    commit("updateField", { path: "detail", value });
                    commit("updateField", { path: "detail_loading", value: false });
                },
            );
        },
        requestCreateGroup({
            commit,
            state,
        }) {
            return requestSubmit(
                "group/customer-service",
                state.form,
                commit,
                "post",
                (createdGroup) => commit(ADD_IN_LIST, createdGroup),
            );
        },
        requestUpdateGroup({
            commit,
            state,
        }) {
            return requestSubmit(
                `group/customer-service/${state.form.id}`,
                state.form,
                commit,
                "put",
                (updatedGroup) => commit(UPDATE_IN_LIST, updatedGroup),
            );
        },
        requestDeleteGroup({
            commit,
        }, group) {
            return requestDelete(
                `group/customer-service/${group.id}`,
                {},
                commit,
                group.name,
                REMOVE_IN_LIST,
            );
        },
        requestAddCustomerService(
            { commit, state, dispatch },
            { groupId, customerServices },
        ) {
            // prevent when already added
            if (state.detail) {
                if (
                    state.detail.customer_services.some((c) =>
                        c.id === customerServices[0]["id"]
                    )
                ) {
                    notify("info", "customer service sudah ada sebelumnya");
                    return;
                }
            }

            notify("info", "menambahkan customer service");
            return requestSubmit(
                `group/${groupId}/customer-services/add`,
                { customerServiceIds: customerServices.map((c) => c.id) },
                commit,
                "put",
                (successResponse) => {
                    // add in detail
                    commit("ADD_CUSTOMER_SERVICE_IN_DETAIL", customerServices);
                    // update group list ( update count )
                    commit("ADD_CUSTOMER_SERVICE_COUNT_IN_GROUP", {
                        groupId,
                        count: customerServices.length,
                    });
                    // add group in options
                    const group = { id: state.detail.id, name: state.detail.name }
                    dispatch('customerService/addGroupInOption', { customerService : customerServices[0], group }, { root: true })
                    // clear notify
                    clearNotify();
                },
            );
        },
        requestRemoveCustomerService({ commit, dispatch, state }, { groupId, customerServices }) {
            notify("info", "menghapus pelanggan");
            return requestSubmit(
                `group/${groupId}/customer-services/remove`,
                { customerServiceIds: customerServices.map((c) => c.id) },
                commit,
                "put",
                (successResponse) => {
                    //if success, remove in list
                    if (successResponse > 0) {
                        // remove in detail if match and exist
                        commit("REMOVE_CUSTOMER_SERVICE_IN_DETAIL", customerServices);
                        // update group list ( update count )
                        commit("SUBTRACT_CUSTOMER_SERVICE_COUNT_IN_GROUP", {
                            groupId,
                            count: customerServices.length,
                        });
                        // remove group in options
                        const group = { id: state.detail.id, name: state.detail.name }
                        dispatch('customerService/removeGroupInOption', { customerService : customerServices[0], group }, { root: true })
                        // clear notify
                        clearNotify();
                    }
                },
            );
        },
    },
    // call variable with custom display
    getters: {
        getField,
        getList: (state) => (category, filterSearchText = "") =>
            state.list.filter((f) => {
                let reg = new RegExp(filterSearchText, "i");
                return f.category === category && f.name.search(reg) >= 0;
            }),
    },
});
