<template>
    <div v-if="response">
        <div class="inline-block px-2 text-xs text-gray-200 bg-red-700 rounded shadow-solid third-transition-enter-active animate-pulse"
            v-for="e in response" :key="e">
            {{ e }}
        </div>
    </div>
</template>

<script>
// @version V0.0.2 [Friday - 20201218_200320] : fix error case
    export default {
        name: "ErrorField",
        data() {
            return {
                response : null
            };
        },
        props : {
            fieldName : {
                type : String,
            }
        },
        events: {
            'show-error-response'(responseData) {
                let result = responseData['errors'] ? responseData['errors'][this.fieldName] : '';
                this.response = _.isEmpty(result) ? null : result ;
            },
            'show-success-response'() {
                this.response = null
            },
        },
    };

</script>

<style lang="css" scoped>
</style>
