import { requestDelete, requestSubmit, sendRequest } from "../api"
import {
    getField,
    updateField
} from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        list: [],
        list_loading: false,
        form: getDefaultFormState(),
        options_limit_for : [
            {label: 'Pelanggan', value: 'client'},
            {label: 'Customer Servis', value: 'customer_service'}
        ],
        options_limitable_type: ['App\\Models\\Client', 'App\\Models\\CustomerService']
    }
}

const getDefaultFormState = () => {
    return {
        id: null,
        limitable: null,
        limitable_id: '',
        limitable_type: '',
        limit_for: 'client',
        limit_day: 0,
        limit_nominal: 0,
        status_active: false,
        latest_unpaid_cashier_date: null,
        total_unpaid: 0,
        limit_nominal_remain: 0
    }
}

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        ADD_TO_LIST(state, list) {
            state.list.unshift(list)
        },
        UPDATE_TO_LIST(state, updatedList) {
            let idx = state.list.findIndex(o => o.id == updatedList.id)
            state.list[idx] = updatedList
        },
        REMOVE_FROM_LIST(state, limitOrderDemand) {
            // _.remove(state.list, { id : limitOrderDemand.id})
            let idx = state.list.findIndex(o => o.id === limitOrderDemand.id)
            state.list.splice(idx, 1);
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        RESET_FORM(state) {
            Object.assign(state.form, getDefaultFormState())
        },
        SET_FORM(state, limitOrderDemand) {
            Object.assign(state.form, limitOrderDemand)
        },
        updateField
    },
    actions: {
        loadList({
            commit
        }) {
            commit('updateField', { path: 'list_loading', value: true })
            return sendRequest('limit-order-demand', {}, 'get', (responseOptions) => commit('SET_LIST', responseOptions), () => {}, () => { commit('updateField', { path: 'list_loading', value: false }) })
        },
        requestCreateLimitOrderDemand({
            commit,
            state
        }) {
            return requestSubmit('limit-order-demand', state.form, commit, 'post', (createdLimitOrderDemand) => commit('ADD_TO_LIST', createdLimitOrderDemand))
        },
        requestUpdateLimitOrderDemand({
            commit,
            state
        }) {
            return requestSubmit(`limit-order-demand/${state.form.id}`, state.form, commit, 'put', (updatedLimitOrderDemand) => commit('UPDATE_TO_LIST', updatedLimitOrderDemand))
        },
        requestDeleteLimitOrderDemand({
            commit
        }, limitOderBranch) {
            return requestDelete(`limit-order-demand/${limitOderBranch.id}`, {}, commit, limitOderBranch.limitable?.name, 'REMOVE_FROM_LIST')
        }
    },
    getters: {
        getField

    }
})
