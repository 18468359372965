<template>
    <div class="d-flex justify-content-center">
        <template v-if="guest">
            <button class="mr-1 btn" @click="toLogin">
                <font-awesome-icon icon='sign-in-alt'></font-awesome-icon>
                Login
            </button>
            <!-- <button class="mr-1 btn" @click="toRegister">
                <font-awesome-icon icon='user-plus'></font-awesome-icon>
                Daftar
            </button> -->
        </template>
        <!-- when sign-in -->
        <template v-else>
            <span class="px-1 py-1 mr-1 text-xs italic text-green-100 bg-green-700 rounded-lg">{{ name | truncate(10) }}
                <span>{{ role_name }}</span>
            </span>
            <button class="mr-1 btn" @click="toLogout">
                <font-awesome-icon icon='sign-out-alt'></font-awesome-icon>
                Logout
            </button>
        </template>
    </div>
</template>

<script>
    // @version V0.0.2 [Tuesday - 20201103_092051] : new update issue about name still when logouted
    // @version V0.0.1 [Thursday - 20200611_230147] : loading authUser moved to authRoute.js
    import { mapState, mapActions, mapGetters } from "vuex";

    export default {
        name: "MenuAuthority",
        computed: {
            // ...mapState('authUser', ['guest','name','role_name'])
            ...mapState('authUser', {
                guest: state => state.guest,
                name: state => state.name,
                role_name: state => state.role.name
            })
            // ...mapGetters('authUser', ['authUserInfo'])
        }
        ,
        data() {
            return {};
        },
        methods: {
            ...mapActions(['authUser']),
            toLogin() {
                this.$router.push('/guest/login');
                this.toEmit();
            },
            toRegister() {
                this.$router.push('/guest/register');
                this.toEmit();
            },
            async toLogout() {
                await this.$store.dispatch('authUser/logoutAuth');
                // don't push if already
                if (!(['home', 'welcome'].some( e => e === this.$route.name) || this.$route.redirectedFrom == '/')) {
                    this.$router.push({name:'home'}); // go to home
                }

                this.toEmit();
            },
            toEmit() {
                // collapseNavbar() { $('#my-nav').collapse('hide'); } // add this on parent for navbar
                this.$emit('buttonClicked');
            },
            echoListeners() {
                //-> some echo example :
                // don't forget to remove listener Echo.leaveChannel('channelName') on beforeDestroy() component event
                Echo.channel(process.env.MIX_APP_NAME)
                // sometimes need dot prefix on event name
                .listen('.App\\Events\\RoleUpdated', (e) => {
                    // should refresh auth
                    this.$store.dispatch('authUser/refreshAuth');
                });
            }
        },
        mounted() {
            this.echoListeners()

            this.$once('hook:beforeDestory', () => {
                Echo.leaveChannel(process.env.MIX_APP_NAME) ;
            })
            // get auth info
            // this.$store.dispatch('authUser/refreshAuth'); //depcrecated, moved to authRoute.js in beforeRouteEnter

        }
    };

</script>

<style lang="css" scoped>
.btn {
    @apply px-1 text-xs font-bold text-teal-700
}
.btn:hover {
    @apply text-white
}
</style>
