import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, REMOVE_ROW, RESET_FORM, SET_FORM, SET_FORMS
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: []
    }
}

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, machineIds) {
            state.rows = machineIds
        },
        updateField
    },
    // call variable with custom display
    getters: {
        getField,
    }
})

