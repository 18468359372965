import { getSearch, requestDelete, requestSubmit} from "../api"
import {
    ADD_IN_LIST,
    REMOVE_IN_LIST,
    RESET_STATE,
    SET_LIST,
    UPDATE_IN_LIST
} from "../constant-types"
import computerForm from "./form/computerForm"
import { getField, updateField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        list: null,
        search_params: getDefaultSearchParamsState()
    }
}

const getDefaultSearchParamsState = () => {
    return {
        filter_search_text : '',
        loading : true,
        filter_sort : [{
            column : 'name',
            sort : 'asc',
            icon : ['fas', 'angle-up']
        }],
        filter_page : 1,
        filter_limit : 10
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_IN_LIST](state, createdComputer) {
            state.list.data.unshift(createdComputer)
        },
        [REMOVE_IN_LIST](state, deletedComputer) {
            let idx = state.list.data.findIndex(l => l.id === deletedComputer.id)
            state.list.data.splice(idx, 1)
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_LIST](state, list) {
            state.list = list
        },
        [UPDATE_IN_LIST](state, updatedComputer) {
            let idx = state.list.data.findIndex(l=> l.id === updatedComputer.id)
            state.list.data[idx] = updatedComputer
        },
        updateField

    },
    // can async
    actions: {
        loadList({commit, state, getters}, payload = { resetPage : false}) {
            if (payload.resetPage) {
                commit('updateField', { path : 'search_params.filter_page', value : 1})
            }
            commit('updateField', { path : 'search_params.loading', value : true})
            return getSearch(
                {words: _.split(state.search_params.filter_search_text, ' '), fields : ['name', 'branch>name']},
                [],
                [],
                state.search_params.filter_sort,
                state.search_params.filter_page,
                state.search_params.filter_limit,
                ['branch:id,name'],
                [],
                'computer',
                (response) => commit(SET_LIST, response),
                ()=>{},
                ()=>{
                    commit('updateField', { path : 'search_params.loading', value : false})
                }
            )
        },
        resetForm({commit}) {
            commit('computer/computerForm/RESET_FORM', null, { root : true})
        },
        requestCreateComputer({commit, state}) {
            let createdForm = { ...state.computerForm.form }
            return requestSubmit('computer', createdForm, commit, 'post', (createdComputer) => commit(ADD_IN_LIST, createdComputer))
        },
        requestUpdateComputer({commit, state}) {
            let createdForm = { ...state.computerForm.form }
            return requestSubmit(`computer/${createdForm.id}`, createdForm, commit, 'put', (editedComputer) => commit(UPDATE_IN_LIST, editedComputer))
        },
        requestDeleteComputer({commit}, computer) {
            return requestDelete(`computer/${computer.id}`, {}, commit, computer.name, REMOVE_IN_LIST)
        }
    },
    // call variable with custom display
    getters: {
        getField
    },
    // modules
    modules: {
        computerForm
    }
})
