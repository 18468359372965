<template>
    <div class="">
        <!-- top navigation -->
        <navigation-vue :menu-list="menuList"></navigation-vue>
        <!-- content -->
        <main class="p-2">
            <slot />
        </main>

        <!-- inspiring info -->
        <!-- # -->
        <div class="h-10"></div>
        <!-- footer -->
        <footer-copyright :copyright="$store.state.generalSetting.key_list ? $store.state.generalSetting.key_list.company_copyright : '---'"></footer-copyright>
    </div>
</template>

<script>
    import { FooterCopyright } from '@/component';
    import { Navigation as NavigationVue } from '@/composite'
    // import NavigationVue from '@/component/Navigation.vue';

    export default {
        name: "FadelLayout",
        components: {
            FooterCopyright,
            NavigationVue
        },
        computed: {
            menuList() {
                // load if already
                if (this.$store.state.page.pages) {
                    return _.map(this.$store.state.page.pages.root, function (p) {
                    return {
                        label: p['title'],
                        link: p['uri'],
                        icon: p['icon']
                    }
                })
                }
                else return []
                
            }
        }

    };

</script>

<style lang="css" scoped>

</style>
