<template>
    <div v-if="show" class="inline-flex items-center p-3 text-white bg-green-500 rounded shadow-md third-transition-enter-active">
        <svg class="w-6 h-6 " viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
            <polyline points="22 4 12 14.01 9 11.01" /></svg>
        <span class="ml-2 text-2xl lowercase">Tersimpan</span>
    </div>
</template>

<script>
    export default {
        name: "SubmittedMessage",
        data() {
            return {};
        },
        props: {
            label : {
                type : String,
                default : 'Tersimpan'
            },
            show : {
                type : Boolean,
                default : false
            }
        }
    };

</script>

<style lang="css" scoped>
</style>
