<template>
    <fadel-layout class="font-sans antialiased">
        <transition name="primary-transition" mode="out-in">
            <router-view></router-view>
        </transition>
        <!-- set progressbar -->
        <vue-progress-bar></vue-progress-bar>
    </fadel-layout>
</template>

<script>
    import FadelLayout from './layout/FadelLayout.vue'

    export default {
        components: {
            FadelLayout
        },
        name: "Home",
        mounted() {
            Echo.channel(process.env.MIX_APP_NAME)
                .listen('.App\\Events\\PasswordChanged', (e) => {
                    this.toLogout(e.userId)
                })
                .listen('.App\\Events\\UserLogout', (e) => {
                    this.toLogout(e.userId)
                })
                this.$once('hook:beforeDestroy', () => {
                    // leave echo channel
                    Echo.leaveChannel(process.env.MIX_APP_NAME);
                 })
        },
        methods: {
            async toLogout(userId) {
                // only when same userId
                if (this.$store.state.authUser.id !== userId) return
                // prevent if already logout
                if (this.$store.state.authUser.id === null) return

                await this.$store.dispatch('authUser/logoutAuth');
                // don't push if already
                if (!(['home', 'welcome'].some( e => e === this.$route.name) || this.$route.redirectedFrom == '/')) {
                    this.$router.push({name:'home'}); // go to home
                }
            },
        },
        // created() {
        //     //  hook the progress bar to start before we move router-view
        //     this.$router.beforeEach((to, from, next) => {
        //         console.log('before each');
        //         //  does the page we want to go to have a meta.progress object
        //         // if (to.meta.progress !== undefined) {
        //         //     let meta = to.meta.progress
        //         //     // parse meta tags
        //         //     this.$Progress.parseMeta(meta)
        //         // }
        //         // //  start the progress bar
        //         // this.$Progress.start()
        //         //  continue to next page
        //         next()
        //     })
        //     //  hook the progress bar to finish after we've finished moving router-view
        //     this.$router.afterEach((to, from) => {
        //         //  finish the progress bar
        //         // this.$Progress.finish()
        //         console.log('after each')
        //     })
        // }
    };

</script>

<style lang="css" scoped>
</style>
