// @version V0.0.3 [Monday - 20201130_215437] : add enableEvent option, passing to sendRequest , add documentation too
// @version V0.0.2 [Saturday - 20201114_204004] : add progress when start and finish search 
// @version V0.0.1 [Tuesday - 20201103_092924] : init
import { sendRequest } from "../request";

/**
 * *wrapper for axios*
 * @param {Object} filter_search_any {words : ["os", "samsung"], fields : ["receipt_code", "customer.type>id,name", "product_description"]};
 * @param {Array} filter_search_must [ ["2018", "=", "modeYear", "product.stock>expire_date_time"], ["123","=","","customer_id"] ]
 * @param {Array} filter_search_within [ [[30,98],"customer>id", true] ]
 * @param {Array} filter_sort [{column:"id",sort:"asc",icon:["fas","angle-up"]}]
 * @param {Number} filter_page // default : 1, set to 0 to only return limit, without paginate
 * @param {Number} filter_limit // default : 10
 * @param {Arary} filter_include_relations ['pictures', 'user:id,name', { 'productUnits' : { 'sort' : [ ['base_qty', 'desc'] ]} }, 'productUnits.unit', 'productUnits.productUnitPrice',{ 'productCategory' : { like : any, must, within, sort }]
 * @param {Array} filter_relation_counts ['order','items']
 * @param {String} data_get_url 'user'
 * @param {CallableFunction} callbackSuccess
 * @param {CallableFunction} callbackFailed 
 * @param {CallableFunction} callbackFinish 
 */
export function getSearch(filter_search_any, filter_search_must, filter_search_within, filter_sort , filter_page, filter_limit, filter_include_relations, filter_relation_counts, data_get_url, callbackSuccess, callbackFailed, callbackFinish, enableEvent = false) {
    // prepare and constructing
    let search = {
        any : filter_search_any,
        must : filter_search_must,
        within : filter_search_within
    }

    let params = {
        search: JSON.stringify(search),
        page: filter_page,
        limit: filter_limit,
        sort: JSON.stringify(filter_sort)
    };

    // optional include relations
    if (!_.isEmpty(filter_include_relations)) {
        _.assign(params, {
            includeRelations: JSON.stringify(filter_include_relations)
        });
    };
    // optional relation counts
    if (!_.isEmpty(filter_relation_counts)) {
        _.assign(params, {
            relationCounts: JSON.stringify(filter_relation_counts)
        });
    };

    // send data and callback
    app.$Progress.start();
    return sendRequest(data_get_url, params, 'get', (result) => callbackSuccess(result), (result) => callbackFailed(result), () => { callbackFinish(), app.$Progress.finish() }, true, enableEvent, true); 
    
}