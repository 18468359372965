import {sendRequest} from "@/fadel/vuex/api";
import { defaultOptionState, defaultOptionMutation } from "@/fadel/vuex/helper";

const getDefaultState = () => {
    return {
        ...defaultOptionState
    };
};
export default {
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        ...defaultOptionMutation,
    },
    // can async
    actions: {
        loadOption({commit}) {
            commit('SET_OPTION_LOADING', true)
            return sendRequest('contact-class/option', {}, 'get', (fetchedOption) => {
                commit('SET_OPTION', fetchedOption)
                commit('SET_OPTION_LOADING', false)
            })
        }
    },
    getters: {
        contactClassId : state => name => {
            let result = state.options.find(o => o.name === name)
            if (result) {
                return result.id
            } else {
                return
            }
        }
    }
};
