import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, RESET_FORM, SET_FORMS
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: [getDefaultRowState()]
    }
}

const getDefaultRowState = () => ({
    id: null,
    category: ''
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.unshift(getDefaultRowState())
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_FORMS](state, categories) {
            state.rows = _.map(categories, c => {
                return {
                    id : c.id,
                    category : c.category
                }
            })
            // categories.forEach(c => state.rows.push({id: c.id, category:c.category})); // works to
        },
        updateField

    },
    // can async
    actions: {
        loadAllOptions({commit, rootState}) {
            commit(SET_FORMS, rootState.product.productCategory.options)
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})