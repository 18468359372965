import {
    RESET_FORM, SET_FORM
} from "../../constant-types"
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        form : getDefaultFormState()
    }
}

const getDefaultFormState = () => (
    {
        id : '',
        branch_id : '',
        name : '',
        macs: [],
    }
)
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_FORM](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, computer) {
            state.form = _.pick(JSON.parse(JSON.stringify(computer)), ['id', 'branch_id', 'name', 'macs'])
        },
        updateField
    },
    // can async
    actions: {

    },
    // call variable with custom display
    getters: {
        getField
    }
})

