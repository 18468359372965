// import authUser from './modules/authUser'
// import animatedClass from './modules/animatedClass'

import { version } from '/package'
import * as modules from './modules'
import oaClient from './modules/other-access/client/oaClient'
import {
    getField,
    updateField
} from "vuex-map-fields"

export default {
    state: {
        application_name : process.env.MIX_APP_NAME,
        app_version: version,
        copyright: 'FADEL GROUP 2021',
        class_display : {
            table : 'table table-bordered table-sm table-striped mb-1 table-mobileable',
            table_header : 'bg-info text-light'
        },
        sound_notification : '/storage/notification-sound/default.mp3',
        client_macs : null
    },
    getters: {

    },
    mutations: {
        updateField
    },
    actions: {
        // send with transaction to detect if valid or not ( should activate server on client to get mac )
        getClientMacs({commit, state}) {
            return axios.get('http://localhost:3333/mac').then((success) => {
                commit('updateField', { path: 'client_macs', value: success.data })
            })
        }
    },
    modules: {
        ...modules, oaClient
    }
}
