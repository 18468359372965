import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW,
    RESET_FORM,
    SET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: []
    }
}

const getDefaultRowState = () => {
    return {
        id: '',
        product_id: '',
        client_category_id: '',
        sell_price: 0,
    }
}

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state, template) {
            state.rows.push(template)
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_FORM](state, productPrices) {
            state.rows = productPrices
        },
        updateField

    },
    // can async
    actions: {
        prepareRows({commit, rootState}) {
            rootState.clientCategory.options.forEach(o => {
                commit(ADD_ROW, Object.assign(getDefaultRowState(), { client_category_id : o.id }, { client_category : { client_category : o.client_category }}))
            })
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
