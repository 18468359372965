<template>
    <button :class="wrapperClass" @click="soundIsActive = !soundIsActive">
        <font-awesome-icon :icon="iconByActiveStatus" />
    </button>
</template>

<script>
// @version V0.0.1 [Friday - 20210611_152706] : init, use with echo listeners

    export default {
        name: "NotificationSound",
        computed: {
            iconByActiveStatus() {
                return this.soundIsActive ? 'volume-up' : 'volume-mute'
            }
        },
        data() {
            return {
                soundIsActive : false
            };
        },
        methods: {
            playNotification() {
                // only play when soundIsActive
                if (!this.soundIsActive) return

                let player = new Audio(this.soundUrl)
                player.addEventListener('canplaythrough', e => { 
                    player.play();
                })
            }
        },
        mounted() {
            // load if have echo listener
            if (this.echoListener) {
                // attach listener
                Echo.channel(this.echoListener.channelName)
                    .listen(this.echoListener.eventName, e => { 
                        this.playNotification()
                    })
                // destroy when leave
                this.$once('hook:beforeDestroy', () => {
                    // leave echo channel
                    Echo.leaveChannel(this.echoListener.channelName)
                 })                
            } 
        },
        props: {
            soundUrl : {
                type: String,
                default : 'https://notificationsounds.com/storage/sounds/file-sounds-1150-pristine.mp3'
            },
            wrapperClass: {
                type: String,
                default : ''
            },
            echoListener: {
                type: Object,
                default : () => {
                    // channelName : '',
                    // eventName : ''
                }
            }
        },
        watch: {
            soundIsActive: function(val) {
                if (val) this.playNotification()
            }
        }
    };
</script>

<style lang="css" scoped>
</style>
