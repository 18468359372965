import {
    requestDelete,
    requestSubmit,
    sendRequest,
} from "../api"
import {
    ADD_TO_OPTION,
    REMOVE_FROM_OPTION,
    RESET_FORM,
    RESET_STATE,
    SET_FORM,
    SET_OPTION,
    UPDATE_TO_OPTION
} from "../constant-types"
import {
    getField,
    updateField
} from 'vuex-map-fields'


const getDefaultState = () => {
    return {
        options: [],
        option_loading: false,
        list: [],
        form: getDefaultFormState()
    }
}

const getDefaultFormState = () => {
    return {
        id: '',
        name: '',
        description: '',
        location: '',
        head_operator_id: '',
        head_operator: '', // for reference
        operators: []
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_TO_OPTION](state, option) {
            state.options.unshift(option)
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [RESET_FORM](state) {
            state.form = getDefaultFormState()
        },
        [REMOVE_FROM_OPTION](state, machine) {
            // _.remove(state.options, { id : machine.id})
            let idx = state.options.findIndex(o => o.id === machine.id)
            state.options.splice(idx, 1);
        },
        [SET_FORM](state, machine) {
            Object.assign(state.form, machine)
        },
        [SET_OPTION](state, option) {
            state.options = option;
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        [UPDATE_TO_OPTION](state, updatedOption) {
            let optionFound = state.options.find(o => o.id === updatedOption.id)
            optionFound = updatedOption;
        },
        // update the recap counter in option -> count_wait_for_print, count_print_finished
        'UPDATE_LIST_RECAP_COUNTER'(state, { machineId, countWaitForPrint, countPrintFinished }) {
            let machine = state.list.find(o => o.id === machineId )
            machine.count_wait_for_print = countWaitForPrint
            machine.count_print_finished = countPrintFinished
        },
        ADD_GROUP_IN_OPTION(state, { machine, group }) {
            // find in list
            const findMachine = state.options.find(o => o.id === machine.id)
            if ( !findMachine ) return

            // only push when not exist
            const exist = findMachine.groups.find(g => g.id === group.id)
            if (exist) return

            findMachine.groups.push(group)
        },
        REMOVE_GROUP_IN_OPTION(state, { machine, group }) {
            // find in list
            const findMachine = state.options.find(o => o.id === machine.id)
            if ( !findMachine ) return
            findMachine.groups.splice(findMachine.groups.findIndex(i => i.id === group.id), 1)
        },
        updateField
    },
    // can async
    actions: {
        loadOption({
            commit
        }) {
            commit('updateField', { path: 'option_loading', value: true })
            return sendRequest('machine/option', {}, 'get', (responseOptions) => commit(SET_OPTION, responseOptions), () => {}, () => { commit('updateField', { path: 'option_loading', value: false }) })
        },
        loadList({
            commit
        }) {
            return sendRequest('machine/list', {}, 'get', (responseOptions) => commit('SET_LIST', responseOptions))
        },
        requestCreateMachine({
            commit,
            state
        }) {
            return requestSubmit('machine', state.form, commit, 'post', (createdMachine) => commit(ADD_TO_OPTION, createdMachine))
        },
        requestUpdateMachine({
            commit,
            state
        }) {
            return requestSubmit('machine', state.form, commit, 'put', (updatedMachine) => commit(UPDATE_TO_OPTION, updatedMachine))
        },
        requestDeleteMachine({
            commit
        }, machine) {
            return requestDelete(`machine/${machine.id}`, {}, commit, machine.name, REMOVE_FROM_OPTION)
        },
        addGroupInOption({commit}, { machine, group}) {
            commit('ADD_GROUP_IN_OPTION', { machine, group})
        },
        removeGroupInOption({commit}, { machine, group}) {
            commit('REMOVE_GROUP_IN_OPTION', { machine, group})
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
