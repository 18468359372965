import {debounce} from 'lodash';
import { getField, updateField } from 'vuex-map-fields'

import {
    sendRequest
} from "@/fadel/vuex/api";
import {
    REQUEST_FAILED,
    REQUEST_SENDED,
    REQUEST_SENDING,
    REQUEST_SUCCESS,
    RESET_STATE
} from "@/fadel/vuex/constant-types"

const getDefaultState = () => {
    return {
        receivable_periodically: { list: [], recap: {}},
        sale_daily_by_monthly: [],
    }
}

const loaderCommonReport = ({commit, commitName, requestUrl, requestParams }) => {
    commit('RESET_REPORT')
    commit('requestStatus/' + [REQUEST_SENDING], null, { root : true});
    return sendRequest(requestUrl, requestParams, 'get', 
    (successResponse) => {
        commit('requestStatus/' + [REQUEST_SUCCESS], null, { root : true});
        commit(commitName, successResponse)
    }, 
    () => {
        commit('requestStatus/' + [REQUEST_FAILED], null, { root : true});
    }, 
    () => {
        commit('requestStatus/' + [REQUEST_SENDED], null, { root : true});
    })
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        RESET_REPORT(state) {
            state.receivable_periodically = { list: [], recap: {}}
            state.sale_daily_by_monthly = []
        },
        SET_RECEIVABLE_PERIODICALLY(state, payload) {
            state.receivable_periodically = payload;
        },
        SET_SALE_DAILY_BY_MONTHLY(state, payload) {
            state.sale_daily_by_monthly = payload;
        },
        updateField
    },
    // can async
    actions: {
        loadReceivablePeriodicallyReport: debounce(async function ({commit, rootState}) {
            // memo: use report module for filter state
            return loaderCommonReport({commit, commitName: 'SET_RECEIVABLE_PERIODICALLY', requestUrl : 'oa-client/report/receivable-periodically', requestParams : {
                filter_date_from: rootState.report.filter_date_from,
                filter_date_to: rootState.report.filter_date_to,
                filter_sort: JSON.stringify(rootState.report.filter_sort)
            }})
        }, 400),
        loadSaleDailyByMonthlyReport: debounce(async function ({ commit, rootState }) {
            // memo: use report module for filter state
            return loaderCommonReport({commit, commitName: 'SET_SALE_DAILY_BY_MONTHLY', requestUrl : 'oa-client/report/sale-daily-by-monthly', requestParams : {
                filter_month: rootState.report.filter_month,
                filter_year: rootState.report.filter_year
            }})
        }, 400),
    },
    getters: {
        getField,
        getSumTotalOfSaleDaily(state) {
            return _.sumBy(state.sale_daily_by_monthly, 'price_total_after_disc');
        },
        getSumPaidOfSaleDaily(state) {
            return _.sumBy(state.sale_daily_by_monthly, 'price_paid')
        },
        getSumRemainOfSaleDaily(state) {
            return _.sumBy(state.sale_daily_by_monthly, 'price_remain')
        },
    }
})


