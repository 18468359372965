// @modified: add meta actionKey with authActions
// @modified: add meta isClientRoute handle
// @version V0.0.2 [Tuesday - 20201103_090903] : added authorize page, vue-progress, vuex predata, has meta.isActionRoute to identify
// @version V0.0.1 [Wednesday - 20201028_093630] : init

// vue router global hook
// router hook, should place before Vue Instance Created
import { router } from "./loadVueRouter";
import { store } from "./loadVueX";
// import {  } from "@/fadel/vuex/api";
import { notify } from "./../fadel/vuex/api";

router.beforeEach(async (to, from, next) => {
    // load vuex predata if need
	if (!store.state.authUser.id) {
        await store.dispatch('authUser/refreshAuth');
        // #
    }
    if (!store.state.oaClient.authClient.fetched) {
        await store.dispatch('oaClient/authClient/refreshAuth')
    }
    // load commercial to guard page, and check
    let commercialPermitted = false
    if (!store.state.commercial.fetched) {
        await store.dispatch('commercial/loadInfo')
    }
    // when loaded, should check immediately
    commercialPermitted =  !store.state.commercial.restricted_pages.some(ap => ap === to.name)
    if ( !commercialPermitted ) {
        notify('error', 'paket fitur tidak sesuai', 5000, 'top-right')
        return
    }
    if ( to.meta.isPackageSpecial ) {
        if (store.state.commercial.selected_package !== 'special') {
            notify('info', 'only for special package', 5000, 'top-right')
            return
        }
    }

    // vue instance as app, may be call after Vue Init
    if (!_.isEmpty(app)) {
        // app.$toasted.show("i'am active");
        // #

        // vue progress bar
        app.$Progress.start();
    }

    /**
     * authorize page
     * @param {Array} authPages authenticated user that have related role of pages
     * @param {ArrayString} inFreeUris unguarded page, ex: ['/', '/guest/login', '/guest/register', '/welcome']
     * @param {Boolean} to.meta.isActionRoute page will passed, and will be authorize on backend, via gate or policy
     */
    // authorize page
    let authPages = store.state.authUser.role.pages;
    let authActions = store.state.authUser.role.actions;
    let freeUris = ['/', '/guest/login', '/guest/register', '/welcome'];
    let inFreeUris = _.indexOf(freeUris, to.path)>=0 ? true : false;
    if (_.find(authPages, {"uri" : to.path}) || _.some(authActions, {"key" : to.meta.actionKey}) || inFreeUris || to.meta.isActionRoute || to.meta.isFreeRoute) {
        // continue if permit
        next();
    }
    else if (to.meta.isClientRoute && !store.state.oaClient.authClient.guest) {
        next();
    }
    else {
        // notify if not permit
        if (!_.isEmpty(app)) {
            // app.$toasted.error('akses tidak diijinkan...', { duration : 3000})
            notify('error', 'akses tidak diijinkan...', 5000, 'top-right')
        }
        // redirect to login pages if not login yet
        // router.push('/login')
    }

})

router.afterEach((to, from) => {
    // #

    // vue instance as app, may be call after Vue Init
    if (!_.isEmpty(app)) {
        // vue progress bar
        app.$Progress.finish();
    }

})
