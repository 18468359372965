// @version V-patch-01 [Friday - 14440412] : prevent navigate to route, before refreshAuth and refreshToken when loginAuth
// @version V0.0.7 [Friday - 20210115_152112] : added check ability ( required actions table )
// @version V0.0.6 [Saturday - 20201114_203432] : added refresh token, after login 
// @version V0.0.5 [Tuesday - 20201103_091814] : init change to functional, fix notify, out axios to api , add auth_pages, fix issue when logouted, user still exist
// @version V0.0.4 [Thursday - 20200611_230547] : refreshAuth -> await mode
// @version V0.0.3 [Thursday - 20200319_233158] : fix role_id problem when logout
// @version V0.0.2 [Sunday - 20191208_161120] : add default role_id = 4
// V0.0.1 [Friday - 20191018_213135] : new option_roles
const getDefaultState = () => {
    return {
        id: null,
        name: 'default',
        guest: true,
        role_name: 'guest',
        role_id: 4, // as default
        role : { pages : null }, // as default
        option_roles: ['SuperAdmin', 'Admin', 'User', 'Guest'], // alt : ['Pemilik', 'Kasir', 'User', 'Guest']
    }
}

import { RESET_STATE } from "../../constant-types";
import {
    notify,
    clearNotify
} from "./../../api/index";

export default ({
    namespaced: true,
    state: getDefaultState(),
    // getters: {
    //     authUserInfo(state) {
    //         return {
    //             guest : state.guest,
    //             name : state.name,
    //             role_name : state.role.name
    //         }
    //     }
    // },
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_AUTH(state, newUser) {
            // determine guest type
            let guestType = {
                guest: true
            };
            if (newUser.id) {
                guestType = {
                    guest: false
                };
            }

            _.assign(state, newUser, guestType);
        }
    },
    actions: {
        refreshAuth({
            commit
        }) {
            //job title
            return axios.get('user/auth-user')
                .then((response) => {
                    //success
                    if (response.data.id) {
                        // set auth
                        commit('SET_AUTH', response.data);
                    } else {
                        commit(RESET_STATE);
                    }
                })
                .catch((error) => {
                    //failed
                    console.log(error);
                });
        },
        refreshToken() {
            axios.get('token')
                .then(function (res) {
                    // Refresh crsf token from session after login
                    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = res.data;
                });
        },
        loginAuth({
            dispatch
        }, credential) {
            notify('default', 'memproses login...', null)
            //job title
            axios.post('login', {
                    email: credential.email,
                    password: credential.password,
                    remember: credential.remember
                })
                .then(async(response) => {
                    //success
                    clearNotify();
                    notify('success', 'login berhasil...')
                    // refresh auth then redirect to home
                    // dispatch('refreshToken');
                    await dispatch('refreshAuth');
                    await dispatch('refreshToken');
                    app.$router.push('/');
                })
                .catch((error) => {
                    //failed
                    clearNotify();
                    notify('error', 'login gagal...')
                    // show error info
                });
        },
        logoutAuth({
            dispatch, commit
        }) {
            notify('default', 'memproses logout...', null)
            return axios.post('logout')
                .then((response) => {
                    //success
                    clearNotify();
                    notify('success', 'logout berhasil...')
                    // window.location.href = window.location.pathname;
                    // dispatch('refreshAuth');
                    // app.$router.push('/');
                    commit(RESET_STATE);
                })
                .catch((error) => {
                    //failed
                    clearNotify();
                    notify('error', 'logout gagal...')
                    //failed mean success of logout
                    // window.location.href = window.location.pathname;
                });
        },
    },
    getters: {
        checkAbility: (state) => (actionKey) => state.role.actions.findIndex(a => a.key === actionKey) >= 0,
        checkRestrictOrderFlow: state => ({machineIds, clientCategoryId}) => {
            return state.branch.restrict_order_flows.some(rof => {
                return machineIds.some(m => m === rof.machine_id) && rof.client_category_id === clientCategoryId;
            })
        },
        // indicate logged user is in list
        checkRestrictOrderFlowForIsIn: state => state.branch.restrict_order_flow_fors.some(f => f.role_id === state.role_id )
    }
})
