import {
    getField,
    updateField
} from "vuex-map-fields"
import {requestSubmit, sendRequest} from "../../api"
import {
    ADD_ROW, RESET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        forms: [],
        form_loading: true
    }
}

const getDefaultRowState = () => ({
    branch_id: '',
    role_id: '',
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.forms.unshift(getDefaultRowState())
        },
        REMOVE_ROW(state, idx) {
            state.forms.splice(idx, 1)
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        SET_FORM(state, payload) {
            state.forms = payload
        },
        updateField
    },
    // can async
    actions: {
        loadForm({commit}) {
            commit('updateField', { path: 'form_loading', value: true })
            return sendRequest('restrict-order-flow-for', {}, 'get', (successResponse) => {
                commit('SET_FORM', successResponse)
                commit('updateField', { path: 'form_loading', value: false })
            })
        },
        requestUpdate({commit, state}) {
            return requestSubmit('restrict-order-flow-for', { params : state.forms }, commit)
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
