import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, REMOVE_ROW, RESET_FORM, SET_FORM, SET_FORMS
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: [getDefaultRowState()]
    }
}

const getDefaultRowState = () => ({
    id: '',
    deadline_date : null,
    paid_date : null,
    nominal : 0,
    direct_cash : '',
    via : '',
    receiver_name : '',
    show_edit: false, // to change payment by cash account selector
})

export default ({
    namespaced: true,
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state, nominal = 0) {
            let newRow = getDefaultRowState()
            // set nominal as remain
            newRow.nominal = nominal

            state.rows.push(newRow)
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [REMOVE_ROW](state, rowIndex) {
            state.rows.splice(rowIndex, 1)
        },
        [SET_FORM](state, payment) {
            state.rows[0] = _.pick(payment, ['id', 'payable_id', 'payable_type', 'deadline_date', 'paid_date', 'nominal','direct_cash', 'via', 'receiver_name', 'show_edit'])
        },
        [SET_FORMS](state, payments) {
            state.rows = _.map(payments, function(p) {
                return _.pick(p, ['id', 'payable_id', 'payable_type', 'deadline_date', 'paid_date', 'nominal','direct_cash', 'via', 'receiver_name', 'show_edit'])
            })
        },
        updateField

    },
    // can async
    actions: {
        setPayfullMode({commit, rootState, state}, payfullMode) {
            let today = moment().format('YYYY-MM-DD')

            // reset all row
            commit(RESET_FORM)

            // when mode changed, payment recap on cashierForm should reset
            commit('cashier/cashierForm/RESET_PAYMENT', { indexRow : 0}, { root : true })

            // payfull
            if (payfullMode) {
                // setup first row
                commit(SET_FORM, {
                    deadline_date : today,
                    paid_date : today,
                    nominal : rootState.cashier.cashierForm.rows[0].price_total_after_disc,
                    direct_cash : false,
                    via : 'tunai',
                    // temporary disable, cashier as receiver has already on form_user_log
                    // receiver_name: rootState.authUser.name
                    receiver_name: 'kasir',
                    show_edit: false,
                })
            }
            // payhalf or later
            else {
                // when only one row, should set nominal to max
                if ( state.rows.length === 1 ) {
                    commit('updateField', { path: 'rows.0.deadline_date' , value: today})
                    commit('updateField', { path: 'rows.0.nominal' , value: rootState.cashier.cashierForm.rows[0].price_total_after_disc})
                }
            }
        }
    },
    // call variable with custom display
    getters: {
        getField,
        getTotalNominal: state => state.rows.reduce((accumulator, currentValue) => accumulator + +currentValue.nominal, 0),
        getTotalNominalIsExceed: (state, getters, rootState) => {
            return getters.getTotalNominal > +rootState.cashier.cashierForm.rows[0].price_total_after_disc
        },
        getRemain: (state, getters, rootState) => {
            return +rootState.cashier.cashierForm.rows[0].price_total_after_disc - getters.getTotalNominal
        }
    }
})
