<template>
    <div class="inline-block">
        <router-link :to="routerLinkTo" :tag="routerLinkTag" class="primary"  :class="classAdditional" v-if="routerLinkTo">
            <slot/>
        </router-link>
        <button class="primary"  :class="classAdditional" v-else>
            <slot/>
        </button>
    </div>
</template>

<script>
    export default {
        name: "SecondaryButton",
        data() {
            return {};
        },
        props: {
            // isActive : {
            //     type : Boolean,
            //     default :false
            // },
            // indicate as router-link
            routerLinkTo : {
                type : String,
                default : ''
            },
            routerLinkTag : {
                type : String,
                default : 'a'
            },
            classAdditional : {
                type : String,
                default : 'text-sm'
            }
        }
    };
</script>

<style lang="css" scoped>
    .primary {
        @apply bg-green-500 text-green-100  font-semibold px-2 py-1 rounded-tr-2xl rounded-bl-2xl transition duration-500 ease-in-out;
    }
    .primary:focus {
        @apply bg-green-800 outline-none;
    }
    .primary:hover {
        @apply bg-green-700;
    }
    .active {
        @apply bg-green-900;
    }
</style>