const getDefaultState = () => ({rows: [getDefaultRowState()]})

const getDefaultRowState = () => ({
    name: '',
    status_active: true
})

import { updateField, getField } from "vuex-map-fields"

export default ({
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        SET_FORM(state, payload) {
            state.rows[0] = payload
        },
        RESET_FORM(state) {
            Object.assign(state, getDefaultState())
        },
        updateField
    },
    getters: {
        getField
    }
})
