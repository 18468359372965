<template>
	<div :class="wrapperClass">
        <slot name="pre-slot" />
		<!-- picture -->
		<div :class="pictureWrapperClass">
			<!-- new picture -->
			<img :src="newPicturePath" v-if="newPicturePath" class="shadow-inner secondary-transition-enter-active" />
			<!-- prev picture -->
			<img :src="prevPicturePath" v-else-if="prevPicturePath" class="shadow-inner secondary-transition-enter-active" />
			<!-- no picture -->
			<div v-else>{{ noPictureCaption }}</div>

			<image-button @click.native="$refs[refKey].click()">Upload Foto</image-button>
			<input
				type="file"
				:ref="refKey"
				class="hidden"
				@change="$emit('pictureUpdated', { refKey, files : $refs[refKey].files })"
			/>
		</div>
	</div>
</template>

<script>
import { ImageButton } from '@/component'
export default {
	name: "PictureSingleInput",
	components: { ImageButton },
	emits: ['pictureUpdated'],
    props: {
        noPictureCaption : {
            type: String,
            default: 'Belum Ada Foto'
        },
        wrapperClass : {
            type: String,
            default: 'w-full sm:w-1/2 sm:pr-3'
        },
		pictureWrapperClass : {
			type: String,
			default: 'flex flex-col items-center justify-center p-3'
		},
		refKey : {
			type: String,
			default: 'pictureRef'
		},
		newPicturePath: {
			type: String,
			default: ''
		},
		prevPicturePath: {
			type: String,
			default: ''
		}
    }
}
</script>

<style lang="css" scoped>
</style>