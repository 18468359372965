<template>
    <button type="submit" :class="{'opacity-75' : requestSending }" :disabled="requestSending"
        class="px-2 py-1 text-xl text-gray-200 bg-green-500 focus:outline-none rounded-tr-xl rounded-bl-xl hover:bg-green-700">
        <!-- loading -->
        <svg v-show="requestSending" class="inline-block w-6 h-6 animate-spin" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />
            <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
        {{ label }}
    </button>
</template>

<script>
    export default {
        name: "SubmitButton",
        data() {
            return {};
        },
        props: {
            requestSending : {
                type : Boolean,
                default : false
            },
            label : {
                type : String,
                default : 'Simpan'
            },
        }
    };

</script>

<style lang="css" scoped>
</style>
