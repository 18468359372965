<template>
    <button type="button"
        class="px-2 py-1 mt-2 font-bold text-white transition duration-500 ease-in-out bg-green-500 focus:outline-none rounded-tr-xl rounded-bl-xl hover:bg-green-700 hover:shadow-inner">
        <font-awesome-icon :icon='["far","image"]'></font-awesome-icon>
        <slot/>
    </button>
</template>

<script>
    export default {
        name: "ImageButton",
        data() {
            return {};
        }
    };

</script>

<style lang="css" scoped>
</style>
