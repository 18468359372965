import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, RESET_FORM, UPDATE_TO_ROW
} from "../../constant-types"
import { calculateNominalAfterCut } from "../../helper"

const getDefaultState = () => {
    return {
        rows: []
    }
}

const getDefaultRowState = () => ({
    // cashier_id : '', // manual
    order_demand_item : '', // for reference and update
    order_demand_item_id : '',
    disc : 0,
    price_after_disc : 0,
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state, orderDemandItem) {
            state.rows.push({
                order_demand_item : orderDemandItem,
                order_demand_item_id : orderDemandItem.id,
                disc: 0,
                price_after_disc: orderDemandItem.price
            })
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [UPDATE_TO_ROW](state, { rowIndex, price_after_disc}) {
            Object.assign(state.rows[rowIndex], { price_after_disc })
        },
        updateField

    },
    // can async
    actions: {
        calculatePrice({commit, state}, index) {
            commit(UPDATE_TO_ROW, {
                rowIndex : index,
                price_after_disc : calculateNominalAfterCut(state.rows[index].disc, state.rows[index].order_demand_item.price)
            })

        }
    },
    // call variable with custom display
    getters: {
        getTotalPrice(state) {
            return _.sumBy(state.rows, 'price_after_disc')
        },
        getField
    }
})
