// @version V0.0.3 [Tuesday - 20201006_171035] : add returnNothingIfEmpty on formatCurrency
// @version V0.0.2 [Saturday - 20200523_200116] : added parameter useDayDifference in since method
// @version V0.0.1 [Tuesday - 20200331_114136] : add displayJson
let parentMethodMixin = {
    ////////////
    // method //
    ////////////
    /// common method will be here, as parent 
    methods: {
        displayJson(encodedJson) {
            // NOTE: should use v-html to view
            // parse if have
            let translated = '';
            if (encodedJson) {
                _.each(JSON.parse(encodedJson), function (value, key) {
                    translated += '<b class="text-info"> # ' + key + '</b> -> ' + value;
                })
            }
            return translated;
        },
        // optimize: later
        diffTime(fromTime, toTime){
            let from = moment(fromTime);
            let to = moment(toTime);
            let duration = moment.duration(to.diff(from));
            return duration.hours() + ' Jam ' + duration.minutes() + ' Menit';
        },
        /** return date time value as birthday format ex : monday 12 Aug 1970 (20year-1month-3day) */
        formatBirthday(value, ageOnly = false) {
            if (value == null || value == "" || value == "Invalid DateTime") {
                return '';
            } else {
                let dateOfBirthFormatted = moment(value, 'YYYY-MM-DD').format('dddd,D MMM YYYY');
                let birthday = moment(value);
                let year = moment().diff(birthday, 'years');
                let month = moment().diff(birthday.add(year, 'years'), 'months');
                let day = moment().diff(birthday.add(month, 'months'), 'days');

                if (ageOnly) {
                    return '(' + year + 'thn-' + month + 'bln-' + day + 'hr)';
                } else {
                    return dateOfBirthFormatted + ' (' + year + 'thn-' + month + 'bln-' + day + 'hr)';
                }
            }
        },
        formatCurrency(value, returnNothingIfEmpty = false) {
            if (value == null || value == "") {
                if (returnNothingIfEmpty) { return ;}
                value = 0;
            }
            return Number(value).toLocaleString('id', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0
            });
            // return value.toLocaleString('id', { style: 'currency', currency: 'IDR' });
        },
        formatNumber(value) {
            if (value == null || value == "") {
                value = 0;
            }
            return Number(value);
        },
        /**
         * 
         * @param {string date time} dateTimeValue null mean now
         * @param {boolean} includeTime 
         */
        formatDateTime(dateTimeValue = null, includeTime = false, useServerFormat = false, separatorDateTime = ' ', setAsTodayIfEmpty = true) {
            
            if ( !setAsTodayIfEmpty  && dateTimeValue == null ) {
                return "---";
            } 
            if ( !setAsTodayIfEmpty && dateTimeValue == "") {
                return "---";
            }

            let generatedDateTime = (dateTimeValue) ? moment(dateTimeValue) : moment();

            let formatDate = (useServerFormat) ? 'YYYY-MM-DD' : 'dddd Do MMMM YYYY';
            let formatTime = (useServerFormat) ? 'hh:mm:ss' : 'h:mm:ss a';

            if (includeTime) {
                return generatedDateTime.format(formatDate + separatorDateTime + formatTime);
            } else {
                return generatedDateTime.format(formatDate);
            }
        },
        // optimize: later
        formatMonthTime(monthTimeValue = null) {
            let result = moment(monthTimeValue + '01').format('MMMM YYYY');
            return result;
        },
        // optimize: later
        formatTime(timeValue) {
            return moment(timeValue).format('h:mm a');
        },
        formatPad(value = null, padFormatName, padCount = 6) {
            /**
             * return only prefix, if no value, suitable use for table header
             */
            if (value) {
                /**
                 * return only value with 6 digit pad if doesn't have padFormatName
                 */
                if (padFormatName) {
                    return this.padFormat[padFormatName]['prefix'] + String(value).padStart(this.padFormat[padFormatName]['padCount'], this.padFormat[padFormatName]['padMask']);
                } else {
                    return String(value).padStart(padCount, '000000');
                }
            } else {
                return "No." + this.padFormat[padFormatName]['prefix'];
            }
        },
        formatPercentage(value) {
            // console.log('debug : value ');
            // console.log(value);
            if (value == null) {
                return '0%';
            }
                return Number(String(value).slice(0, -1)) + '%';
            // }
        },
        formatPercentageOrCurrency(value) {
            //-> if percentage
            if (String(value).slice(-1) == '%') {
                return Number(String(value).slice(0, -1)) + '%';
            }
            //-> if nominal then go to currency
            else {
                return this.formatCurrency(value);
            }
        },
        isMobile(maxWidth = 500) {
            if ( screen.width <= maxWidth) {
                return true;
            } else {
                return false;
            }
        // }
        },
        since(dt, useDayDifference = false) {
            if (dt == null) {
                return;
            }

            if (useDayDifference) {
                let today = moment().format('YYYY-MM-DD');
                if (moment(dt).diff(today) == 0) {
                    return 'hari ini';
                } else {
                    return moment(dt).from(today);
                }
            } else {
                return moment(dt).fromNow();
            }
        },
        until(dt){
            let now = moment().startOf('day');
                let next = moment(dt);
                let result = next.diff(now, 'days');

                if (result < 0) {
                    return '---';
                } else if (result == 0) {
                    return 'Hari Ini';
                } else if (result == 1) {
                    return 'Besok';
                } else if (result == 2) {
                    return 'Lusa';
                } else {
                    return result + ' Hari Lagi';
                }
        },
        /** show and hide element base on boolean expression */
        toggleShow(elDestination, boolExpression, inClass = 'animated bounceIn', outClass = 'animated hinge') {
            if (boolExpression) {
                $(elDestination).removeClass(outClass).addClass(inClass).css({
                    'diplay': 'inherit',
                    'height': 'auto'
                });
            } else {
                $(elDestination).removeClass(inClass).addClass(outClass).css({
                    'diplay': 'none',
                    'height': 0
                });
            }

        },
        transformMonthName(monthInNumber) {
            let monthName = '';
            switch (+monthInNumber) { // + to make sure it's not string
                case 1:
                    monthName = 'Januari';
                    break;
                case 2:
                    monthName = 'Februari';
                    break;
                case 3:
                    monthName = 'Maret';
                    break;
                case 4:
                    monthName = 'April';
                    break;
                case 5:
                    monthName = 'Mei';
                    break;
                case 6:
                    monthName = 'Juni';
                    break;
                case 7:
                    monthName = 'Juli';
                    break;
                case 8:
                    monthName = 'Agustus';
                    break;
                case 9:
                    monthName = 'September';
                    break;
                case 10:
                    monthName = 'Oktober';
                    break;
                case 11:
                    monthName = 'November';
                    break;
                case 12:
                    monthName = 'Desember';
                    break;
                default:
                    break;
            }
            return monthName;
        },
        /**
         * get info of logged user
         * snippet : $parent.getUserLogin();
         */
        // getUserLogin() {
        // 	let user = {
        // 		id: 1,
        // 		full_name: 'jacob'
        // 	};
        // 	return user; // temporary act as mocking
        // }
        // add on web
        // Route::get('/token', function(){ return csrf_token(); });
        refreshToken() {
            axios.get('token')
                .then(function (res) {
                    // Refresh crsf token from session after login
                    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = res.data;
                });
        },
    }
}

module.exports = parentMethodMixin
