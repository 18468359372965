<template>
    <div class="px-2 py-1 border rounded-sm shadow-md">
        <div class="text-gray-500">{{ title }}</div>
        <table class="w-full">
            <thead class="text-teal-700 bg-teal-200">
                <slot name="head-data">
                    <tr>
                        <th>Deskripsi</th>
                        <th>Nominal</th>
                    </tr>
                </slot>
            </thead>
            <tbody>
                <slot name="body-data">
                    <!-- <tr>
                        <td>Pasang kancing</td>
                        <td class="text-right">Rp.50.000</td>
                    </tr>
                    <tr>
                        <td>Desain</td>
                        <td class="text-right">Rp.70.000</td>
                    </tr> -->
                </slot>
            </tbody>
            <tfoot class="text-teal-700">
                <slot name="foot-data">
                    <!-- <tr>
                        <th>Jumlah</th>
                        <th class="text-right">Rp.120.000</th>
                    </tr> -->
                </slot>
            </tfoot>
        </table>
    </div>
</template>

<script>

    export default {
        name: "TableSecondary",
        data() {
            return {};
        },
        props: {
            title: {
                type: String,
                default: 'Title'
            },
        },
    };

</script>

<style lang="css" scoped>
</style>
