<template>
    <button
        class="px-2 py-1 text-xs font-bold text-green-200 bg-green-500 rounded hover:bg-green-600 hover:shadow-inner">
        <font-awesome-icon icon='edit'></font-awesome-icon>
        <slot />
    </button>
</template>

<script>
    export default {
        name: "EditButton",
        data() {
            return {};
        }
    };

</script>

<style lang="css" scoped>
</style>
