<template>
    <datetime :title="title" :format="displayFormat" :value="value" @input="(e) => $emit('input', e)" :type="typeOfDate"
        :input-class="inputClass" :input-style="inputStyle" :auto="true" value-zone="UTC"></datetime>
</template>

<script>
// @version V0.0.1 [Sunday - 20201108_085403] : init

    import {
        Datetime
    } from 'vue-datetime'
    // You need a specific loader for CSS files
    // import 'vue-datetime/dist/vue-datetime.css' --> to resources/css

    // make sure naming weekday base on id
    import {
        DateTime,
        Settings
    } from 'luxon'
    Settings.defaultLocale = 'id'

    /**
     * complete reference : https://mariomka.github.io/vue-datetime/
     */
    export default {
        name: "DateTimeInput",
        components: {
            Datetime,
        },
        computed: {
            // date, datetime, time
            typeOfDate() {
                return this.dateOnly ? 'date' : 'datetime'
            },
            displayFormat() {
                let dateFormat = {
                    year: 'numeric',
                    day: 'numeric',
                    month: this.longFormat ? 'long' : 'numeric',
                    weekday: this.longFormat ? 'long' : 'short'
                }

                let timeFormat = {
                    hour: 'numeric',
                    minute: '2-digit',
                }

                if (!this.dateOnly) {
                    Object.assign(dateFormat, timeFormat);
                }

                return dateFormat;
            },
        },
        data() {
            return {
                
            };
        },
        props: {
            value: [String],
            inputClass: {
                type: [String, Array, Object],
                default: 'w-full form-input' // base on tailwind
            },
            inputStyle: {
                type: [String, Array, Object],
                default: ''
            },
            dateOnly: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: ''
            },
            longFormat: {
                type: Boolean,
                default: true
            }
        }
    };

</script>

<style lang="css" scoped>
    /* note -> scoped should untyped */
    /* .theme-custom .vdatetime-popup__header,
    .theme-custom .vdatetime-calendar__month__day--selected>span>span,
    .theme-custom .vdatetime-calendar__month__day--selected:hover>span>span {
        background: #FF9800;
    }

    .theme-custom .vdatetime-year-picker__item--selected,
    .theme-custom .vdatetime-time-picker__item--selected,
    .theme-custom .vdatetime-popup__actions__button {
        color: #ff9800;
    } */
</style>
