<template>
    <textarea class="block w-full form-input" :rows="rows" :placeholder="placeholder" :value="value" @input="e => $emit('input', e.target.value)" />
</template>

<script>
    export default {
        name: "NakedTextareaInput",
        props: {
            value: {
                type: [String, Number],
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            rows: {
                type: Number,
                default: 3
            }
        }
    };
</script>

<style lang="css" scoped>
</style>