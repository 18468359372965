<template>
    <div class="text-xl tracking-wider uppercase font-bold border-b border-black">
        <slot>Lorem Ipsum</slot>
    </div>
</template>

<script>
    export default {
        name: "PageTitle",
        data() {
            return {};
        },
    };
</script>

<style lang="css" scoped>
</style>