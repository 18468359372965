<template>
	<label class="block">
		<span class="text-gray-700">{{ label }}</span>
		<naked-textarea-input :rows="rows" class="mt-1" :placeholder="placeholder" :value="value" @input="e => $emit('input', e)" />
	</label>
</template>

<script>
import NakedTextareaInput from './NakedTextareaInput'

export default {
	name: "TextareaInput",
	components: { NakedTextareaInput },
    props: {
        label: {
            type: String,
            default: 'Deskripsi'
        },
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        rows: {
            type: Number,
            default: 3
        }
    }
}
</script>

<style lang="css" scoped>
</style>