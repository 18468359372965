/**
 * @typedef {Object} Form
 * @property {number} targetable_id - The targetable id.
 * @property {string} targetable_type - The targetable type.
 * @property {string} target_label - The target label.
 * @property {'monthly_target' | 'yearly_target'} target_key - The target key, which can be either 'monthly_target' or 'yearly_target'.
 */

import { requestSubmit } from "../../api";
import { getField, updateField } from "vuex-map-fields";

/**
 * @typedef {Object} State
 * @property {Form} form

/**
 * Get the default state for the form.
 * @returns {State}
 */
const getDefaultState = () => {
    return {
        form: {
            // model from `Target`
            targetable_id: null,
            targetable_type: "App\\Models\\CustomerService", // as App\\Models\\CustomerService | App\\Models\\Machine | App\\Models\\Group
            targetable: null,
            target_label: "Target Bulanan",
            target_key: "monthly_target",
            target_value: 0,
            date_range_from: null,
            date_range_to: null,
            achievement_percentage: 0,
            current_achievement: 0,
            remain_achievement: 0,
        },
    };
};

export default {
    namespaced: true,
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_FORM(state, targetable_type) {
            Object.assign(state.form, getDefaultState().form);
            state.form.targetable_type = targetable_type;
        },
        SET_FORM(state, form) {
            Object.assign(state.form, form);
        },
        updateField,
    },
    getters: {
        getField
    },
    actions: {
        // create
        async requestCreateTarget({ commit, state }) {
            return await requestSubmit(
                `target`,
                state.form,
                commit,
                "post",
                (createdTarget) => {
                    // add to list
                    commit("target/ADD_TO_LIST_MONTHLY", createdTarget, {
                        root: true,
                    });
                }
            );
        },
        // update
        async requestUpdateTarget({ commit, state }) {
            return await requestSubmit(
                `target/${state.form.id}`,
                state.form,
                commit,
                "put",
                (updatedTarget) => {
                    // update to list
                    commit("target/UPDATE_TO_LIST_MONTHLY", updatedTarget, {
                        root: true,
                    });
                }
            );
        },
        // delete
        async requestDeleteTarget({ commit, state }, target) {
            // should confirm before continue
            if (!confirm(`Apakah yakin ingin menghapus "${target.target_label}"?`)) {
                return false;
            }
            // process
            return await requestSubmit(
                `target/${target.id}`,
                state.form,
                commit,
                "delete",
                (deletedTarget) => {
                    // remove from list
                    commit("target/REMOVE_FROM_LIST_MONTHLY", deletedTarget.id, {
                        root: true,
                    });
                }
            );
        },
    },
};
