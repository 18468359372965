import {
    ADD_IN_LIST,
    REMOVE_IN_LIST,
    RESET_FORM, SET_FORM, SET_LIST, UPDATE_IN_LIST
} from "./../constant-types"
import { updateField, getField } from 'vuex-map-fields'
import { clearNotify, notify, requestDelete, requestSubmit, sendRequest } from "../api"

const getDefaultState = () => {
    return {
        form : getDefaultFormState(),
        detail: {},
        detail_loading: false,
        list: [],
        list_loading: false,
    }
}

const getDefaultFormState = (category = 'receivable_group') => (
    {
        id : '',
        name : '',
        category,
    }
)
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        [RESET_FORM](state, category) {
            Object.assign(state.form, getDefaultFormState(category))
        },
        [SET_FORM](state, payload) {
            //state.form = _.pick(JSON.parse(JSON.stringify(payload)), ['id', 'name', 'category'])
            state.form = JSON.parse(JSON.stringify({
                id: payload.id,
                name: payload.name,
                category: payload.category
            }))
        },
        [SET_LIST](state, payload) {
            state.list = payload
        },
        SET_LIST_LOADING(state, payload) {
            state.list_loading = payload
        },
        [ADD_IN_LIST](state, payload) {
            state.list.unshift(payload)
        },
        [UPDATE_IN_LIST](state, payload) {
            let idx = state.list.findIndex(f => f.id === payload.id)
            if (idx >= 0) {
                state.list.splice(idx, 1, payload)
            }
        },
        [REMOVE_IN_LIST](state, payload) {
            let idx = state.list.findIndex(f => f.id === payload.id)
            state.list.splice(idx, 1)
        },
        ADD_CLIENT_IN_DETAIL(state, clients) {
            if (state.detail) {
                clients.forEach(client => {
                    state.detail.clients.unshift(client)
                })
            }
        },
        REMOVE_CLIENT_IN_DETAIL(state, clients) {
            if (state.detail) {
                clients.forEach(client => {
                    let idx = state.detail.clients.findIndex(f => f.id === client.id)
                    if (idx >= 0) {
                        state.detail.clients.splice(idx, 1)
                    }
                })
            }
        },
        ADD_CLIENT_COUNT_IN_GROUP(state, { groupId, count = 1 }) {
            let idx = state.list.findIndex(l => l.id === groupId)
            if (idx >= 0) {
                state.list[idx]['clients_count'] += count
            }
        },
        SUBTRACT_CLIENT_COUNT_IN_GROUP(state, { groupId, count = 1 }) {
            let idx = state.list.findIndex(l => l.id === groupId)
            if (idx >= 0) {
                state.list[idx]['clients_count'] -= count
            }
        },
        updateField
    },
    // can async
    actions: {
        loadList({ commit }) {
            commit('SET_LIST_LOADING', true)
            return sendRequest('group/client', {}, 'get', (successResponse) => {
                commit(SET_LIST, successResponse)
                commit('SET_LIST_LOADING', false)
            })
        },
        loadDetail({commit}, groupId) {
            commit('updateField', { path: 'detail_loading', value: true })
            return sendRequest(`group/client/${groupId}`, {}, 'get', (successResponse) => {
                // sort clients
                let { clients, ...group } = successResponse
                let value = {
                    ...group,
                    clients: _.sortBy(clients, function (o) {
                        return o.name
                    })
                }
                commit('updateField', { path: 'detail', value })
                commit('updateField', { path: 'detail_loading', value: false })
            })
        },
        requestCreateGroup({
            commit,
            state
        }) {
            return requestSubmit('group/client', state.form, commit, 'post', (createdGroup) => commit(ADD_IN_LIST, createdGroup))
        },
        requestUpdateGroup({
            commit,
            state
        }) {
            return requestSubmit(`group/client/${state.form.id}`, state.form, commit, 'put', (updatedGroup) => commit(UPDATE_IN_LIST, updatedGroup))
        },
        requestDeleteGroup({
            commit
        }, group) {
            return requestDelete(`group/client/${group.id}`, {}, commit, group.name, REMOVE_IN_LIST)
        },
        requestAddClient({commit, state}, { groupId, clients }) {
            // prevent when already added
            if (state.detail) {
                if (state.detail.clients.some(c => c.id === clients[0]['id'] )) {
                    notify('info', 'pelanggan sudah ada sebelumnya')
                    return
                }
            }

            notify('info', 'menambahkan pelanggan')
            return requestSubmit(`group/${groupId}/clients/add`, { clientIds : clients.map(c => c.id) }, commit, 'put', (successResponse) => {
                // add in detail
                commit('ADD_CLIENT_IN_DETAIL', clients )
                // update group list ( update count )
                commit('ADD_CLIENT_COUNT_IN_GROUP', { groupId, count: clients.length })
                // clear notify
                clearNotify()
            })
        },
        requestRemoveClient({commit}, { groupId, clients }) {
            notify('info', 'menghapus pelanggan')
            return requestSubmit(`group/${groupId}/clients/remove`, { clientIds : clients.map(c => c.id) }, commit, 'put', (successResponse) => {
                //if success, remove in list
                if (successResponse > 0) {
                    // remove in detail if match and exist
                    commit('REMOVE_CLIENT_IN_DETAIL', clients)
                    // update group list ( update count )
                    commit('SUBTRACT_CLIENT_COUNT_IN_GROUP', { groupId, count : clients.length })
                    // clear notify
                    clearNotify()
                }
            })
        }
    },
    // call variable with custom display
    getters: {
        getField,
        getList: state => ( category, filterSearchText = '' ) => state.list.filter(f => {
            let reg = new RegExp(filterSearchText, "i")
            return f.category === category && f.name.search(reg) >= 0
        })
    }
})


