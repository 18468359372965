import { sendRequest } from "../api"
import { updateField, getField } from 'vuex-map-fields'

const getDefaultState = () => {
    return {
        options: [],
        option_loading: false,
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        SET_OPTION(state, payload) {
            state.options = payload
        },
        updateField
    },
    // can async
    actions: {
        loadOption({
            commit
        }) {
            commit('updateField', { path: 'option_loading', value: true })
            return sendRequest('business-unit/option', {}, 'get', (responseOptions) => commit('SET_OPTION', responseOptions), () => {}, () => { commit('updateField', { path: 'option_loading', value: false }) })
        },
    },
    getters: {
        getField
    }
})
