<script>
import { Line } from "vue-chartjs"

// ref to this issue https://stackoverflow.com/questions/66940954/why-does-nuxt-give-me-this-error-with-vue-chartjs, should use
// yarn add chart.js@2.9.4 vue-chartjs
export default {
    extends: Line,
    mounted() {
        // Overwriting base render method with actual data.
        this.renderChart({
            labels: this.labels,
            datasets: this.datasets,
        }, this.options)
    },
    props: {
        labels: {
            type: Array,
            default: () => [],
        },
        datasets: {
            type: Array,
            default : () => [] //ex. { label : 'abc', backgroundColor: '#f87979', data : [1,2,3,4,56] }
        },
        options: {
            type : Object,
            default : () => ({responsive: true, maintainAspectRatio: false})
        }
    },
}
</script>
