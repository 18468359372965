// @version V0.0.1 [Tuesday - 20201103_092924] : init

const getDefaultState = () => {
    return {
        someInitialState: true,
        option_roles: [],
        page_roles: [],
        action_roles: []
    }
}
import { updateField, getField } from "vuex-map-fields";
import { REQUEST_FAILED, REQUEST_SENDED, REQUEST_SENDING, RESET_STATE } from "../constant-types";

import {
    sendRequest
} from "./../api";

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        SET_OPTION_ROLES(state, payload) {
            state.option_roles = payload
        },
        SET_PAGE_ROLE(state, payload) {
            state.page_roles = payload;
        },
        SET_ACTION_ROLE(state, payload) {
            state.action_roles = payload;
        },
        updateField
    },
    // can async
    actions: {
        loadOptionRoles({commit}) {
            //job title
            return axios.get('role/option')
                .then((response) => {
                    //success
                    commit('SET_OPTION_ROLES', response.data);
                });
        },
        getPageRole({commit}) {
            return sendRequest('role/page-role', {}, 'get', (response) => commit('SET_PAGE_ROLE', response), () => {}, () => {})
        },
        getActionRole({commit}) {
            return sendRequest('role/action-role', {}, 'get', (response) => commit('SET_ACTION_ROLE', response), () => {}, () => {})
        },
        requestUpdateOptionRole({commit, state}) {
            commit('requestStatus/'+ [REQUEST_SENDING], null, {
                root: true
            });
            return sendRequest('role/option-role', { option_roles :  JSON.parse(JSON.stringify(state.option_roles))}, 'put',
                (successResponse) => {
                    commit('requestStatus/'+ [REQUEST_SENDED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/'+ [REQUEST_FAILED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/'+ [REQUEST_SENDED], null, {
                        root: true
                    })
                },
                true,
                true,
                true
             )
        },
        requestSaveActionRole({commit, state}) {
            commit('requestStatus/'+ [REQUEST_SENDING], null, {
                root: true
            });
            return sendRequest('role/action-role', { action_roles : state.action_roles }, 'put',
                (successResponse) => {
                    commit('requestStatus/'+ [REQUEST_SENDED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/'+ [REQUEST_FAILED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/'+ [REQUEST_SENDED], null, {
                        root: true
                    })
                },
                true,
                true, 
                false
             )
        },
        requestSavePageRole({commit, state}) {
            commit('requestStatus/'+ [REQUEST_SENDING], null, {
                root: true
            });
            return sendRequest('role/page-role', { page_roles : state.page_roles }, 'put',
                (successResponse) => {
                    commit('requestStatus/'+ [REQUEST_SENDED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/'+ [REQUEST_FAILED], null, {
                        root: true
                    })
                },
                () => {
                    commit('requestStatus/'+ [REQUEST_SENDED], null, {
                        root: true
                    })
                },
                true,
                true, 
                false
             )
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
