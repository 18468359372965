import { getField, updateField } from 'vuex-map-fields'
import { sendRequest, requestSubmit } from '@/fadel/vuex/api'
import {notify, notifyConfirmation, requestDelete} from '../api';

const swapArrayElements = (a, x, y) => {
  if (a.length === 1) return a;
  a.splice(y, 1, a.splice(x, 1, a[y])[0]);
  return a;
};

const getDefaultState = () => {
    return {
        list: [],
        list_loading: false,
        details: [],
        details_loading: false,
        option_page_keys: [
            { id: 'client-printing', label: 'Landing Page Mitra' },
            { id: 'client-corporate', label: 'Landing Page Corporate' },
        ],
        option_components: [
            { id: 'banner-full', label: 'Banner Penuh' },
            { id: 'title-only', label: 'Judul Saja' },
            { id: 'gallery-primary', label: 'Gallery Pertama' },
            { id: 'gallery-secondary', label: 'Gallery Kedua' },
            { id: 'gallery-tertiary', label: 'Gallery Ketiga' },
            { id: 'list-checklist', label: 'List Centang' },
            { id: 'google-maps', label: 'Peta Lokasi' },
            { id: 'paragraph', label: 'Paragraph/Artikel' }
        ],
        option_content_types: [
            { id: 'text', label: 'Teks' },
            { id: 'image-link', label: 'Link Gambar' },
            { id: 'image', label: 'Gambar' },
            { id: 'html', label: 'format HTML' },
            { id: 'video-link', label: 'Link Video' },
            { id: 'video', label: 'Video' }
        ],
        component_type_by_name: {
            'title-only' : 'paragraph',
            'banner-full' : 'banner',
            'gallery-primary' : 'gallery',
            'gallery-secondary' : 'gallery',
            'gallery-tertiary' : 'gallery',
            'list-checklist' : 'paragraph',
            'google-maps' : 'paragraph',
            paragraph : 'paragraph'
        },
        allowed_content_types: {
            banner: ['image', 'image-link', 'video', 'video-link'],
            gallery: ['image', 'image-link']
        }
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState())
        },
        ADD_DETAIL(state, { idx, componentName, pageKey }) {
            let blueprint = {
                page_key: pageKey,
                title: '',
                description: '',
                component_type: state.component_type_by_name[componentName],
                component_name: componentName,
                order_sequence: idx,
                show_preview: false,
                article_items: []
            }
            // if idx null mean first array
            if (!idx) {
                state.details.unshift(blueprint)
            } else {
                state.details.splice(idx, 0, blueprint)
            }
        },
        MOVE_UP_DETAIL(state, idx) {
            // prevent if upper
            if(idx === 0) return
            swapArrayElements(state.details, idx-1, idx)
        },
        MOVE_DOWN_DETAIL(state, idx) {
            swapArrayElements(state.details, idx, idx-1)
        },
        REMOVE_DETAIL(state, idx) {
            state.details.splice(idx, 1)
        },
        REMOVE_DETAIL_ITEM(state, { idx, idxItem }) {
            state.details[idx].article_items.splice(idxItem, 1)
        },
        SET_DETAILS(state, payload) {
            // include show preview on every detail
            state.details = payload.map(p => {
                return {
                    ...p,
                    show_preview: false
                }
            })
        },
        UPDATE_DETAILS(state, { idx, detail }) {
            state.details.splice(idx, 1, { ...detail, show_preview: false })
        },
        TOGGLE_PREVIEW(state, idx) {
            state.details[idx].show_preview = !state.details[idx].show_preview
        },
        FIX_ORDER_SEQUENCE_IN_DETAILS(state) {
            state.details.forEach((d, idx) => d.order_sequence = idx+1)
        },
        updateField
    },
    // can async
    actions: {
        requestReadArticle({commit}, pageKey) {
            commit('updateField', { path: 'details_loading', value: true })
            return sendRequest(`article/${pageKey}`, {}, 'get', (successResponse) => {
                commit('SET_DETAILS', successResponse)
                commit('updateField', { path: 'details_loading', value: false })
            })
        },
        // update from edited details
        requestStoreUpdateArticle({commit, getters}, { idx, detail }) {
            // clean form : when content_type is video or image and has content_file as new file, should clean content
            const { article_items, ...article } = detail
            let form = {
                ...article,
                article_items: article_items.map(ai => {
                    const { content, ...rest } = ai
                    let contentCleaned = (['image', 'video'].some(i => i === rest.content_type) && rest.content_file) ? '' : content
                    return {
                        ...rest,
                        content: contentCleaned
                    }
                }),
                sorted_articles: getters.getSortedArticleIds
            }
            return requestSubmit('article', form, commit, 'post', (successCallback) => {
                // update set
                commit('UPDATE_DETAILS', { idx, detail: successCallback } )
            }, (errorResponse) => {
                notify('error', errorResponse.message)
            })
        },
        requestChangeOrderSequence({commit, state, getters}, { currentIdx, direction }) {
            if (direction === 'up') {
                commit('MOVE_UP_DETAIL', currentIdx)
            } else {
                commit('MOVE_DOWN_DETAIL', currentIdx)
            }
            commit('FIX_ORDER_SEQUENCE_IN_DETAILS')
            return requestSubmit('article/update-sequence', { list : getters.getSortedArticleIds}, commit, 'post', (successCallback) => {
                // nothing to do
            }, (errorResponse) => {
                notify('error', errorResponse.message)
            })
        },
        requestRemoveArticle({commit}, { article, idx }) {
            // remove directly when not persisted yet
            if (!article.id) {
                commit('REMOVE_DETAIL', idx)
                return
            }
            // with confirmation
            notifyConfirmation('apakah yakin ingin menghapus ?', () => {
                return requestSubmit(`article/${article.id}`, {}, commit, 'delete', (successResponse) => {
                    commit('REMOVE_DETAIL', idx)
                }, (errorResponse) => {
                    notify('error', errorResponse.message)
                })
            }, () => {})
        },
        requestRemoveArticleItem({commit}, { articleItem, idx, idxItem }) {
            // remove directly when not persisted yet
            if (!articleItem.id) {
                commit('REMOVE_DETAIL_ITEM', { idx, idxItem })
                return
            }
            // with confirmation
            notifyConfirmation('apakah yakin ingin menghapus ?', () => {
                return requestSubmit(`article/item/${articleItem.id}`, {}, commit, 'delete', (successResponse) => {
                    commit('REMOVE_DETAIL_ITEM', { idx, idxItem })
                }, (errorResponse) => {
                    notify('error', errorResponse.message)
                })
            }, () => {})
        }
    },
    // call variable with custom display
    getters: {
        getField,
        getComponentLabelById: state => componentId => state.option_components.filter(f => f.id === componentId)[0].label,
        getSortedArticleIds: state => state.details.map(d => ({ id: d.id ? d.id : null, order_sequence: d.order_sequence }))
    },
})
