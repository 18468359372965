import authClient from './authClient'
import clientForm from './clientForm'
import orderStatus from './orderStatus'
import machine from './machine'
import order from './order'
import orderDemandForm from './orderDemandForm'
import report from './report'
export default ({
    namespaced: true,
    modules: {
        authClient, clientForm, orderStatus, machine, order, orderDemandForm, report
    }
})
