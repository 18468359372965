// @version V0.0.1 [Monday - 20201130_215526] : init
import { sendRequest } from "../request";

export function requestSubmit(url, form, dispatchOrCommit, method = 'post', successCallback = () => {}, errorCallback = () => {}, finishCallback = () => {}) {
    dispatchOrCommit('requestStatus/REQUEST_SENDING', null, { root : true})
    return sendRequest(url, form, method, 
        (successResponse) => {
            dispatchOrCommit('requestStatus/REQUEST_SUCCESS', null, { root : true})
            successCallback(successResponse)
        },
        (failedResponse) => {
            dispatchOrCommit('requestStatus/REQUEST_FAILED', null, { root : true})
            errorCallback(failedResponse)
        },
        () => {
            dispatchOrCommit('requestStatus/REQUEST_SENDED', null, { root : true})
            finishCallback()
        }
    )
} 