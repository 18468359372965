// @version V0.0.1 [Monday - 20201130_214019] : init, casted as number, added optional method
export function calculateNominalAfterCut(cut, nominalToCut) {
    if (String(cut).slice(-1)=='%') {
        let substractedDisc = 100 - String(cut).slice(0,-1);
        return +(nominalToCut * substractedDisc / 100);
    } else {
        return +(nominalToCut - cut);
    }
}

export function calculateCutResult(cut, nominalToCut, method = 'normal') {
    if (String(cut).slice(-1)=='%') {
        let percentageValue = String(cut).slice(0,-1);
        let result = +(nominalToCut * percentageValue / 100);
        
        switch (method) {
            case 'round':
                result = Math.round(result)
                break;
            case 'ceil':
                result = Math.ceil(result)
                break;
            case 'floor':
                result = Math.floor(result)
                break;
            default: // 'normal
                break;
        }

        return result
    } else {
        return +cut;
    }
}