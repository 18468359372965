// @version V0.0.1 [Tuesday - 20201103_092924] : init

const getDefaultFormState = () => {
    return {
        name: '',
        email: '',
        profilePhoto: null,
        password: '',
        password_confirmation: '',
        role_id: '',
        branch_id: '',
        status_active: true
    }
}

import {
    getField,
    updateField
} from "vuex-map-fields";
import {
    RESET_FORM,
    RESET_STATE,
    RESET_LIST,
    REQUEST_SENDING,
    REQUEST_SENDED,
    REQUEST_SUCCESS,
    REQUEST_FAILED
} from "./../constant-types";
import {
    sendRequest,
    getSearch,
    notifyConfirmation,
    notify
} from "./../api/index";

import {
    debounce
} from "lodash";

export default ({
    namespaced: true, //
    // variable
    state: {
        form: getDefaultFormState(),
        user_list: null,
        search_params: {
            filter_search_text: '',
            filter_sort: [{
                'column': 'name',
                'sort': 'asc',
                'icon': ['fas', 'angle-up']
            }], //default value, for empty --> filterSort:[]
            filter_page: 1,
            filter_limit: 10,
            loading: false
        }
    },
    // must syncronous
    mutations: {
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            // Object.assign(state, getDefaultState())
            this.commit('resetForm');
        },
        [RESET_FORM](state) {
            // Object.assign(state.form, getDefaultFormState())
            state.form = getDefaultFormState();
            // commit('updateFormField', getDefaultFormState());
        },
        [RESET_LIST](state) {
            state.user_list = null
        },
        UPDATE_FORM_FIELD(state, field) {
            updateField(state.form, field)
        },
        UPDATE_SEARCH_PARAMS_FIELD(state, field) {
            updateField(state.search_params, field)
        },
        SET_USER_TO_FORM(state, user) {
            state.form = user;
        },
        SET_USER_LIST(state, payload) {
            state.user_list = payload
        },
        REMOVE_USER_IN_LIST(state, indexList) {
            state.user_list.data.splice(indexList, 1);
        }
    },
    // can async
    actions: {
        loadUserToForm({
            commit
        }, userId) {
            return sendRequest('user/' + userId, {}, 'get', (user) => commit('SET_USER_TO_FORM', user))
        },
        createUser({
            state,
            commit
        }) {
            commit(`requestStatus/${[REQUEST_SENDING]}`, null, {
                root: true
            });
            return sendRequest(
                'user', state.form, 'post',
                // success
                () => {
                    commit(RESET_FORM);
                    commit(`requestStatus/${[REQUEST_SUCCESS]}`, null, {
                        root: true
                    })
                },
                // failed
                () => commit(`requestStatus/${[REQUEST_FAILED]}`, null, {
                    root: true
                }),
                // finished
                () => commit(`requestStatus/${[REQUEST_SENDED]}`, null, {
                    root: true
                })
            )
        },
        updateUser({
            state,
            commit
        }) {
            commit(`requestStatus/${[REQUEST_SENDING]}`, null, {
                root: true
            });
            return sendRequest(
                'user', state.form, 'put',
                // success
                () => {
                    // commit(RESET_FORM);
                    commit(`requestStatus/${[REQUEST_SUCCESS]}`, null, {
                        root: true
                    })
                },
                // failed
                () => commit(`requestStatus/${[REQUEST_FAILED]}`, null, {
                    root: true
                }),
                // finished
                () => commit(`requestStatus/${[REQUEST_SENDED]}`, null, {
                    root: true
                })
            )
        },
        deleteUser({
            state,
            commit
        }, payload) {
            // confirmation first, then delete
            notifyConfirmation(
                'Apakah yakin ingin menghapus' + payload.user.name,
                // continue
                (c) => {
                    // request delete and delete row
                    sendRequest(
                        'user/' + payload.user.id, {}, 'delete',
                        () => commit('REMOVE_USER_IN_LIST', payload.index),
                        (error) => {
                            notify('error', 'gagal menghapus', 4000, 'top-left')
                        }
                    )
                },
                // stop
                (s) => {
                    // console.log(s)
                },
            )
        },
        getUserList: debounce(async ({
            state,
            commit
        }, payload) => {
            // working on payload if have
            if (payload) {
                if (payload.page) {
                    commit('UPDATE_SEARCH_PARAMS_FIELD', {
                        path: 'filter_page',
                        value: payload.page
                    });
                }
            }
            // loading for display
            commit('UPDATE_SEARCH_PARAMS_FIELD', {
                path: 'loading',
                value: true
            });
            await getSearch({
                    words: _.split(state.search_params.filter_search_text, ' '),
                    fields: ["name", "email", "role>name,description"]
                },
                [],
                [],
                state.search_params.filter_sort,
                state.search_params.filter_page,
                state.search_params.filter_limit,
                ['role','branch'],
                [],
                'user',
                (result) => commit('SET_USER_LIST', result),
                (failed) => {},
                () => {
                    // loading for display
                    commit('UPDATE_SEARCH_PARAMS_FIELD', {
                        path: 'loading',
                        value: false
                    });
                }
            )
        }, 1000)
    },
    // call variable with custom display
    getters: {
        getFormField(state) {
            return getField(state.form)
        },
        getSearchParamsField(state) {
            return getField(state.search_params)
        },
    }
})
