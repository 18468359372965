import {
    getField,
    updateField
} from "vuex-map-fields"
import {requestSubmit, sendRequest} from "../../../api"
import {
    RESET_FORM, SET_FORM
} from "./../../../constant-types"

const getDefaultState = () => {
    return {
        form: getDefaultFormState(),
        default_form_setted: false,
        default_form: {
            status_negotiable: null,
            client_category_id: null,
            label: '',
            code: '',
            match: false
        }
    }
}

const getDefaultFormState = () => ({
    id: '',
    customer_service_id: '',
    client_category_id: '',
    status_negotiable: null,
    name: '',
    full_name: '',
    business_units: [],
    contacts: [],
    addresses: [],
    memo: ''
})

const getDefaultAddressState = () => ({
    id: null,
    addressable_id: null,
    addressable_type: 'App\\Models\\Client',
    address_label: '',
    street: '',
    city: '',
    status_primary: false,
})

const getDefaultContactState = () => ({
    id: null,
    contact_class_id: '',
    contactable_id: null,
    contactable_type: 'App\\Models\\Client',
    contact_label: '',
    contact_value: '',
    status_primary: false
})
export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [RESET_FORM](state) {
            Object.assign(state.form, getDefaultFormState())
        },
        [SET_FORM](state, client) {
            state.rows[0] = _.pick(client, ['id', 'customer_service_id', 'client_category_id', 'name', 'full_name', 'addresses', 'contacts', 'memo', 'business_units'])
        },
        SET_DEFAULT_FORM(state, { statusNegotiable, clientCategoryId, label, code }) {
            state.default_form.status_negotiable = statusNegotiable
            state.default_form.client_category_id = clientCategoryId
            state.default_form.label = label
            state.default_form.code = code
            state.default_form.match = true

            state.default_form_setted = false
        },
        SET_DEFAULT_TO_FORM(state) {
            state.form.status_negotiable = state.default_form.status_negotiable
            state.form.client_category_id = state.default_form.client_category_id

            state.default_form_setted = true
        },
        ADD_ADDRESS_ROW(state) {
            // set link
            let addressForm = getDefaultAddressState()
            addressForm.addressable_id = state.form.id

            state.form.addresses.push(addressForm)
        },
        REMOVE_ADDRESS_ROW(state, index) {
            state.form.addresses.splice(index, 1)
        },
        ADD_CONTACT_ROW(state) {
            // set link
            let contactForm = getDefaultContactState()
            contactForm.contactable_id = state.form.id

            state.form.contacts.push(contactForm)
        },
        REMOVE_CONTACT_ROW(state, index) {
            state.form.contacts.splice(index, 1)
        },
        updateField
    },
    // can async
    actions: {
        requestApplyForm({state, commit, rootState}) {
            // include credential username, password, password_confirmation
            let form = {
                ...state.form,
                ...rootState.client.clientForm.setting_form
            }
            return requestSubmit('oa-client/client/apply-form', form, commit, 'post', () => {
                commit('RESET_FORM')
                commit('SET_DEFAULT_TO_FORM')
                // reset credential form
                commit('client/clientForm/RESET_FORM', null, { root: true })
            })
        },
        loadDefaultForm({commit}, clientCode) {
            return requestSubmit('oa-client/client/client-code', { client_code: clientCode }, commit, 'post', (fetchedRule) => {
                commit('SET_DEFAULT_FORM', {
                    statusNegotiable: fetchedRule.status_negotiable,
                    clientCategoryId: fetchedRule.client_category_id,
                    label: fetchedRule.label,
                    code: fetchedRule.code,
                })
                commit('SET_DEFAULT_TO_FORM')
            })
        }
    },
    // call variable with custom display
    getters: {
        getField
    }
})
