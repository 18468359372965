<template>
    <div class="fixed bottom-0 w-full">
        <div class="relative text-xs text-center text-gray-700 bg-green-200 border-t-2 border-gray-200">
            <b>Copyright &copy; {{ copyright }} - </b>
            <a href="https://aplikasipro.com" target="_blank">made by aplikasipro.com</a>
            <!--commercial package info-->
            <div class="absolute right-0 md:bottom-0 bottom-4 px-1 text-yellow-700 font-card">{{ $store.state.commercial.selected_package }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FooterCopyright",
        data() {
            return {};
        },
        props: {
            copyright: {
                type: String,
                default: 'Coca Cola 2020'
            },
        },
    };

</script>

<style lang="css" scoped>
</style>
