import { requestCreate, requestDelete, requestUpdate, sendRequest } from "../api"
import {
    ADD_TO_OPTION,
    REMOVE_FROM_OPTION,
    RESET_STATE, SET_OPTION, UPDATE_TO_OPTION
} from "../constant-types"
import expenseCategoryForm from "./form/expenseCategoryForm"

const getDefaultState = () => {
    return {
        options: [],
        option_loading: false
    }
}
export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_TO_OPTION](state, createdExpenseCategory) {
            state.options.unshift(createdExpenseCategory)
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [REMOVE_FROM_OPTION](state, deletedExpenseCategory) {
            let idx = state.options.findIndex(o => o.id === deletedExpenseCategory.id)
            state.options.splice(idx, 1)
        },
        [SET_OPTION](state, fetchedOption) {
            state.options = fetchedOption
        },
        [UPDATE_TO_OPTION](state, updatedExpenseCategory) {
            let idx = state.options.findIndex(o => o.id === updatedExpenseCategory.id)
            Object.assign(state.options[idx], updatedExpenseCategory)
        } ,
        SET_OPTION_LOADING(state, payload) {
            state.option_loading = payload
        }

    },
    // can async
    actions: {
        loadOptions({commit, state}) {
            commit('SET_OPTION_LOADING', true)
            return sendRequest('expense-category/option', {}, 'get', (fetchedOption) => commit(SET_OPTION, fetchedOption), () => {}, () => {  commit('SET_OPTION_LOADING', false) })
        },
        requestCreateExpenseCategory({commit, state}) {
            return requestCreate('expense-category', state.expenseCategoryForm.rows[0], commit, ADD_TO_OPTION)
        },
        requestUpdateExpenseCategory({commit, state}) {
            return requestUpdate(`expense-category/${state.expenseCategoryForm.rows[0].id}`, state.expenseCategoryForm.rows[0], commit, UPDATE_TO_OPTION)
        },
        requestDeleteExpenseCategory({commit, state}, expenseCategory) {
            return requestDelete(`expense-category/${expenseCategory.id}`, {}, commit, expenseCategory.category, REMOVE_FROM_OPTION)
        }
    },
    // call variable with custom display
    getters: {

    },
    // modules
    modules: {
        expenseCategoryForm
    }
})
