import {
    ADD_IN_LIST,
    REMOVE_IN_LIST,
    RESET_STATE, SET_LIST, UPDATE_IN_LIST
} from "../constant-types"

import { getField, updateField } from 'vuex-map-fields'
import expenseForm from "./form/expenseForm"
import { getSearch, requestCreate, requestDelete, requestUpdate } from "../api"

const getDefaultState = () => {
    return {
        list: null,
        search_params: getDefaultSearchParamsState()
    }
}

const getDefaultSearchParamsState = () => {
    return {
        filter_search_text : '',
        loading : false,
        filter_sort : [{
            column : 'expense_date_time',
            sort : 'desc',
            icon : ['fas', 'angle-down']
        }],
        filter_page : 1,
        filter_limit : 20,
        filter_date : (new Date()).toISOString().split('T')[0]
    }
}

export default ({
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_IN_LIST](state, createdExpense) {
            state.list.data.unshift(createdExpense);
        },
        [RESET_STATE](state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState())
        },
        [REMOVE_IN_LIST](state, expense) {
            let idx = state.list.data.findIndex(l => l.id === expense.id)
            state.list.data.splice(idx, 1);
        },
        [SET_LIST](state, fetchedList) {
            state.list = fetchedList
        },
        [UPDATE_IN_LIST](state, updatedExpense) {
            let idx = state.list.data.findIndex(l => l.id === updatedExpense.id)
            Object.assign(state.list.data[idx], updatedExpense)
        },
        updateField

    },
    // can async
    actions: {
        loadList({commit, state}, payload = { resetPage : false}) {
            if (payload.resetPage) {
                commit('updateField', { path : 'search_params.filter_page', value : 1})
            }
            commit('updateField', { path : 'search_params.loading', value : true })
            return getSearch(
                {words: _.split(state.search_params.filter_search_text, ' '), fields : ['description']},
                [
                    [state.search_params.filter_date, "=", "modeDate", "expense_date_time"]
                ],
                [],
                state.search_params.filter_sort,
                state.search_params.filter_page,
                state.search_params.filter_limit,
                [],
                [],
                'expense',
                (response) => { commit(SET_LIST, response) ; commit('updateField', { path : 'search_params.loading', value : false })} ,
                ()=>{},
                ()=>{}
            )
        },
        requestCreateExpense({commit, state}) {
            return requestCreate('expense', { ...state.expenseForm.rows[0], machines: state.expenseForm.form_machines }, commit, ADD_IN_LIST)
        },
        requestUpdateExpense({commit, state}) {
            return requestUpdate(`expense/${state.expenseForm.rows[0].id}`, { ...state.expenseForm.rows[0], machines: state.expenseForm.form_machines }, commit, UPDATE_IN_LIST)
        },
        requestDeleteExpense({commit}, expense) {
            return requestDelete(`expense/${expense.id}`, {}, commit, expense.description, REMOVE_IN_LIST)
        }
    },
    // call variable with custom display
    getters: {
        getField
    },
    // modules
    modules: {
        expenseForm
    }
})
