import { updateField, getField } from "vuex-map-fields";
import {requestDelete, requestCreate, requestUpdate, sendRequest} from "../api";

const getDefaultState = () => {
    return {
        list: [],
        list_loading : false,
        form: getDefaultFormState()
    };
};

const getDefaultFormState = () => ({
    user_id: '',
    user: '',
    customer_services : []
})

export default {
    namespaced: true, //
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        RESET_STATE(state) {
            // Merge rather than replace so we don't lose observers
            // https://github.com/vuejs/vuex/issues/1118
            Object.assign(state, getDefaultState());
        },
        SET_LIST(state, payload) {
            state.list = payload
        },
        ADD_IN_LIST(state, newItem){
            state.list.unshift(newItem)
        },
        UPDATE_IN_LIST(state, updatedItem) {
            let idx = state.list.findIndex(l => l.id === updatedItem.id)
            Object.assign(state.list[idx], updatedItem)
        },
        REMOVE_IN_LIST(state, deletedItem) {
            let idx = state.list.findIndex(l => l.id === deletedItem.id)
            state.list.splice(idx, 1)
        },
        SET_FORM(state, payload) {
            const { filter_user_customer_services, ...user } = payload
            state.form.user_id = user.id
            state.form.user = user
            state.form.customer_services = filter_user_customer_services.map( m => m.id )
        },
        RESET_FORM(state) {
            Object.assign(state.form, getDefaultFormState())
        },
        updateField
    },
    // can async
    actions: {
        loadList({commit}) {
            // set loading
            commit('updateField', { path: 'list_loading', value: true })
            return sendRequest('filter-user-customer-service', {}, 'get', (successResponse) => {
                commit('SET_LIST', successResponse)
                // set stop loading
                commit('updateField', { path: 'list_loading', value: false })
            })
        },
        requestCreate({commit, state}) {
            let user_id = state.form.user_id
            let customer_service_ids = state.form.customer_services
            return requestCreate(`filter-user-customer-service/${user_id}`, { customer_service_ids }, commit)
        },
        requestUpdate({commit, state}) {
            let user_id = state.form.user_id
            let customer_service_ids = state.form.customer_services
            return requestUpdate(`filter-user-customer-service/${user_id}`, { customer_service_ids }, commit)
        },
        requestDelete({commit}, user) {
            return requestDelete(`filter-user-customer-service/${user.id}`, {}, commit, `filter dari ${user.name}`)
        }
    },
    getters: {
        getField,
        filteredAuthUserCustomerServices: (state, getters, rootState, rootGetters) => {
            let find = state.list.find(l => l.id === rootState.authUser.id)
            if (find) {
                return find.filter_user_customer_services
            } else {
                return []
            }
        }
    }
};
