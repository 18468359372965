import {
    getField,
    updateField
} from "vuex-map-fields"
import {
    ADD_ROW, RESET_FORM, SET_CLIENT_TO_FORM, SET_FORM
} from "../../constant-types"

const getDefaultState = () => {
    return {
        rows: [getDefaultRowState()],
        form_hidden_mode: false
    }
}

const getDefaultRowState = () => ({
    id: '',
    client_id: '',
    client: '', // as reference
    memo_to_cashier: '',
    memo_receipt: '',
    order_status_category_id: 1, // 1 as default, will attach/setup later to OrderStatus event
})

export default ({
    namespaced: true, 
    // variable
    state: getDefaultState(),
    // must syncronous
    mutations: {
        [ADD_ROW](state) {
            state.rows.push(getDefaultRowState())
        },
        [RESET_FORM](state) {
            Object.assign(state, getDefaultState())
        },
        [SET_CLIENT_TO_FORM](state, client) {
            state.rows[0].client_id = client.id
            state.rows[0].client = client
        },
        [SET_FORM](state, orderDemand) {
            Object.assign(state.rows[0], _.pick(orderDemand, ['id', 'client_id', 'client', 'memo_to_cashier', 'memo_receipt']))
        },
        updateField

    },
    // can async
    actions: {

    },
    // call variable with custom display
    getters: {
        getField
    }
})
